import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class ObjectsService {

    constructor(private _http: HttpClient) { 
        // console.log(AppConfig.ipAddress)
    }

    objects(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvati', a)
        .pipe(map((res: any) => res));
    }

    activation(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/aktiviraj', a)
        .pipe(map((res: any) => res));
    }

    oneObject(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
        .pipe(map((res: any) => res));
    }

    addLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/language', a)
        .pipe(map((res: any) => res));
    }

    activateOfferForYou(a: any) {
        return this._http.put(AppConfig.ipAddress + 'accommodation/aktivirajPonudu', a)
        .pipe(map((res: any) => res));
    }

    kopirajAccommodation(a: any) {
        return this._http.put(AppConfig.ipAddress + 'accommodation/kopirajAccommodation', a)
        .pipe(map((res: any) => res));
    }

    dohvatiJezike(a: any) {
        return this._http.get(AppConfig.ipAddress + 'fetch/dohvatiJezike?id='+a.id, a)
        .pipe(map((res: any) => res));
    }

    spremiDefaultJezik(a: any) {
        return this._http.put(AppConfig.ipAddress + '/accommodation/spremiDefaultJezik', a)
        .pipe(map((res: any) => res));
    }

    obrisiAccommodation(a: any){
        return this._http.post(AppConfig.ipAddress + 'accommodation/obrisiAccommodation', a)
        .pipe(map((res: any) => res));
    }

    napraviPDF2(a: any){
        return this._http.get(AppConfig.ipAddress + 'accommodation/napraviPDF2?idObjekta=' + a.idObjekta + "&jezik=" + a.jezik, {responseType: 'blob'})
        .pipe(map((res: any) => res));
    }

}