import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class TypesService {

    constructor(private _http: HttpClient) { 
      // console.log(AppConfig.ipAddress)
    }

    dohvatiInterestDisableType(a: any) {
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiInterestDisableType', a)
          .pipe(map((res: any) => res));
    }
      
    ukloniInteresDisableType(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/ukloniInteresDisableType', a)
        .pipe(map((res: any) => res));
    }
    
    dodajInteresDisableType(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dodajInteresDisableType', a)
        .pipe(map((res: any) => res));
    }
}