import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DxDataGridModule, DxFormModule } from 'devextreme-angular';

import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';

import { HomeComponent } from './pages/home/home.component';

import { BasicInfoComponent } from './pages/basic-info/basic-info.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ObjectsComponent } from './pages/objects/objects.component';
import { ContentComponent } from './pages/content/content.component';
import { UsersComponent } from './pages/users/users.component';
import { OneObjectComponent } from './pages/objects/one-object/one-object/one-object.component';
import { YouMayNeedComponent } from './pages/you-may-need/you-may-need.component';
import { PostcardsComponent } from './pages/postcards/postcards.component';
import { AboutComponent } from './pages/about/about.component';
import { EditInteresComponent } from './pages/interes/edit-interes/edit-interes.component';
import { AddInteresComponent } from './pages/interes/add-interes/add-interes.component';
import { AnaliticsComponent } from './pages/analitics/analitics.component';
import { OffersComponent } from './pages/offers/offers.component';
import { OneOfferComponent } from './pages/offers/one-offer/one-offer.component';
import { SelectedContentComponent } from './pages/selected-content/selected-content.component';
import { TypesComponent } from './pages/types/types.component';
import { NotificationsListComponent } from './pages/notifications-list/notifications-list.component';
import { NotificationDetailComponent } from './pages/notification-detail/notification-detail.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AudioGuideComponent } from './pages/audio-guide/audio-guide.component';
import { AudioPointsComponent } from './pages/audio-points/audio-points.component';
import { ObjectGroupComponent } from './pages/object-groups/object-group/object-group.component';
import { OneGroupComponent } from './pages/object-groups/one-group/one-group.component';
import { OneGroupAddComponent } from './pages/object-groups/one-group-add/one-group-add.component';
import { ObjectTypesComponent } from './pages/object-types/object-types.component';
import { ViatorComponent } from './pages/viator/viator.component';
import { TagsComponent } from './pages/tags/tags.component';

const routes: Routes = [  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'object-types',
    component: ObjectTypesComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'basic-info',
    component: BasicInfoComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'basic-info/:id',
    component: BasicInfoComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [ AuthGuardService ]
  },
  { 
    path: 'objects',
    component: ObjectsComponent,
    canActivate: [ AuthGuardService ],
    // children: [{ path: './one-object', component: OneObjectComponent } ]
  },
  {
    path: 'content',
    component: ContentComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'one-object/:id',
    component: OneObjectComponent,
    canActivate: [ AuthGuardService ]
  },

  {
    path: 'about/:id',
    component: AboutComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'postcards/:id',
    component: PostcardsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'you-may-need/:id',
    component: YouMayNeedComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'add-interes',
    component: AddInteresComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'edit-interes/:id',
    component: EditInteresComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'analitics',
    component: AnaliticsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'offers/:id',
    component: OffersComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'one-offer/:id/:id2',
    component: OneOfferComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'selected-content/:id',
    component: SelectedContentComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'types/:id',
    component: TypesComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    // canActivate: [ AuthGuardService ]
  },
  {
    path: 'notifications-list',
    component: NotificationsListComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'notification-detail/:id',
    component: NotificationDetailComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'audio-guide/:id',
    component: AudioGuideComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'audio-points/:id/:lng/:accId',
    component: AudioPointsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'object-group',
    component: ObjectGroupComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'one-group-add',
    component: OneGroupAddComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'one-group/:id',
    component: OneGroupComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'viator/:id',
    component: ViatorComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'tags',
    component: TagsComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DxDataGridModule, DxFormModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [HomeComponent]
})
export class AppRoutingModule { }
