import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjectsService } from '../../objects.service';
import { encodeHtml } from 'devextreme/core/utils/string'
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
@Component({
  selector: 'app-one-object',
  templateUrl: './one-object.component.html',
  styleUrls: ['./one-object.component.scss'],
  providers: [ObjectsService]
})
export class OneObjectComponent implements OnInit {

  id: any;
  objekt: any;
  imeObjekta: any;
  role: any;

  constructor(
    private _route: ActivatedRoute,
    public _router: Router,
    private _objectService: ObjectsService
  ) {
      this.role = localStorage.getItem('role')
      this.id = this._route.snapshot.params['id'];
      this.objekt = this._router.getCurrentNavigation().extras.state;
      this._objectService.oneObject({"id": this.id}).subscribe(res => {
          this.imeObjekta = res.name;
      });
  }

  ngOnInit() {
    this.checkOfferForYouStatus();
  }

  duplicateObject(){ 
    this._objectService.kopirajAccommodation({id: this.id}).subscribe(res => {
      if(res.success === true){
        this._router.navigate(['/objects/'])
      } else {
        notify('Try again.', 'error')
      }
    })
  }

  copyObject(){
    let encodedMessage = encodeHtml("Are you sure that you want to duplicate the whole object and its content?");
    let myDialog = custom({
        title: "Warning",
        messageHtml: encodedMessage,
        buttons: [{
            text: "Yes",
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
            },{
              text: "No",
              onClick: (e) => {          
                  return { buttonText: e.component.option("text") }
              }
          }]
    });  
    myDialog.show().then((dialogResult) => {
        if(dialogResult.buttonText == 'Yes'){
          notify('Object is successfully duplicated', 'success')
          this.duplicateObject();
         }
    });
  }

  checkOfferForYouStatus() {
    let element = document.getElementById("offerForYouStatus") as HTMLElement;
    this._objectService.oneObject({"id": this.id}).subscribe(res => {
      let status = res.offerForYou;
      if (status === 1) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

  editBasicInfo(){
     this._router.navigate(['/basic-info/' + this.id]);    
  }

  about(){
    this._router.navigate(['/about/' + this.id]);   
  }

  usluge(){
    this._router.navigate(['/you-may-need/' + this.id]);   
  }

  postcards(){
    this._router.navigate(['/postcards/' + this.id]);
  }

  offers() {
    this._router.navigate(['/offers/' + this.id]);
  }

  content() {
    this._router.navigate(['/selected-content/' + this.id]);
  }

  contentShowHide(){
    // console.log('prikaži kontent');
    this._router.navigate(['/types/' + this.id]);
  }

  audioGuide(){
    this._router.navigate(['/audio-guide/' + this.id]);
  }

  showViator(){
    this._router.navigate(['/viator/' + this.id]);
  }

  activate(event) {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    this._objectService.activateOfferForYou({id: this.id}).subscribe(res=> {
      let status = res.podaci.offerForYou.active;
      let element = event.target;
      if (status === 1) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

}  
