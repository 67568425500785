import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxFormComponent, DxFormModule, DxPopupModule, DxTabPanelModule, DxTabsModule, DxTextBoxModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import * as moment from 'moment';
import { NotificationsService } from './notifications.service';
import validationEngine from "devextreme/ui/validation_engine";
import { encodeHtml } from 'devextreme/core/utils/string'
import { custom } from 'devextreme/ui/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [NotificationsService]
})
export class NotificationsComponent implements OnInit {  
  @ViewChild(DxFormComponent, {static: false}) notification: DxFormComponent;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  siteKey: string = "6Lf7NakaAAAAAFLSBcZfxLbdxKYR_b4L5B2f9fMl"
  public aFormGroup: FormGroup;
  
 today = new Date();  
  
  notifications: any = {};
  podaci: any;
  jezikTab = [
    { id: 0, text: "Hr" },
    { id: 1, text: "En" },
    { id: 2, text: "Fr" },
    { id: 3, text: "De" },
    { id: 4, text: "It" }
 ]
 tab: number = 0;
 hrvObjekt = { };
 enObjekt = { };
 frObjekt = { };
 deObjekt = { };
 itObjekt = { };
 hrobjektLength: any;
 enobjektLength: any;
 frobjektLength: any;
 deobjektLength: any;
 itobjektLength: any;
 varijablaHr: any;
 varijablaEn: any;
 varijablaFr: any;
 varijablaDe: any;
 varijablaIt: any;
 imeObjekta: any;
 nisamRobot = false;
 loadingVisible = false;

 constructor(private notificationsService: NotificationsService, private formBuilder: FormBuilder) { 
  this.validateAsync = this.validateAsync.bind(this);
  
  this.aFormGroup = this.formBuilder.group({
    recaptcha: ['', Validators.required]
  });
}
 
  ngOnInit() {     }

  odabirTaba(e){
    this.tab = e.itemIndex;
  }
  
  onContentReady(e){   
    e.component.getEditor('sender').focus();
  }

  validateAsync(params) {
  return new Promise((resolve, reject) => {
    this.notificationsService.scanKeyCheck({ key: params.value })
        .toPromise()
        .then((res: any) => {
          // console.log(res);        
            // res.message contains validation error message
            res.success ? { success: Boolean, message: String }: reject(params.message);
            resolve(res);
            this.imeObjekta = res.name;
        })
        .catch(error => {
            reject("Cannot contact validation server");
            this.imeObjekta = null;
        });
      })
  }

     posalji(data){
      var result = this.notification.instance.validate();
  
     this.hrobjektLength = Object.entries(this.hrvObjekt).filter(([k,v],i)=>!!v)
     this.enobjektLength = Object.entries(this.enObjekt).filter(([k,v],i)=>!!v)
     this.frobjektLength = Object.entries(this.frObjekt).filter(([k,v],i)=>!!v)
     this.deobjektLength = Object.entries(this.deObjekt).filter(([k,v],i)=>!!v)
     this.itobjektLength = Object.entries(this.itObjekt).filter(([k,v],i)=>!!v)
  
// ----------------------- HRVATSKI JEZIK --------------------------------------- //
     if(this.hrobjektLength.length === 2){
        this.varijablaHr = {
            title: this.hrvObjekt['title'],
            content: this.hrvObjekt['content'],
        } 
      } else {
          this.varijablaHr = {};
      }  
// ----------------------- ENGLESKI JEZIK --------------------------------------- //
     if(this.enobjektLength.length === 2){
      this.varijablaEn = {
          title: this.enObjekt['title'],
          content: this.enObjekt['content'],
      } 
    } else {
        this.varijablaEn = {};
    }  
// ----------------------- FRANCUSKI JEZIK --------------------------------------- //
    if(this.frobjektLength.length === 2){
      this.varijablaFr = {
          title: this.frObjekt['title'],
          content: this.frObjekt['content'],
      } 
    } else {
        this.varijablaFr = {};
    } 
// ----------------------- NJEMAČKI JEZIK --------------------------------------- //
    if(this.deobjektLength.length === 2){
      this.varijablaDe = {
          title: this.deObjekt['title'],
          content: this.deObjekt['content'],
      } 
    } else {
        this.varijablaDe = {};
    } 
// ----------------------- TALIJANSKI JEZIK --------------------------------------- //
    if(this.itobjektLength.length === 2){
      this.varijablaIt = {
          title: this.itObjekt['title'],
          content: this.itObjekt['content'],
      } 
    } else {
        this.varijablaIt = {};
    } 

  //////// slanje svega ////////////
 let hr = this.varijablaHr;
 let en = this.varijablaEn;
 let fr = this.varijablaFr;
 let de = this.varijablaDe;
 let it = this.varijablaIt;
    this.podaci = {
      sender: data.sender,
      sender_name: this.imeObjekta,
      notification_date: moment(data.date).format('YYYY-MM-DD HH:mm'),   
      hr,
      en,
      fr,
      de,
      it
    }
    if(result.isValid){
      // console.log(this.podaci);
    }
          
    // console.log(this.frobjektLength.length);
    
    
  if(result.isValid ){
    if(this.frobjektLength.length < 2 || this.deobjektLength.length < 2 || this.enobjektLength.length < 2 || this.itobjektLength.length < 2){
          this.dodatnoPitanje();
        } else {
          this.slanjeNaAPI();    
        }
    }  else {
      notify('All fields are required!', 'error')
    }  
  }

    slanjeNaAPI(){
      this.loadingVisible = true;
      this.notificationsService.listnotification(this.podaci).subscribe(res =>{
        // console.log(res);
        this.loadingVisible = false;
        if(res.succes === true){
          notify('Successfully sent!', 'success');
          this.notification.instance.resetValues();
          this.hrvObjekt = {}
          this.enObjekt = {}
          this.frObjekt = {}
          this.deObjekt = {}
          this.itObjekt = {}
          this.imeObjekta = null;
          this.tab = 0;
        } else {
          this.loadingVisible = false;
          notify('Error. Try again!', 'error')
        }
      })
     }

     dodatnoPitanje(){
      let encodedMessage = encodeHtml("You don't have all translations. Send it anyway or add another translation");
      let myDialog = custom({
          title: "Warning",
          messageHtml: encodedMessage,
          buttons: [       
          {
            text: "Send",
            onClick: (e) => {          
                return { buttonText: e.component.option("text") }
            }
          },
          {
            text: "Cancel",
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
          }]
      });  
      myDialog.show().then((dialogResult) => {
          // console.log(dialogResult.buttonText);
          if(dialogResult.buttonText == 'Send'){
            this.slanjeNaAPI();
            this.handleReset();
           }
      });
    }

    handleSuccess(e){
      // console.log(e);  
      this.nisamRobot = true;    
    }

    handleExpire(e){
      this.nisamRobot = false;   
    }

    handleReset(): void {
      this.captchaElem.reloadCaptcha();
      this.nisamRobot = false;  
    }

}

// @NgModule({
//   imports: [
//     CommonModule,
//     RouterModule,
//     DxFormModule,
//     DxPopupModule,
//     DxButtonModule,
//     DxTabPanelModule,
//     DxTabsModule,
//     DxTextBoxModule
//   ],
//   declarations: [ NotificationsComponent ],
//   exports: [ NotificationsComponent ]
// })
// export class NotificationModule { }

