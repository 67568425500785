export const navigationUser = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Objects',
    icon: 'pin',
    path: '/objects'
  },
  // {
  //   text: 'Object group',
  //   icon: 'columnchooser',
  //   path: '/object-group'
  // },
  {
    text: 'Content',
    icon: 'coffee',
    path: '/content'
  }
];
