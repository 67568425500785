import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class BasicInfoService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }
    
    regions(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/region', a)
        .pipe(map((res: any) => res));
    }

    cities(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/city', a)
        .pipe(map((res: any) => res));
    }

    countries(a: any){
        return this._http.get(AppConfig.ipAddress + 'fetch/country', a)
        .pipe(map((res: any) => res));
    }

    oneObject(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
        .pipe(map((res: any) => res));
    }

    addObject(a: any){
        return this._http.post(AppConfig.ipAddress + 'accommodation', a)
        .pipe(map((res: any) => res));
    }

    editObject(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediOsnovne', a)
        .pipe(map((res: any) => res));
    }

    generirajQR(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/QRCode', a)
        .pipe(map((res: any) => res));
    }

    kreirajMediaPack(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/createMediaPack', a)
        .pipe(map((res: any) => res));
    }
    dohvatiSveGradove(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/cityAll', a)
        .pipe(map((res: any) => res));
    }

    dohvatiGradoveFilter(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/cityFilter', a)
        .pipe(map((res: any) => res));
    }

    getLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/getLanguage', a)
        .pipe(map((res: any) => res));
    }

    editLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/editLanguage', a)
        .pipe(map((res: any) => res));
    }

    provjeraScanKey(a: any){
        return this._http.get(AppConfig.ipAddress + '/accommodation/scanKeyList?token=?' + a.token, a)
        .pipe(map((res: any) => res));
    }

    popis(a: any){
        return this._http.post(AppConfig.ipAddress + 'object_types/dohvati', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiJezike(a: any) {
        return this._http.get(AppConfig.ipAddress + 'fetch/dohvatiJezike', a)
        .pipe(map((res: any) => res));
    }

    dohvatiJezikePrijevod(a: any) {
        return this._http.post(AppConfig.ipAddress + 'fetch/dohvatiJezikePrijevod', a)
        .pipe(map((res: any) => res));
    }

}