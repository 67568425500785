import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './../../config';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  loggedIn = true;
  token: any;  
  role: any;
  path: any;
  baseUrl: string = AppConfig.ipAddress + '/auth/'
                     
  headers = new HttpHeaders();
  constructor(private router: Router,private _http: HttpClient) {
    this.imaToken();  
    // this.path = location.pathname;
  }

  login(user: any){
    this.headers = new HttpHeaders({ 'Authorization': 'Basic ' + btoa(user.email + ":" + user.password) });
      return this._http.post(this.baseUrl, user, {headers: this.headers})   
      .pipe(map((res: any) => res      
    ));
  }

  logIn(login: string, passord: string) {
    this.login({email: login, password: passord});
    this.loggedIn = true;
    this.router.navigate(['/home']);
    this.token = sessionStorage.getItem('token')
    this.role = localStorage.getItem('role');       
  }

  logOut() {
    this.loggedIn = false;
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login-form']);
  }

  get isLoggedIn() {
    return this.loggedIn;
  }

  // get prikazObavijesti() {    
  //   if(this.path === '/notifications'){
  //     return true;
  //   } else {
  //     return false;
  //   }  
  // }
 
imaToken(){
  this.token = sessionStorage.getItem('token')  
  if(this.token != null || this.token != undefined){
    this.loggedIn = true;
  } else {
    this.loggedIn = false;
   }
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
 constructor(private router: Router, private authService: AuthService) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {     
    const isLoggedIn = this.authService.isLoggedIn;
    const isLoginForm = route.routeConfig.path === 'login-form';    

    if (isLoggedIn && isLoginForm) {
        this.router.navigate(['/home']);
        return false;
    }
    if (!isLoggedIn && !isLoginForm) {
        this.router.navigate(['/login-form']);
    }
    return isLoggedIn || isLoginForm;
  }
}