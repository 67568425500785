import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationDetailService {

    constructor(private _http: HttpClient) { 
        // console.log(AppConfig.ipAddress)
    }

    listnotificationDetails(a: any){
        return this._http.get(AppConfig.ipAddress + 'listnotification/dohvati/' + a.id, a)
        .pipe(map((res: any) => res));
    }

    posaljiPush(a: any){
        return this._http.post(AppConfig.ipAddress + 'listnotification/posaljipush', a)
        .pipe(map((res: any) => res));
    }

    zakaziPush(a: any){
        return this._http.post(AppConfig.ipAddress + 'listnotification/zakaziPush', a)
        .pipe(map((res: any) => res));
    }

}