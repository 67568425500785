import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { NotificationsListService } from './notifications-list.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [NotificationsListService]
})
export class NotificationsListComponent implements OnInit {
  token: any;
  podaci: any;
  danasnjiDatum = moment(new Date()).format('YYYY-MM-DD');

  constructor(public router: Router, private notificationsListService: NotificationsListService) {
    this.allNotifications();
   }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift( 
      {
      location: 'before',
      template: 'naslovTablice',    
    },
    )
  }

  details(e){
    // console.log(e);    
    this.router.navigate(['/notification-detail/' + e.data.id ]);
  }

  ngOnInit() { 
    sessionStorage.removeItem('objectsKey');
    sessionStorage.removeItem('contentKey');
  }


  allNotifications(){
    this.notificationsListService.popisObavijesti({}).subscribe(res => {
      this.podaci = res;
      // console.log(this.podaci)
    })
  }

  deleteNotification(e){
    this.notificationsListService.deleteNotification({id: e.data.id}).subscribe(res => {
      // console.log(res);    
      if(res.success === true){
        notify('Notification successfully deleted', 'success')
      } else {
        notify('An error has occurred. Try again', 'error')
      } 
      this.allNotifications();
    })
  }

  formatirajDatum(datum: any){
    return moment(datum).format('YYYY-MM-DD')
  }

}
