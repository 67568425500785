import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class TagsService {

    constructor(
        private _http: HttpClient
    ){}

    dohvatiSveTagove(a: any){
        return this._http.post(AppConfig.ipAddress + 'tag/dohvatiSve', a)
        .pipe(map((res: any) => res      
        ));
    }

    newTag(a: any){
        return this._http.post(AppConfig.ipAddress + 'tag', a)
        .pipe(map((res: any) => res      
        ));
    }

    editTag(a: any){
        return this._http.put(AppConfig.ipAddress + 'tag/uredi/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

    deleteTag(id: any){
        return this._http.delete(AppConfig.ipAddress + 'tag/' + id)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiJezikeTaga(id: any){
        return this._http.get(AppConfig.ipAddress + 'dohvatiJezikeTaga'+ id)
        .pipe(map((res: any) => res      
        ));
    }

    getTagLanguage(a: any){
        return this._http.post(AppConfig.ipAddress + 'tag/getLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiTagoveZaContent(a: any){
        return this._http.post(AppConfig.ipAddress + 'tag/dohvati', a)
        .pipe(map((res: any) => res      
        ));
    }

}