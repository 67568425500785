import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(private _http: HttpClient) { }

  oneObject(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
      .pipe(map((res: any) => res));
  }

  addOffer(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dodajPonudu', a)
      .pipe(map((res: any) => res));
  }

  fetchOffers(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiSvePonude', a)
      .pipe(map((res: any) => res));
  }

  activateOffer(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/aktivirajJednuPonudu', a)
      .pipe(map((res: any) => res));
  }

  getOffer(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiJednuPonudu', a)
      .pipe(map((res: any) => res));
  }

  editOffer(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/urediPonudu', a)
      .pipe(map((res: any) => res));
  }

  deleteOffer(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/obrisiPonudu', a)
      .pipe(map((res: any) => res));
  }

  getLanguage(a: any) {
    return this._http.put(AppConfig.ipAddress + 'language/getOfferForYouLanguage', a)
      .pipe(map((res: any) => res));
  }

  editLanguage(a: any) {
    return this._http.put(AppConfig.ipAddress + 'language/editOfferForYouLanguage', a)
      .pipe(map((res: any) => res));
  }

  upload(a: any){
    return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
      .pipe(map((res: any) => res));
  }

  deleteImage(a: any){
    return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
      .pipe(map((res: any) => res));
  }

}
