import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { addInterestService } from './add-interes.service';
import { BasicInfoService } from './../../basic-info/basic-info.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AppConfig } from './../../../config';

declare const google: any;
 import notify from 'devextreme/ui/notify';
import "rxjs/add/operator/toPromise";
import { TagsService } from '../../tags/tags.service';

@Component({
  selector: 'app-add-interes',
  templateUrl: './add-interes.component.html',
  styleUrls: ['./add-interes.component.scss'],
  providers: [addInterestService, BasicInfoService, TagsService]
})
export class AddInteresComponent implements OnInit {

  adresaImage = AppConfig.adresaImage;
  odabraniStartTime: any;
  odabraniEndTime: any;
  odabranGrad: any;
  odabranType: any;
  odabranParking: any;
  odabranPetFriendly: any;
  allCities = [];
  types: any;
  parking: any;
  petFriendly: any;
  token: any;
  latitude: number;
  longitude: number;
  zoom: number = 18 //15;
  address: string;
  private geoCoder;
  value:any;
  valuepdf: any;
  newObject = {
    'access_token': '',
    "type": '',
    "phone": '',
    "city": '',
    "address": '',
    "gallery":[],
    "pdf": [],
    "name": '',
    "email": '',
    "web": '',
    "parking": '',
    "petFriendly": '',
    "openHours": '',
    // "startTime": '',
    // "endTime": '',
    "shortDescription": '',
    "view360": '',
    "location":{"lon": 0, "lat": 0 },
    'defaultJezik': null,
    'tags': [],
    'linkovi': []
  };
  location: Location;
  galerijaPostoji = false;
  fotke = [];
  limitUpload = false;
  otvoriPopupPDF = false;
  pdfDatoteka = [];
  imaPDF = false;
  dodanPDF = false;
  nijeDodanPDF = false;
  event: any;
  termSearch: any;
  gradoviPopup = false;
  gradovi: any;
  height: any;
  prikaziGaleriju = false;
  slike = [];
  loadingVisible = false;
  draggingIndex: number;
  popisJezika = [];
  odabraniZadaniJezik: string = 'hr';

  tagsList: any = [];
  linkovi = [];

  @ViewChild('search', {static: false}) public searchElementRef: ElementRef;
  @ViewChild('map', {static: false}) public mapElementRef: ElementRef;

  constructor(
    private addInterestService: addInterestService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private router: Router,
    private _location: Location,
    private basicInfoService: BasicInfoService,
    private _tagsService: TagsService
  ){
    this.token = sessionStorage.getItem('token');
    this.startTime = this.startTime.bind(this);
    this.endTime = this.endTime.bind(this);
    this.changeParking = this.changeParking.bind(this);
    this.changePetFriendly = this.changePetFriendly.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.getTags();

    this.types = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}        
    ]

    this.parking = [
      { id: 'NO', name: 'NO'},
      { id: 'YES', name: 'YES'}
    ]

    this.petFriendly = [
      { id: 'NO', name: 'NO'},
      { id: 'YES', name: 'YES'}
    ]
   }

  onContentReady(e){   
    e.component.getEditor('name').focus();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift( 
      {
        location: 'before',
        template: 'naslovTablice',    
      }
    )
  }

  getTags(){
    this.tagsList = [];
    this._tagsService.dohvatiSveTagove({'jezik': this.odabraniZadaniJezik}).subscribe(res =>{
      if(res.success === true){
        this.tagsList = res.data;
      }
    })
  }

  addLink($event){
    console.log($event);
  }

  cellClickEvent($event){
    console.log($event);
  }

  onInitNewRow($event){
    console.log($event);
  }

    // Promijena redoslijeda slika
    private _reorderItem(fromIndex: number, toIndex: number): void {
      const itemToBeReordered = this.fotke.splice(fromIndex, 1)[0];
      this.fotke.splice(toIndex, 0, itemToBeReordered);
      this.draggingIndex = toIndex;
    }
  
    onDragStart(index: number): void {
      this.draggingIndex = index;
    }
  
    onDragEnter(index: number): void {
      if (this.draggingIndex !== index) {
        this._reorderItem(this.draggingIndex, index);
      }
    }
  
    onDragEnd(): void {
      this.draggingIndex = undefined;
    }

  odustani(){
    this._location.back();
  }
    // Get Current Location Coordinates
    setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 18//15;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    }
  
    markerDragEnd($event: MouseEvent) {
      this.latitude = $event.coords.lat;
      this.longitude = $event.coords.lng;
      this.getAddress(this.latitude, this.longitude);
      this.newObject.location.lat = this.latitude;
      this.newObject.location.lon = this.longitude;
    }
  
    getAddress(latitude, longitude) {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 18 // 15;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      });
    }

    changeType(e){
      this.odabranType = e.value;
    }

    changeParking(e){
      this.odabranParking = e.value;
    }

    changeLanguage(e){
      this.odabraniZadaniJezik = e.value;
      this.getTags();
    }

    changePetFriendly(e) {
      this.odabranPetFriendly = e.value;
    }
   
    dodajGrad(e){
      this.gradoviPopup = true;
    }
 
    zatvorenPopup(){
      this.termSearch = '';
    }

    trazim(){
      const term = this.termSearch;     
      if (term.length >= 3) {  
        this.addInterestService.dohvatiGradoveFilter({ime: term}).subscribe(res => {
          this.gradovi = res;
        })
      }
    }
   
    gradoviPopupClose(e){
      this.gradoviPopup = false;
      // console.log(this.odabranGrad);  
      this.gradovi = []; 
     }
  
     odabirGrada(index: number, data: any, isChecked: boolean){
      if (isChecked === true) {    
          this.odabranGrad = data.cityName         
      }
}

/** ----------------------- galerija slika------------------------------------ */
slikePrikaz(){
  this.slike = [];
  for (var x in this.fotke){
    this.slike.push(
       this.adresaImage + '/imagesUpload/' + this.fotke[x] 
       )
     }   
}

// galerija slika 
  otvoriGaleriju(){
    this.slikePrikaz();
    this.prikaziGaleriju = true;
}

    pdf(e){
      this.otvoriPopupPDF = true;     
    }

    uploadPDF(event){
      this.valuepdf =  event.target.files;
      this.uploadpdf(event.target.value);
    }

    uploadpdf(data){
        let fileList: FileList = this.valuepdf;
          if(fileList.length > 0){
            let file: File = fileList[0];         
            let formData:FormData = new FormData();
              formData.append('datoteka', file);
            let headers = new Headers();
              headers.append('Content-Type', newFunction());            
            this.addInterestService.uploadPDF(formData).subscribe(res => {             
              this.pdfDatoteka.push(res.name + res.extension);   
              if(this.pdfDatoteka.length != 0) {
                this.imaPDF = true;
              } else {
                this.imaPDF = false;
              }
            });  
          }
      }

      doneClick(){
        this.dodanPDF = true;
        this.otvoriPopupPDF = false;        
      }
  
      deletePDF(pdf, i){
        this.addInterestService.deletePDF({'fileName': pdf}).subscribe(res => {               
              if(res.success == true) {
                this.pdfDatoteka.splice(i, 1)
           }
        })
     }

    startTime(e){
      if(e.value != null){
        this.odabraniStartTime = moment(e.value).format('HH:mm');
      }else{
        this.odabraniStartTime = moment(e.previousValue).format('HH:mm');
      }      
    }

  endTime(e){
    if(e.value != null){
      this.odabraniEndTime = moment(e.value).format('HH:mm');
    }else{
      this.odabraniEndTime = moment(e.previousValue).format('HH:mm');
    }       
  }


  dodajFotku(event){
    this.value =  event.target.files;
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {

    this.height = img.height;
    this.uploadDoc(event.target.value);
  }
}


  ukloniFotku(slika, i){
    // this.fotke.splice(i, 1);
    this.addInterestService.deleteImage({'fileName': slika}).subscribe(res => {
      if(res.success == true){
       this.fotke.splice(i, 1);
      }  else { }
      if(this.fotke.length == 5){
       notify('Only 5 photos can be uploaded!');
       this.limitUpload = true;
     } else {
       this.limitUpload  = false;
     }
    })
  }

  uploadDoc(data){
      let fileList: FileList = this.value;
        if(fileList.length > 0){
          let file: File = fileList[0];         
          let formData:FormData = new FormData();
            formData.append('datoteka', file);
            formData.append('height', this.height);
          let headers = new Headers();
            headers.append('Content-Type', newFunction());            
          this.addInterestService.upload(formData).subscribe(res => {         
            this.fotke.push(res.name + res.extension);
            if(this.fotke.length != 0) {
              this.galerijaPostoji = true;
            } else { 
              this.galerijaPostoji = false
            }
            if(this.fotke.length == 5){
              notify('Only 5 photos can be uploaded. Delete one first!');
              this.limitUpload = true;
            }
          });  
        }
    }

    save(data: any){
      this.loadingVisible = true;
      if(data.location.lat === 0){
         data.location.lat = this.latitude;
      }
      if(data.location.lon === 0) {
         data.location.lon = this.longitude;
      }
      // if(this.odabraniStartTime == undefined){
      //   this.odabraniStartTime = '';
      // }
      // if(this.odabraniEndTime == undefined){
      //   this.odabraniEndTime = '';
      // }  
      this.newObject.access_token = this.token,
      this.newObject.type = data.type,       //this.odabranType,      
      this.newObject.name = data.name,
      this.newObject.city = this.odabranGrad,
      this.newObject.address = data.address,
      this.newObject.phone = data.phone,
      this.newObject.email = data.email,
      this.newObject.web = data.web,
      this.newObject.shortDescription = data.shortDescription,
      this.newObject.location = {lat: data.location.lat, lon: data.location.lon},
      this.newObject.parking = this.odabranParking,
      this.newObject.petFriendly = this.odabranPetFriendly,
      this.newObject.view360 = data.view360,
      // this.newObject.openHours = this.odabraniStartTime + '-' + this.odabraniEndTime 
      this.newObject.openHours = data.openHours,
      this.newObject.gallery = this.fotke,
      this.newObject.pdf = this.pdfDatoteka;
      this.newObject.defaultJezik = data.defaultJezik;
      this.newObject.tags = data.tags;
      this.newObject.linkovi = this.linkovi;
      
          /** Validacija za formu */
    if(data.name == "" || data.type == "" || data.city == undefined || data.defaultJezik == null){
      this.loadingVisible = false;
      notify('Please fill in the required fields!', 'error');
    } else {
      this.addInterestService.addInterest(this.newObject).subscribe(res=>{
        this.loadingVisible = false;
        if(res.success == true) {
           this.router.navigate(['/content/'])
        }
      })      
    }
 }

    ngOnInit() {
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
          this.geoCoder = new google.maps.Geocoder;
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ["geocode"]
          });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              //get the place result
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              //verify result
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              //set latitude, longitude and zoom
              this.latitude = place.geometry.location.lat();
              this.longitude = place.geometry.location.lng();
              this.zoom = 18 //15;
              this.newObject.location.lat = this.latitude;
              this.newObject.location.lon = this.longitude;
              });
            });
          });
          this.dohvatiDostupneJezike();
    }

    dohvatiDostupneJezike(){
      this.basicInfoService.dohvatiJezike({}).subscribe(res=> {
          if(res.success == true){
            this.popisJezika = res.data;
          } else {
            notify('Error retrieving language list!', 'error');
          }
      })
    }
    
}

function newFunction(): string {
	return 'multipart/form-data';
}