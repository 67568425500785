import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from './home.service';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ],
  providers: [HomeService]
})

export class HomeComponent {
  role: any;
  myID: any;
  brojObjekata: any;
  brojInteresa: any;
  
  constructor(private _homeService: HomeService,  public _router: Router) {
    this.role = localStorage.getItem('role');
    this.myID = localStorage.getItem('myID');

    this.dohvatiPodatke();
    sessionStorage.removeItem('objectsKey');
    sessionStorage.removeItem('contentKey');
  }

  dohvatiPodatke(){
    this._homeService.getData({}).subscribe(res => {
      // console.log(res);
      this.brojInteresa = res.content;
      this.brojObjekata = res.accommodation;      
    })
  }

}
