import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import validationEngine from "devextreme/ui/validation_engine";
import { OneGroupAddService } from './one-group.add.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-one-group-add',
  templateUrl: './one-group-add.component.html',
  styleUrls: ['./one-group-add.component.scss'],
  providers: [OneGroupAddService]
})
export class OneGroupAddComponent implements OnInit {
  grupe = [
    { id: 0, naziv: 'Grupa 1', scanKey: 'G1234-5555' },
    { id: 1, naziv: 'Grupa 2', scanKey: 'G1234-4433' },
    { id: 2, naziv: 'Grupa 3', scanKey: 'G1234-1212' },
    { id: 3, naziv: 'Grupa 4', scanKey: 'G1234-1000' }
  ]
  newObjectGroup = { };
  role: any;
  myID: any;
  objects: any;
  odabraniObjekti = [];
  odabraniObjektiNaziv: string;
  objektiSvi = [];
  loadingVisible = false;

  constructor(private oneGroupAddService: OneGroupAddService, private _location: Location) {
    this.role = localStorage.getItem('role');
    this.myID = localStorage.getItem('myID');

    this.validationCallbackScanKey = this.validationCallbackScanKey.bind(this);
    this.onValueChangedObject = this.onValueChangedObject.bind(this);
   }

  ngOnInit() {
    this.allObjects();
    }

  onContentReady(e){   
    e.component.getEditor('naziv').focus();
  }

  validationCallbackScanKey(e) { 
      var isValid = true;
      for (var i = 0; i < this.grupe.length; i++) {
        if(e.value != null){
              if (this.grupe[i].scanKey == e.value) {
                  isValid = false;             
                  break;
              }
            }
      }  
      return isValid;
  }

  onValueChangedObject(e, data){
    // this.objektiSvi = [];
    // this.odabraniObjekti = [];
    // this.odabraniObjektiNaziv = '';

        if(e.removedItems.length != 0){
          let index = this.objects.indexOf(e.removedItems[0].name);
          let index2 = this.objects.indexOf(e.removedItems[0].id);
          if (index > -1) {
            this.objektiSvi.splice(index, 1);
          } 
          if (index2 > -1) {
            this.odabraniObjekti.splice(index2, 1);
          } 
        } 
        if (e.addedItems.length != 0){
          this.objektiSvi.push(e.addedItems[0].name);
          this.odabraniObjekti.push(e.addedItems[0].id);
        }
        this.odabraniObjektiNaziv = JSON.stringify(this.objektiSvi).replace(/]|[[]/g, '').replace(/"/g,' ');
      // data.setValue(this.odabraniObjekti);   

}

  odustani(){
    this._location.back();
  }

  save(data: any){
    this.loadingVisible = true;
    // console.log(data);  
    let podaci = {
      name: data.naziv,
      scanKey: 'G'+ data.scanKey,
      accommodations: data.accommodations //  this.odabraniObjekti
    }  
      // console.log(podaci);      
      // console.log(validationEngine.validateGroup("newObjectGroup"));
      
      if(validationEngine.validateGroup("newObjectGroup").isValid === true){
    // if(validationEngine.validateGroup("newObjectGroup").isValid === true && this.odabraniObjekti.length > 0){
      // console.log(podaci);
      this.oneGroupAddService.addGroup(podaci).subscribe(res => {
        this.loadingVisible = false;    
        // console.log(res);  
        if(res.success == true) {
          notify('Object group has been successfully created.', 'success')
          this._location.back();        
        } else {
          notify('Something went wrong. Try again.', 'error');
        } 
      })

    } else {
      notify('Please fill in the required fields!', 'error');
      this.loadingVisible = false;    
    }
  }


  allObjects(){
    this.oneGroupAddService.objects({"myID": this.myID, "role": this.role}).subscribe(res => {
       this.objects = res;
      //  console.log(this.objects);       
    })
  }

}
