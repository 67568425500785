import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AboutService } from './about.service';
import { AppConfig } from '../../config';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';
import { BasicInfoService } from '../basic-info/basic-info.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [AboutService, BasicInfoService]
})
export class AboutComponent implements OnInit {  
  @ViewChild("aboutForm", { static: false }) aboutForm: DxFormComponent;
  @ViewChild("formaJezik", { static: false }) formaJezik: DxFormComponent;  
  adresaImage = AppConfig.adresaImage;
  token: any;
  id: any;
  detalji = {   
      "access_token": '',
      "id": '',
      "fewWords": '',
      "gallery": [],
      "email": '',
      "receptionPhone": '',
      "website": '',
      "wifi": '',
      "houseRules": '',
      "audio": ''
    }
   imaSlike = false;
   value: any;
   fotke = [];
   openPopup = false;
   imeObjekta: any;
   detaljiAbout: any;
   limitUpload = false;
   popupPrijevod = false;
   textPrijevod = {};
   jezikTeksta: any;
   jezikTextaObjekt: any;
   prijevodDescription: any;
   prijevodHouseRules: any;
   idObjekta: any;
   height: any;
   prikaziGaleriju = false;
   slike = [];
   itemTemplate: any;
   loadingVisible = false;
   prijevodWeb: any;
   draggingIndex: number;
   valueAudio: any;
   audioprikaz: any;
   audioHR: any;
   jezikPrikaz: any;
   audio: any;
   popisJezikaEdit = [];
   jezikPrijevoda: string;

  constructor(
    private aboutService: AboutService,
    private basicInfoService: BasicInfoService,
    private _route:ActivatedRoute,
  ) {
    this.token = sessionStorage.getItem('token');
    this.id = this._route.snapshot.params['id'];
    //  console.log(this.id)
    this.aboutService.oneObject({"id": this.id}).subscribe(res => {
      // console.log(res)
      this.imeObjekta = res.name; 
      this.idObjekta = res.ownerId;
      this.dohvatiMoguceJezike(res.defaultJezik);
    });

    this.about();
    this.slikePrikaz();
  }

  // Promijena redoslijeda slika
  private _reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.fotke.splice(fromIndex, 1)[0];
    this.fotke.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragStart(index: number): void {
    this.draggingIndex = index;
  }

  onDragEnter(index: number): void {
    if (this.draggingIndex !== index) {
      this._reorderItem(this.draggingIndex, index);
    }
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }


  onShown(e){   
    this.aboutForm.instance.focus();
  }

  onShownJezik(e){   
    this.formaJezik.instance.focus();
  }
  
  /** ----------------------- galerija slika------------------------------------ */
  slikePrikaz(){
    this.slike = [];
    this.aboutService.about({"id": this.id}).subscribe(res => {
    for (var x in res['gallery']){
     this.slike.push(
        this.adresaImage + '/imagesUpload/' + res['gallery'][x] 
        )
      }   
    })
  }
  
    otvoriGaleriju(){
      this.prikaziGaleriju = true;
  }

  dodajFotku(event){    
    this.value =  event.target.files;
    let img = new Image();
   img.src = window.URL.createObjectURL(event.target.files[0])
   img.onload = () => {

    this.height = img.height;
    // console.log(this.height) 
    this.uploadDoc(event.target.value);    
     }
  }

  ukloniFotku(slika, i){
    // console.log(slika, i);    
    //  console.log(this.fotke.length)
     this.aboutService.deleteImage({'fileName': slika}).subscribe(res => {
      //  console.log(res);     
       if(res.success === true){
        this.fotke.splice(i, 1);
        this.brisanjeSlikaIzBaze();
       }  else { }
      //  console.log(this.fotke.length)
       if(this.fotke.length == 5){
        notify('Only 5 photos can be uploaded!');
        this.limitUpload = true;
      } else {
        this.limitUpload  = false;
      }
    })
  }

  uploadDoc(data){
    // console.log(data);
    // console.log(this.value);
      let fileList: FileList = this.value;
        if(fileList.length > 0){
          let file: File = fileList[0];         
          let formData:FormData = new FormData();
            formData.append('datoteka', file);
            formData.append('height', this.height);
          let headers = new Headers();
            headers.append('Content-Type', newFunction()); 

                this.aboutService.upload(formData).subscribe(res => {
                  // console.log(res);
                  this.fotke.push(res.name + res.extension);
                  if(this.fotke.length != 0) {
                    this.imaSlike = true;
                  } else { 
                    this.imaSlike = false
                  }
                  if(this.fotke.length == 5){
                    notify('Only 5 photos can be uploaded!');
                    this.limitUpload = true;
                  } else {
                    this.limitUpload  = false;
                  }            
              });  
      }
  }
    
   about(){
    this.aboutService.about({"id": this.id}).subscribe(res => {
      // console.log(res);  
      this.about = res;   
    //  console.log(res.gallery.length) 
      this.fotke = res.gallery;
      this.audioHR = res.audio;
      // console.log(this.fotke)
      // console.log(this.adresaImage + '/imagesUpload');
      
  
      if(this.fotke.length != 0){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }
    });
  }

    editAbout(){
      // console.log(this.fotke)
      if(this.fotke.length == 5){
        notify('Only 5 photos can be uploaded!');
        this.limitUpload = true;
      }   
      this.openPopup = true; 
      this.jezikTeksta = 'hr'
      // console.log(this.imaSlike);      
    }


    brisanjeSlikaIzBaze(){
      // console.log(this.fotke);      
      this.detalji.access_token = this.token,
      this.detalji.id = this.id,
      this.detalji.gallery = this.fotke      
      this.aboutService.editAbout(this.detalji).subscribe(res => {
        // console.log(res)
      }) 
    }

    saveEdit(e){   
      // console.log(this.fotke);         
      this.loadingVisible = true;     
      this.detalji.access_token = this.token,
      this.detalji.id = this.id,
      this.detalji.fewWords = e.fewWords || null,
      this.detalji.gallery = this.fotke,
      this.detalji.email = e.email || null,
      this.detalji.receptionPhone = e.receptionPhone || null,
      this.detalji.website = e.website || null,
      this.detalji.wifi = e.wifi || null,
      this.detalji.audio = this.audioHR
      // console.log(this.detalji);
      
      this.aboutService.editAbout(this.detalji).subscribe(res => {
        this.loadingVisible = false;
        this.openPopup = false;
        // console.log(res);   
      })    
     }

  ngOnInit() {  }

  odustani(){
    this.openPopup = false;
  }  
   //** PRIJEVOD NA JEZIKE */
   dohvatiMoguceJezike(defaultJezik){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.id, 'jezik': defaultJezik, 'ekran': 'About'}).subscribe(res=> {
        // console.log(res);
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    // console.log($event);
    this.popupPrijevod = true;
    this.jezikTeksta = $event.selectedItem.text;
    this.jezikPrijevoda = $event.selectedItem.name;
    this.aboutService.getLanguage({
        accommodationId: this.id, 
        language: $event.selectedItem.text
    }).subscribe(res=>{
      // console.log(res);
      if(res.success == false){
        notify(res.data, 'error');
        this.audio = null;
      } else {
        this.textPrijevod['description'] = res.data.welcome != undefined ? res.data.welcome.fewWords : '';
        this.textPrijevod['web'] = res.data.welcome != undefined ? res.data.welcome.web : '';
        this.audio = res.data.welcome != undefined ? res.data.welcome['audio']  : '';
      }
    })
  }

  spremiPrijevod(e){
    //console.log(e);
    //console.log(this.audio)
    this.popupPrijevod = false;
    this.prijevodDescription = e.description;
    this.prijevodWeb = e.web;
    this.aboutService.editLanguage({
        'accommodationId': this.id,
        'jezik': this.jezikTeksta,
        'welcome': {
          fewWords: this.prijevodDescription || null,
          web: this.prijevodWeb || null,
          audio: this.audio || null
        }
    }).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
        notify('Your translation is saved.', 'success');
      }
    })
  }

   odustaniPrijevod(){
    this.popupPrijevod = false;
    this.textPrijevod = {};
  }


  /**------------------------ AUDIO --------------------------------*/
  kreirajAudio(e){        
    this.valueAudio =  e.target.files;
    this.audioprikaz = window.URL.createObjectURL(e.target.files[0])
    this.uploadAudio(e.target.value);   
  }

  uploadAudio(data){
    let fileList: FileList = this.valueAudio;
      if(fileList.length > 0){
        let file: File = fileList[0];    
        // console.log(this.valueAudio);       
        let formData:FormData = new FormData();
          formData.append('audio', file);
          formData.append('language', this.jezikTeksta);
        let headers = new Headers();
          headers.append('Content-Type', newFunction());            
        this.aboutService.uploadAudio(formData).subscribe(res => {  
          // console.log(res);
           this.jezikPrikaz = res.language;                          
           this.audioHR = res.name + res.extension
           sessionStorage.setItem("aboutaudiohr", this.audioHR);          
        });  
      }
  }

  ukloniAudio(audio, jezik, i){   
    // console.log(jezik);     
    this.aboutService.deleteImage({'fileName': audio}).subscribe(res => {
      // console.log(res);    
      if(res.success == true){   
          if(jezik === 'hr'){
            this.audioHR = null;
            sessionStorage.removeItem('aboutaudiohr')            
          }
          this.brisanjeAudioIzBaze();
        }          
    } )
  }

  brisanjeAudioIzBaze(){    
    this.detalji.access_token = this.token,
    this.detalji.id = this.id,
    this.detalji.gallery = this.fotke,
    this.detalji.audio = this.audioHR      
    this.aboutService.editAbout(this.detalji).subscribe(res => {
      // console.log(res)
    }) 
  }
  
  dodajAudioJezik(e){
    this.valueAudio =  e.target.files;
    this.audioprikaz = window.URL.createObjectURL(e.target.files[0])
    this.uploadAudioJezik(e.target.value);    
  }

  uploadAudioJezik(data){
    let fileList: FileList = this.valueAudio;
      if(fileList.length > 0){
        let file: File = fileList[0];    
        // console.log(this.valueAudio);       
        let formData:FormData = new FormData();
          formData.append('audio', file);
          formData.append('language', this.jezikTeksta);
        let headers = new Headers();
          headers.append('Content-Type', newFunction());            
        this.aboutService.uploadAudio(formData).subscribe(res => {  
          // console.log(res);
          this.audio = res.name + res.extension
           this.jezikPrikaz = res.language  
           sessionStorage.setItem("aboutaudio", this.audio);     
          })
        } else { }
    }

    ukloniAudioJezik(audio, sadrzaj){   
      this.prijevodDescription = sadrzaj.description;
      this.prijevodWeb = sadrzaj.web; 
           
      this.aboutService.deleteImage({'fileName': audio}).subscribe(res => {
        // console.log(res);    
        if(res.success == true){   
              this.audio = null;
              sessionStorage.removeItem('aboutaudio')  
              this.brisanjeAudioJezikIzBaze(this.jezikTeksta)          
            }       
      } )
    }


    brisanjeAudioJezikIzBaze(jezik){
      this.aboutService.editLanguage({
        'accommodationId': this.id,
        'jezik': jezik,
        'welcome': {
          fewWords: this.prijevodDescription || null,
          web: this.prijevodWeb || null,
          audio: this.audio || null
        }
      })
    }
  
}

function newFunction(): string {
	return 'multipart/form-data';
}