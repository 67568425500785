import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from './offers.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  providers: [OffersService]
})
export class OffersComponent implements OnInit {

  token: any;
  id: any;
  imeObjekta: any;
  offers: any;

  constructor(private _route:ActivatedRoute, private offersService: OffersService, private router: Router) {
    this.token = sessionStorage.getItem('token');
    this.id = this._route.snapshot.params['id'];
    this.offersService.oneObject({"id": this.id}).subscribe(res => {
      this.imeObjekta = res.name; 
    });
  }

  ngOnInit() {
    this.fetchOffers();
  }

  addNew() {
    this.offersService.addOffer({id: this.id}).subscribe(res=> {
      this.fetchOffers();
    });
  }

  fetchOffers() {
    this.offersService.fetchOffers({id: this.id}).subscribe(res=> {
      this.offers = res.podaci.reverse();
    });
  }

  activateOffer(id) {
    // console.log(id);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    this.offersService.activateOffer({id: this.id, idOffer: id}).subscribe(res=> {
      this.fetchOffers();
    });
  }

  editOffer(id) {
    this.router.navigate(['/one-offer/' + id + '/' + this.id]);
  }

}
