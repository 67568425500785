import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { ObjectGroupService } from './object-group.service';

@Component({
  selector: 'app-object-group',
  templateUrl: './object-group.component.html',
  styleUrls: ['./object-group.component.scss'],
  providers: [ObjectGroupService]
})
export class ObjectGroupComponent implements OnInit {
  grupe = [ ];
  token: any;
  loadingVisible = false;
  objects: any;
  role: any;
  myID: any;

  constructor(private objectGroupService: ObjectGroupService, private router: Router) {
    this.token = sessionStorage.getItem('token');
    this.role = localStorage.getItem('role');
    this.myID = localStorage.getItem('myID');

    this.calculateDisplayValue  =this.calculateDisplayValue.bind(this)
   }

  ngOnInit() { 
    this.sveGrupe(); 
   }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift( 
      {
      location: 'before',
      template: 'naslovTablice',    
    },
    {
      locateInMenu:'auto',
      location: 'after',
      template: "addObjectGroup",
    },
    )
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "name"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.color = "#E6861B";
        e.cellElement.style.cursor = 'pointer'
      //  e.cellElement.style.fontSize = "16px";
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.color = "white";
        //  e.cellElement.style.fontSize = "14px";
      }  
    }
  }

  calculateCellValue(data) {
    // console.log(data);    
    return data.accommodations.length > 0 ? data.accommodations.join(', ') : " ";
  }

    calculateDisplayValue(data) {
      // let that = this;
      // that.allObjects();
      // console.log(data);
      //   // console.log(this.objects);
      // console.log(this.objects);       
      var displayText = '';
      for (var i in data['accommodations']) {
          var acc = data['accommodations'][i]
          displayText += acc + ', ';
          // console.log(acc);
          
    }
      displayText = displayText.slice(0, -2); // trim trailing delimter
      // console.log(displayText);
      return displayText;
  }

    calculateFilterExpression (filterValue, selectedFilterOperation) {   
      return [this.calculateCellValue, "contains", filterValue];
    }
  
    // da ide na detalj
    cellClickEvent(e){
      // console.log(e);      
      let navigationExtras: NavigationExtras = {
        state: { ekran: 'samoPrikaz' }         
      };
     if (e.rowType == "data" && e.column.dataField === "name") { 
       this.router.navigate(['one-group/' + e.data._id], navigationExtras);
     }
   }

  addObjectGroup(){
    // console.log('Dodavanje nove grupe');
    this.router.navigate(['one-group-add'])    
  }

  editObjectGroup(data: any, ){
    // console.log(data);
    this.router.navigate(['one-group/' + data.data._id])
  }

  deleteObjectGroup(e){
    this.objectGroupService.delete({id: e.data._id}).subscribe(res =>{
      // console.log(res);
      if(res.success == true) {
        notify('Object group has been successfully deleted.', 'success')
        this.sveGrupe();        
      } else {
        notify('Something went wrong. Try again.', 'error');
      } 
    })
  }

  sveGrupe(){
    this.objectGroupService.groups({}).subscribe(res =>{
      // console.log(res);
      this.grupe = res.data;
    })

  }

}
