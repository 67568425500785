import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';

import { DevExtremeModule, DxCheckBoxModule, DxTabPanelModule, DxTabsModule } from 'devextreme-angular';
import { AgmCoreModule } from '@agm/core';

import { BasicInfoComponent } from './pages/basic-info/basic-info.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ObjectsComponent } from './pages/objects/objects.component';
import { HttpClientModule } from '@angular/common/http';
import { ContentComponent } from './pages/content/content.component';
import { UsersComponent } from './pages/users/users.component';
import { OneObjectComponent } from './pages/objects/one-object/one-object/one-object.component';
import { AboutComponent } from './pages/about/about.component';
import { YouMayNeedComponent } from './pages/you-may-need/you-may-need.component';
import { PostcardsComponent } from './pages/postcards/postcards.component';
import { AddInteresComponent } from './pages/interes/add-interes/add-interes.component';
import { EditInteresComponent } from './pages/interes/edit-interes/edit-interes.component';
import { AnaliticsComponent } from './pages/analitics/analitics.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OffersComponent } from './pages/offers/offers.component';
import { OneOfferComponent } from './pages/offers/one-offer/one-offer.component';
import { SelectedContentComponent } from './pages/selected-content/selected-content.component';
import { TypesComponent } from './pages/types/types.component';
import { NotificationsListComponent } from './pages/notifications-list/notifications-list.component';
import { NotificationDetailComponent } from './pages/notification-detail/notification-detail.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AudioGuideComponent } from './pages/audio-guide/audio-guide.component';
import { AudioPointsComponent } from './pages/audio-points/audio-points.component';
import { ObjectGroupComponent } from './pages/object-groups/object-group/object-group.component';
import { OneGroupComponent } from './pages/object-groups/one-group/one-group.component';
import { OneGroupAddComponent } from './pages/object-groups/one-group-add/one-group-add.component';
import { ObjectTypesComponent } from './pages/object-types/object-types.component';
import { ViatorComponent } from './pages/viator/viator.component';
import { TagsComponent } from './pages/tags/tags.component';
// import { NotificationsComponent } from './pages/notifications/notifications.component';

@NgModule({
  declarations: [
    AppComponent,
    BasicInfoComponent,
    SettingsComponent,
    ObjectsComponent,
    ContentComponent,
    UsersComponent,
    OneObjectComponent,
    AboutComponent,
    YouMayNeedComponent,
    PostcardsComponent,
    AddInteresComponent,
    EditInteresComponent,
    AnaliticsComponent,
    OffersComponent,
    OneOfferComponent,
    SelectedContentComponent,
    TypesComponent,
    NotificationsListComponent,
    NotificationDetailComponent,
    NotificationsComponent,
    AudioGuideComponent,
    AudioPointsComponent,
    ObjectGroupComponent,
    OneGroupComponent,
    OneGroupAddComponent,
    ObjectTypesComponent,
    TagsComponent,
    ViatorComponent
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    AppRoutingModule,
    DevExtremeModule,
    HttpClientModule,
    DxTabPanelModule,
    DxTabsModule,
    DxCheckBoxModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAmRgH2Gcvu6zkGg3Z3wKulFZwhajzP2mQ',
      libraries: ["places"]
    })
  ],
  providers: [AuthService, ScreenService, AppInfoService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
