import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    allUsers(a: any){
        return this._http.get(AppConfig.ipAddress + 'users', a)
        .pipe(map((res: any) => res      
        ));
    }

    oneUser(a: any){
        return this._http.get(AppConfig.ipAddress + 'users/dohvati/', a)
        .pipe(map((res: any) => res      
        ));
    }

    password(a: any){
        return this._http.put(AppConfig.ipAddress + 'users/password/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

    active(a: any){
        return this._http.put(AppConfig.ipAddress + 'users/aktiviraj/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

    addUser(a: any){
        return this._http.post(AppConfig.ipAddress + 'users/', a)
        .pipe(map((res: any) => res      
        ));
    }

    editUser(a: any){
        return this._http.put(AppConfig.ipAddress + 'users/uredi/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

}

