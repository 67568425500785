import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()

export class AppConfig {

 /*  public static ipAddress = 'https://lab.fortuno.hr:7001/';
  public static adresaImage='https://lab.fortuno.hr:7071'; */

  public static ipAddress = 'https://app.guideforyou.eu:7001/';
  public static adresaImage='https://app.guideforyou.eu:';
 
}