import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import notify from 'devextreme/ui/notify';

import { SelectedContentService } from './selected-content.service';

@Component({
  selector: 'app-selected-content',
  templateUrl: './selected-content.component.html',
  styleUrls: ['./selected-content.component.scss'],
  providers: [SelectedContentService]
})
export class SelectedContentComponent implements OnInit {

  id: any;
  token: any;
  allInterests = [];
  types: any;
  allCities = [];
  naslov: string;
  window: any;
  podaciReorder: any;
  prikazTipovi = [];
  customFilter: any;

  constructor(
    private _route: ActivatedRoute,
    private contentService: SelectedContentService,
    private router: Router
  ) {
    this.token = sessionStorage.getItem('token');
    this.id = this._route.snapshot.params['id'];

    this.onShowing = this.onShowing.bind(this);

    this.onReorder = this.onReorder.bind(this);

    this.types = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}           
    ];
    // console.log(this.types);

    this.sviInteresi();
    this.sviGradoviObjekata();

    /// custom filter  /////
    this.customFilter = [
      {text: 'Accommodation', value: 'accommodation'},
      {text: "Beach",value: 'beach'}, 
      {text: "Feel and taste",value: 'feelAndTaste'},
      {text: 'Happenings', value: 'happenings'},
      {text: "Health And Beauty",value: 'healthAndBeauty'}, 
      {text: "History Attraction",value: 'historyAttraction'}, 
      {text: "Indoor",value: 'indoor'}, 
      {text: "Organized Trip",value: 'organizedTrip'},      
      {text: "Restaurant",value: 'restaurant'}, 
      {text: "Shoping and groceries",value: 'shopingAndGroceries'},
      {text: "Sight Of Nature",value: 'sightOfNature'}, 
      {text: "Sport And Recreation",value: 'sportAndRecreation'},          
    ]; 
  }

  aktiviraj(e, data){
    // console.log(data);
    this.contentService.dodajInteres({'id': this.id, 'interestId': data.key}).subscribe(res=> {
      // console.log(res);
      if(res.success == true) {
        this.sviInteresi();
      }
    })   
  }
  deaktiviraj(e, data){
    // console.log(data);
    this.contentService.ukloniInteres({'id': this.id, 'interestId': data.key}).subscribe(res=> {
      // console.log(res);
      if(res.success == true) {
        this.sviInteresi();
      }
    })   
  }

  oneInteres(e){
    this.naslov = 'Edit';
  }

  onToolbarPreparing(e) { 
      e.toolbarOptions.items.unshift(
        {
          locateInMenu:'auto',
          location: 'after',
          template: "saveReorder",
        },
        {
          location: 'before',
          template: 'naslovTablice',    
        },
        {
          locateInMenu:'auto',
          location: 'after',
          template: "exportLng",
        },
    )      
  }

  onInitNewRow(e){
    this.naslov = 'Add new';
  }

  onShowing(e){  
    e.component.option("title", this.naslov);
  }

  changeType(e) {
    // console.log(e);
  }

  sviInteresi(){
    this.contentService.dohvatiInterese({'id':this.id}).subscribe(res => {
       this.allInterests = res.podaci;

       this.podaciReorder = [];
       this.allInterests.forEach(element => {
         this.podaciReorder.push(element.id)
       });
      // console.log(this.podaciReorder);       
    })
  }

  sviGradoviObjekata(){
    this.contentService.gradoviObjekata({}).subscribe(res => {
       this.allCities = res;
    })
  }

  onReorder(e) {
    var visibleRows = e.component.getVisibleRows(),
        toIndex = this.allInterests.indexOf(visibleRows[e.toIndex].data),
        fromIndex = this.allInterests.indexOf(e.itemData);

    this.allInterests.splice(fromIndex, 1);
    this.allInterests.splice(toIndex, 0, e.itemData);

    this.podaciReorder.splice(fromIndex, 1);
    this.podaciReorder.splice(toIndex, 0, e.itemData.id);
}

  saveReorder(e){
    // console.log({id: this.id, interestsorderby: this.podaciReorder});    
    this.contentService.urediInterestOrderBy({id: this.id, interestsorderby: this.podaciReorder}).subscribe(res => {
      // console.log(res);  
      if(res.success === true){
        this.sviInteresi();
        notify('Saved!', 'success');
      }  else {
        notify('Try again!', 'error');
      }  
    })
  }

  calculateCellValue(data) {
    return data.type.length > 0 ? data.type.join(', ') : " ";
  }

  calculateDisplayValue(data) {
    this.prikazTipovi = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}               
    ]  

    var displayText = '';
    for (var i in data['type']) {
        var type = data['type'][i];
        for(let i = 0; i < this.prikazTipovi.length; i++){
        if(type === this.prikazTipovi[i].id)  
        displayText += this.prikazTipovi[i].name + ', ';
    }
  }
    displayText = displayText.slice(0, -2); // trim trailing delimter
    return displayText;
}

  calculateFilterExpression (filterValue, selectedFilterOperation) {   
    return [this.calculateCellValue, "contains", filterValue];
  }

  onExporting(e){
    e.component.beginUpdate();
    e.component.columnOption("address", "visible", true);
    e.component.columnOption("email", "visible", true);
    e.component.columnOption("phone", "visible", true);
    e.component.columnOption("web", "visible", true);
  }

  onExported(e){
    e.component.columnOption("address", "visible", false);
    e.component.columnOption("email", "visible", false);
    e.component.columnOption("phone", "visible", false);
    e.component.columnOption("web", "visible", false);
    e.component.endUpdate();
  }

  saveExportLng(){
    this.contentService.contentExcel({'idObjekta': this.id}).subscribe(res => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = 'SelectedContent';
        link.click();
    })
  }

  ngOnInit() {}

}