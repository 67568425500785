import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class PostcardsService {

    constructor(private _http: HttpClient) { 
      // console.log(AppConfig.ipAddress)
    }
    
    kreirajPostcard(a: any){
      return this._http.post(AppConfig.ipAddress + 'upload/createPostcard', a)
      .pipe(map((res: any) => res));
    }

    prikazPostcards(a: any){
      return this._http.put(AppConfig.ipAddress + 'accommodation/razglednice', a)
      .pipe(map((res: any) => res));
    }

    spremiPostcard(a: any){
      return this._http.put(AppConfig.ipAddress + 'accommodation/urediPostcards', a)
      .pipe(map((res: any) => res));
    }
    

    deletePostcards(a: any){
      return this._http.post(AppConfig.ipAddress + 'upload/obrisiPostcard', a)
      .pipe(map((res: any) => res));
    }

    oneObject(a: any){
      return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
      .pipe(map((res: any) => res      
      ));
  }
  
  }