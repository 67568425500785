import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Location } from '@angular/common';
import { AppConfig } from 'src/app/config';
import { OneGroupService } from './one-group.service';
import { resolve } from 'url';

@Component({
  selector: 'app-one-group',
  templateUrl: './one-group.component.html',
  styleUrls: ['./one-group.component.scss'],
  providers: [OneGroupService]
})
export class OneGroupComponent implements OnInit {
  adresaImage = AppConfig.adresaImage;
  groupID: any;
  samoPrikaz = false;
  role: any;
  myID: any;
  objects: any;
  loadingVisible = false;
  nazivGrupe: any;
  objectGroup: any;
  imaQR = true;
  qrCode: any; 
  slikaMP: any;
  slikaMP2: any; 
  prikaziMP = false;
  odabraniObjektiNaziv: any;

  constructor(private oneGroupService: OneGroupService, private _route: ActivatedRoute, private router: Router, private _location: Location) {
    this.role = localStorage.getItem('role');
    this.myID = localStorage.getItem('myID');
    this.groupID = _route.snapshot.params['id']; 

    this.allObjects();  
    // this.oneGroup(); 

    if(this.router.getCurrentNavigation().extras.state){
      this.nazivGrupe = 'Preview'
      this.samoPrikaz = true;   
    } else {
      this.nazivGrupe = 'Edit Object group'
    } 
    // this.validationCallbackScanKey = this.validationCallbackScanKey.bind(this);
  }

    ngOnInit() {
 
      // setTimeout(() => {
      // this.oneGroup(); 
      // }, 300); 
   }
  
  onContentReady(e){   
    e.component.getEditor('name').focus();
  }

  generirajMediaPack(){ 
      this.prikaziMP = true; 
  }

//   validationCallbackScanKey(e) { 
//     // var isValid = true;
//     // for (var i = 0; i < this.grupe.length; i++) {
//     //   if(e.value != null){
//     //         if (this.grupe[i].scanKey == e.value) {
//     //             isValid = false;             
//     //             break;
//     //         }
//     //       }
//     // }  
//     // return isValid;
// }


  save(data: any){
    // console.log(data);
    this.loadingVisible = true;
    this.oneGroupService.edit({"id": this.groupID, "name": data.name, "accommodations": data.accommodations}).subscribe(res => {
      // console.log(res);
      this.loadingVisible = false;
      if(res.success == true) {
        notify('Success.', 'success')
        this._location.back();        
      } else {
        notify('Something went wrong. Try again.', 'error');
      } 
   })
  }

  odustani(){
    this._location.back();
  }
  
  allObjects(){
    this.objects = [];
    this.oneGroupService.objects({"myID": this.myID, "role": this.role}).subscribe(res => {
      //  this.objects = res;
      //  console.log(this.objects);          
       res.forEach(element => {
         this.objects.push({
           id: element.id, name: element.name
         })
       });    
      //  console.log(this.objects);            
      this.oneGroup();
    })
  }

  oneGroup(){
    this.oneGroupService.jednaGrupa({id: this.groupID}).subscribe(res => {
      // console.log(res);
      this.objectGroup = res.data;
      this.qrCode = res.data.QRCode;
      this.slikaMP = res.data.mediaPack;
      this.slikaMP2 = res.data.mediaPack2;     
      // console.log(this.objectGroup);  
    })
    // console.log(this.objectGroup);    
  }

}
