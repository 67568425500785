import { Injectable } from '@angular/core';

export class Locale {
  Name: string;
  Value: string;
}

let locales: Locale[] = [{
  "Name": "Hrvatski",
  "Value": "hr"
  }, {
  "Name": "English",
  "Value": "en"
}];

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    return 'Guide For You';
  }

  getLocales() {
    return locales;
  }
}