import { Component, OnInit } from '@angular/core';
import { AppInfoService, Locale } from 'src/app/shared/services';
import { locale } from 'devextreme/localization';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  locale: string;
  locales: Locale[];

  constructor(public appInfo: AppInfoService) {
    this.locale = this.getLocale();
    this.locales = appInfo.getLocales();
    locale(this.locale);
    this.setLocale(this.locale);
  }

  ngOnInit() {  }

  getLocale() {
    var locale = sessionStorage.getItem("locale");
    return locale != null ? locale : "en";
  }
  setLocale(locale) {
    sessionStorage.setItem("locale", locale);
  }
  changeLocale(data) {
    this.setLocale(data.value);
    parent.document.location.reload();
  }

}
