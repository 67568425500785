import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { event } from 'devextreme/events';
import notify from 'devextreme/ui/notify';

import { ViatorService } from './viator.service';

@Component({
  selector: 'app-types',
  templateUrl: './viator.component.html',
  styleUrls: ['./viator.component.scss'],
  providers: [ViatorService]
})
export class ViatorComponent implements OnInit {

viator: any;
accommodationID: any;

  constructor(
    private _viatorService: ViatorService,
    private _route: ActivatedRoute
  ) {
    this.accommodationID = this._route.snapshot.params['id'];
  }

   onToolbarPreparing(e) { 
     e.toolbarOptions.items.unshift(
    {
      location: 'before',
      template: 'naslovTablice',    
    })      
  }

  ngOnInit() {
    this.popis();
  }

  popis(){
    this._viatorService.dohvatiGradove({id: this.accommodationID}).subscribe(res =>{
      // console.log(res);
      this.viator = res.podaci;
    })
  }

  onClick(e: any, data: any){
    if(e.value === true){
      this._viatorService.dodajViatorDest({id: this.accommodationID, viatorID: data.key}).subscribe(res =>{
        // console.log(res);
        if(res.success === true){
          this.popis();
          notify(res.message, "success");
        } else {
          notify(res.message, "error");
        }
      })
      } else {
      this._viatorService.ukloniViatorDest({id: this.accommodationID, viatorID: data.key}).subscribe(res =>{
        // console.log(res);
        if(res.success === true){
          this.popis();
          notify(res.message, "success");
        } else {
          notify(res.message, "error");
        }
      })
    }
  }

}