import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class addInterestService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    addInterest(a: any){
        return this._http.post(AppConfig.ipAddress + 'interest/', a)
        .pipe(map((res: any) => res      
        ));
    }

    gradoviObjekata(a: any){
        return this._http.get(AppConfig.ipAddress + 'accommodation/vratiGradove', a)
        .pipe(map((res: any) => res      
        ));
    }

    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

    // uploadSlike(a: any){
    //     return this._http.post(AppConfig.ipAddress + 'upload/uploadSliku', a)
    //     .pipe(map((res: any) => res      
    //     ));
    // }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }

    uploadPDF(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadPDF', a)
        .pipe(map((res: any) => res      
        ));
    }

    deletePDF(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiPDF', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiSveGradove(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/cityAll', a)
        .pipe(map((res: any) => res));
    }

    dohvatiGradoveFilter(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/cityFilter', a)
        .pipe(map((res: any) => res));
    }

}