import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MapsAPILoader, MouseEvent} from '@agm/core';

import validationEngine from "devextreme/ui/validation_engine";
import { encodeHtml } from 'devextreme/core/utils/string'
import { custom } from 'devextreme/ui/dialog';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

import { AudioGuideService } from './audio-guide.service';
import { AppConfig } from './../../config';
import { BasicInfoService } from '../basic-info/basic-info.service';

declare const google: any;

@Component({
  selector: 'app-audio-guide',
  templateUrl: './audio-guide.component.html',
  styleUrls: ['./audio-guide.component.scss'],
  providers: [AudioGuideService, BasicInfoService]
})
export class AudioGuideComponent implements OnInit {

  adresaImage = AppConfig.adresaImage;
  @ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;
  @ViewChild(DxFormComponent, {static: false}) newLocation: DxFormComponent;
  naslov: any;
  dodavanje = false;
  window: any;
  podaci = {
    'name': '',
    'accommodationId': '',
    'location': {lat: 0, lon: 0},
    // 'QRCode': '',
    'picture': '',
    'geoKoord': false
    // 'language': {}
  };
  latitude: number;
  longitude: number;
  zoom: number = 18 //15;
  address: string;
  private geoCoder;
  location: Location;
  dodajPopup = false;
  prikaziSliku = false;
  loadingVisible = false;
  jezikPopup = false;
  sviJezici = [];  
  urediPopup = false;
  podaciEdit: any = {};
  id: any;
  lokacije: any;
  zadaniObjekt = { };
  zadanioObjektLength: any;
  varijablaZadani: any;
  value: any;
  height: any;
  imaSlike = false;
  fotka: any;
  qrCode: any;
  prikaziGaleriju = false;
  slike = [];
  offlineQrCode: any;
  imaQR = false;
  imaOfflineQR = false;
  locationID: any;
  @ViewChild('pretraga', {static: false}) public searchElementRef: ElementRef;
  @ViewChild('map', {static: false}) public mapElementRef: ElementRef;
  putanjazaQR: any;
  isEdited = false; 
  caption: string = "selected language";

  popisJezikaEdit = [];
  defaultJezik: string;
  jezikPrijevoda: string;
  jezikTeksta: string;
  odabraniDrugiJezik: boolean = false;
  geoKoord: boolean = false;
  
  constructor(
    private basicInfoService: BasicInfoService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public _router: Router,
    private audioGuideService: AudioGuideService,
    private _route: ActivatedRoute
  ) {
    this.id = this._route.snapshot.params['id'];
    this.basicInfoService.oneObject({"id": this.id}).subscribe(res => {
      this.defaultJezik = res.defaultJezik;
      this.caption = "selected language: " + res.defaultJezik; 
    });
    // console.log(this.adresaImage);
    this.putanjazaQR = this.adresaImage + '/audio-guide/#/lokacija/'
    this.sveLokoacije();
    
      // setTimeout(() => {  
      // this.tab1.instance.getEditor('name').focus(); 
      // }, 300) 
  }
  
  ngOnInit() { 
    // this.prikazLokacije();      
  }

  getPDF(data: any){
    this.audioGuideService.napraviPDFTocaka({ 'locationId': data.data.id }).subscribe(res => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = data.data.name + '-AudioTocke.pdf';
      link.click();
    });
  }

  onInitNewRow(e){
    this.isEdited = false;
  }

  onEditingStart(e){
    this.isEdited = true;
  }

  prikazLokacije(){
    setTimeout(() => {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["geocode"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
            this.podaci.location.lat = this.latitude;
            this.podaci.location.lon = this.longitude;
          });
        });
      });
    }, 10);
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.podaci.location.lat = this.latitude;
    this.podaci.location.lon = this.longitude;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',
    },
    { location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: this.popup.bind(this)
      }
    }
  )
}

  // da ide na detalj
  cellClickEvent(e){
    this.locationID = e.key.id;
    this.sviJezici = [];
    if (e.rowType == "data" && e.column.dataField === "defaultJezik") {
      this.jezikPopup = true;
      // this.idObjekta = e.key.id;
      for (const key in e.key.language) {
        if (e.key.language.hasOwnProperty(key)) {
          // console.log({name: `${key}`, text: `${key}`, value: `${e.key.language[key]}` });
          this.sviJezici.push({name: key, text: key, value: e.key.language[key]});
        }
      }
    }
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "defaultJezik"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.cursor = 'pointer';
      }
    }
  }

/** slika */
dodajFotku(event){
  this.value =  event.target.files;
  let img = new Image();
  img.src = window.URL.createObjectURL(event.target.files[0])
  img.onload = () => {
    // console.log(this.value);    
  this.height = img.height;
  this.uploadDoc(event.target.value);
  }
}

ukloniFotku(slika){ 
  this.audioGuideService.deleteImage({'fileName': slika}).subscribe(res => {
    if(res.success == true){
      this.fotka = null;
      this.imaSlike = false;
      if(this.isEdited){
        this.uklanjanjeSlika();
      }
    }}
  )
}

uklanjanjeSlika(){   
  this.audioGuideService.urediLokaciju({
    id: this.podaciEdit.id, 
    accommodationId: this.podaciEdit.accommodationId,
    picture: this.fotka,
    location: this.podaciEdit.location,
    QRCode: this.qrCode,
    OfflineQRCode: this.offlineQrCode,
    name: this.podaciEdit.hr.name
  }).subscribe(res => {
    // console.log(res);
  })  
}

  uploadDoc(data){
    let fileList: FileList = this.value;
      if(fileList.length > 0){
        let file: File = fileList[0];         
        let formData:FormData = new FormData();
            formData.append('datoteka', file);
            formData.append('height', this.height);
        let headers = new Headers();
            headers.append('Content-Type', newFunction());            
        this.audioGuideService.upload(formData).subscribe(res => {
          this.fotka = res.name + res.extension;  
          this.podaciEdit.picture = res.name + res.extension; 
          if(this.fotka) {
            this.imaSlike = true;
             // prikaz slike za slide
            this.slike.push(this.adresaImage + '/imagesUpload/' + this.fotka);
          } else { 
            this.imaSlike = false
          }
      });  
    }
  }

  popup(){   
    this.dodajPopup = true; 
    this.fotka = null;
    this.prikazLokacije(); 
  }

  addLocation(e){
    // this.loadingVisible = true;
    if(e.location.lat === 0){
      e.location.lat = this.latitude;
    }
    if(e.location.lon === 0) {
      e.location.lon = this.longitude;
    } 
    /// ako je prošlo
    // this.dodajPopup = false;   
    var result = this.newLocation.instance.validate();  
    this.zadanioObjektLength = Object.entries(this.zadaniObjekt).filter(([k,v],i)=>!!v)

// ----------------------- HRVATSKI JEZIK --------------------------------------- //
     if(this.zadanioObjektLength.length > 0){
        // this.varijablaZadani = { name: this.zadaniObjekt['name']  } 
        this.varijablaZadani = this.zadaniObjekt['name']  
      } else {
          this.varijablaZadani = {};
      }  

  //////// slanje svega ////////////
    this.podaci = {
      accommodationId: this.id,
      location: this.podaci.location,
      // QRCode: '',
      picture: this.fotka,
      name: this.varijablaZadani,
      geoKoord: this.geoKoord
    }

    if(this.varijablaZadani.length === undefined || this.podaci.picture === undefined || this.podaci.picture === null ){
      notify('Name and image are required!', 'error');
     } else {
      this.slanjeNaAPI();    
    }
  }

  updateGeoTocke($event){
    this.geoKoord = $event.value;
    this.podaci.geoKoord = $event.value;
  }

  slanjeNaAPI(){
    this.loadingVisible = true;
    this.audioGuideService.dodajLocation(this.podaci).subscribe(res =>{
        this.loadingVisible = false;
            if(res.success === true){
              // this.generirajQR(res.doda)
              this.locationID = res.dodanilocation.id
              this.generirajQR(res.dodanilocation.id) 
              this.generirajOfflineQR(res.dodanilocation.id)
              // this.spremiEdit();
              // this.ocistiFormu();
            } else {
              notify('Error. Try again!', 'error');
            }
    })    
  }

  generirajQR(id){
    let idZaAPI: any; 
    if(this.urediPopup){
      idZaAPI = this.locationID    
    } else {
      idZaAPI = id;
    }
    this.audioGuideService.generirajQR({'url': this.putanjazaQR, 'kod': idZaAPI}).subscribe(res => {
      this.qrCode = res.kod;
      if(res.kod){
        this.imaQR = true;
          if(this.dodajPopup){
            this.dodajSaQR();
          } else {
            notify("Online QR code is generated! Don't forget to save changes!");
          }       
      } else {
        this.imaQR = false;
      } 
    }) 
  }

  generirajOfflineQR(id){    
    let offlineIDZaAPI: any; 
    if(this.urediPopup){
      offlineIDZaAPI = this.locationID;
    } else {
      offlineIDZaAPI = id; 
    }       
    // let url = 'https://guideforyou.info/deeplink?param=';
    let url = 'https://app.guideforyou.eu:7001/deeplink?param=';
    this.audioGuideService.generirajQR({'url': url, 'kod': offlineIDZaAPI}).subscribe(res => {
      // console.log(res); 
      this.offlineQrCode = res.kod; 
      if(res.kod){
        this.imaOfflineQR = true;
          if(this.dodajPopup){
            this.dodajSaQR();
          } else {
            notify("Offline QR code is generated! Don't forget to save changes!");
          }       
      } else {
        this.imaOfflineQR = false;
      } 
    })     
  }

 ocistiFormu(){
    notify('Success!', 'success');
    if(this.newLocation){
      this.newLocation.instance.resetValues();
    } 
    this.zadaniObjekt = {}
    this.dodajPopup = false;
    this.imaSlike = false;
    this.imaQR = false;
    this.imaOfflineQR = false;
    this.sveLokoacije();
  }

  /**-------------------------------------- Uređivanje lokacije ---------------------------------------------- */
  editLocation(e){
    this.slike = [];
    this.urediPopup = true;
    this.audioGuideService.dohvatijedan({id: e.key.id}).subscribe(res => {
      // this.urediPopup = true;
      this.locationID = res['id'];

      this.dohvatiMoguceJezike(this.defaultJezik, 'Location', res['id']);
      this.qrCode = res['QRCode'];
      this.offlineQrCode = res['OfflineQRCode'];
      this.geoKoord = res['geoKoord'];
      if(res['picture']){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }
      if(res['OfflineQRCode']){
        this.imaOfflineQR = true;
      } else {
        this.imaOfflineQR = false;
      }           
      if(res['QRCode']){
        this.imaQR = true;
      } else {
        this.imaQR = false;
      }    
      // this.urediPopup = true; 
      this.podaciEdit = {
          id: res['id'],
          accommodationId: res['accommodationId'],
          name: res['name'],
          picture: res['picture'],
          location: res['location'],
          geoKoord: res['geoKoord']
      }
      this.fotka = res['picture']
      this.latitude = +this.podaciEdit.location.lat;
      this.longitude = +this.podaciEdit.location.lon;
      // prikaz slike za slide
      this.slike.push(this.adresaImage + '/imagesUpload/' + res['picture'])  
      this.prikazLokacijeZaEdit();
    })
  }

   //***************************** edit lokacije na mapi *********************************** */
  prikazLokacijeZaEdit(){      
    if(!this.odabraniDrugiJezik){
      this.mapsAPILoader.load().then(() => {
        this.setLocationFromData();
        this.geoCoder = new google.maps.Geocoder;      
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {       
          types: ["geocode"]        
        });
        autocomplete.addListener("place_changed", () => {     
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            //verify result  
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
            this.podaciEdit.location.lat = +this.latitude;
            this.podaciEdit.location.lon = +this.longitude;
          });     
        });   
      });
    }
  }

  editAdressOnMap($event: MouseEvent){
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddressEdit(this.latitude, this.longitude);
    this.podaciEdit.location.lat = this.latitude;
    this.podaciEdit.location.lon = this.longitude;
  }

  markerDragEndEdit($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddressEdit(this.latitude, this.longitude);
    this.podaciEdit.location.lat = this.latitude;
    this.podaciEdit.location.lon = this.longitude;    
  }

    // Get Current Location Coordinates
    setLocationFromData() {     
      if ('geolocation' in navigator) {
        this.latitude = +this.podaciEdit.location.lat;
          this.longitude = +this.podaciEdit.location.lon;
          this.zoom = 18; //15;
        // navigator.geolocation.getCurrentPosition((position) => {
        //   this.latitude = position.coords.latitude;
        //   this.longitude = position.coords.longitude;
        //   this.zoom = 15;
        //   // this.getAddressEdit(this.latitude, this.longitude);
        //   console.log(this.latitude, this.longitude);
        // });
      }
    }    
  
   getAddressEdit(latitude, longitude) {    
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  dodajSaQR(){
    this.audioGuideService.urediLokaciju({
      id: this.locationID, 
      accommodationId: this.id,
      location: this.podaci.location,
      QRCode: this.qrCode,
      OfflineQRCode: this.offlineQrCode,
      picture: this.fotka,
      name: this.varijablaZadani}).subscribe(res => {       
      this.ocistiFormu();
    })
  }

  saveEdit(e){
    // this.loadingVisible = true;
    if(this.odabraniDrugiJezik){
      if(e.name === '' || this.fotka === undefined || this.fotka === null){
        // this.loadingVisible = false;
        notify('Name and Image are required!', 'error');
      } else {  
      this.audioGuideService.urediLokaciju({
        'id': this.locationID, 
        'location': this.podaciEdit.location, /** */
        'QRCode': this.qrCode,
        'OfflineQRCode': this.offlineQrCode,
        'picture': this.fotka,
        'name': e.name,
        'jezik': this.jezikTeksta,
        'geoKoord': this.geoKoord
      }).subscribe(res => {
          if(res.success == true){
            this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
            // this.loadingVisible = false;
            // this.urediPopup = false;
            // this.dodajPopup = false;
            this.sveLokoacije();
          } else {
            // notify(res.message, 'success');
          }
        })
      }
    } else {
      if(e.name === '' || this.fotka === undefined || this.fotka === null){
        // this.loadingVisible = false;
        notify('Name and Image are required!', 'error');
      } else {  
      this.audioGuideService.urediLokaciju({
        'id': e.id, 
        'location': this.podaciEdit.location,
        'QRCode': this.qrCode,
        'OfflineQRCode': this.offlineQrCode,
        'picture': this.fotka,
        'name': e.name,
        'jezik': null,
        'geoKoord': this.geoKoord /** */
      }).subscribe(res => {
          if(res.success == true){
            // this.loadingVisible = false;
            this.urediPopup = false;     
            this.dodajPopup = false;
            this.sveLokoacije();    
          } else {
            // notify(res.message, 'success');
          }
        })
      }
    }
  }

  editZadani(){
    this.odabraniDrugiJezik = false;
    this.jezikTeksta = this.defaultJezik;
    this.caption = "selected language: " + this.defaultJezik;
    this.audioGuideService.dohvatijedan({id: this.locationID}).subscribe(res => { 
      // this.urediPopup = true;
      this.qrCode = res['QRCode'];
      this.offlineQrCode = res['OfflineQRCode'];
      this.geoKoord = res['geoKoord'];
      if(res['picture']){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }
      if(res['OfflineQRCode']){
        this.imaOfflineQR = true;
      } else {
        this.imaOfflineQR = false;
      }           
      if(res['QRCode']){
        this.imaQR = true;
      } else {
        this.imaQR = false;
      }     
      // this.urediPopup = true; 
      this.podaciEdit = {
        id: res['id'],
        accommodationId: res['accommodationId'],
        name: res['name'],
        picture: res['picture'],
        location: res['location'],
        geoKoord: res['geoKoord']
      }
      this.fotka = res['picture']
      this.latitude = this.podaciEdit.location.lat;
      this.longitude = this.podaciEdit.location.lon;
      // prikaz slike za slide
      this.slike.push(this.adresaImage + '/imagesUpload/' + res['picture']);
      this.prikazLokacijeZaEdit();
    })
  }

  jezici(e){
    this.jezikPopup = true;  
  }

  odabranJezik(e, item){
    this.audioGuideService.changeLanguage({locationId : this.locationID, language: item}).subscribe(res =>{
      // this.dataGrid.instance.getDataSource().reload();          
      this.dataGrid.instance.refresh();
      this.sveLokoacije();
        if(res.success === true){
            notify('Success!', 'success');
          } else {
            e.target.checked = false;
            notify(res.message, 'error');
          }
      }) 
  }

  audioPoints(e){
    this._router.navigate(['/audio-points/' + e.data.id + '/' + e.data.defaultJezik + '/' + this.id]);
  }

  back(){
    this.dodajPopup = false;
    this.urediPopup = false;
    this.editZadani();
    // this.newLocation.instance.resetValues();
    // this.zadaniObjekt = {}
    // this.dodajPopup = false;
    // this.imaSlike = false;
    // this.imaQR = false;
    // this.imaOfflineQR = false;
  }

  otvoriGaleriju(){
    this.prikaziGaleriju = true;
  }

  sveLokoacije(){
    this.audioGuideService.allLocations({'id': this.id}).subscribe(res => {
      this.lokacije = res.data;
    })
  }

  //PRIJEVOD
  dohvatiMoguceJezike(defaultJezik, vrsta, locationId){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.id, 'jezik': defaultJezik, 'ekran': vrsta, 'locationId': locationId}).subscribe(res=> {
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data){
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.jezikPrijevoda = $event.selectedItem.name;
    this.jezikTeksta = $event.selectedItem.text;
    this.odabraniDrugiJezik = true;
    this.caption = "selected language: " + $event.selectedItem.text;
    this.audioGuideService.getLanguage({
        locationId: this.locationID,
        language: $event.selectedItem.text
    }).subscribe(res=> {
      if (res.success == false) {
        notify(res.data, 'error');
        this.podaciEdit = {
          name: null
      }
      } else {
        this.podaciEdit = {
            name: res.data['name']
        }
      }
    })
  }

}

function newFunction(): string {
	return 'multipart/form-data';
}