import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class AnaliticsService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }
    
    dohvatiAnalitiku(a: any){
        return this._http.put(AppConfig.ipAddress + 'analytics/dohvati', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiBrojacLokacija(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/dohvatiBrojacLokacija', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiBrojacTocaka(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/dohvatiBrojacTocaka', a)
        .pipe(map((res: any) => res      
        ));
    }

}