import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class AudioGuideService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    allLocations(a: any){
        return this._http.get(AppConfig.ipAddress + 'location/dohvatiSveAccommodation/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    dodajLocation(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/', a)
        .pipe(map((res: any) => res ));
    }

    dohvatijedan(a: any){
        return this._http.get(AppConfig.ipAddress + 'location/dohvati/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    urediLokaciju(a: any){
        return this._http.put(AppConfig.ipAddress + 'location/uredi/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    brisanje(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }

    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

    generirajQR(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/QRCodeLocation', a)
        .pipe(map((res: any) => res));
    }
    
    changeLanguage(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/changeLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

    getLanguage(a: any){
        return this._http.post(AppConfig.ipAddress + 'location/getLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

    napraviPDFTocaka(a: any) {
        return this._http.get(AppConfig.ipAddress + 'location/napraviPDFTocaka?locationId=' + a.locationId, {responseType: 'blob'})
        .pipe(map((res: any) => res));
    }

}