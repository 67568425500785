import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

    constructor(private _http: HttpClient) { 
        // console.log(AppConfig.ipAddress)
    }

    get prikaz() {
        return true;
    }

    listnotification(a: any){
        return this._http.post(AppConfig.ipAddress + 'listnotification/', a)
        .pipe(map((res: any) => res));
    }

    scanKeyCheck(a: any){
        return this._http.get(AppConfig.ipAddress + 'accommodation/scanKeyCheck?key=' + a.key, a)
        .pipe(map((res: any) => res));
    }


}
