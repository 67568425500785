import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class editInteresService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    getOneInterest(a: any){
        return this._http.get(AppConfig.ipAddress + 'interest/dohvati/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

    updateInterest(a: any){
        return this._http.put(AppConfig.ipAddress + 'interest/urediInterest', a)
        .pipe(map((res: any) => res      
        ));
    }

    // uploadSlike(a: any){
    //     return this._http.post(AppConfig.ipAddress + 'upload/uploadSliku', a)
    //     .pipe(map((res: any) => res      
    //     ));
    // }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }
    
    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

    uploadPDF(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadPDF', a)
        .pipe(map((res: any) => res      
        ));
    }
    
    deletePDF(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiPDF', a)
        .pipe(map((res: any) => res      
        ));
    }

    getInterestLangugage(a: any){
        return this._http.put(AppConfig.ipAddress + 'languageInterest/getInterestLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

    editInterestLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'languageInterest/editInterestLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiGradoveFilter(a: any){
        return this._http.put(AppConfig.ipAddress + 'fetch/cityFilter', a)
        .pipe(map((res: any) => res));
    }

    dodajLink(a: any){
        return this._http.post(AppConfig.ipAddress + 'interest/dodajLink', a)
        .pipe(map((res: any) => res      
        ));
    }

    dohvatiLinkove(a: any){
        return this._http.post(AppConfig.ipAddress + 'interest/dohvatiLinkove', a)
        .pipe(map((res: any) => res      
        ));
    }

    urediLink(a: any){
        return this._http.post(AppConfig.ipAddress + 'interest/urediLink', a)
        .pipe(map((res: any) => res      
        ));
    }

    obrisiLink(a: any){
        return this._http.post(AppConfig.ipAddress + 'interest/obrisiLink', a)
        .pipe(map((res: any) => res      
        ));
    }

}