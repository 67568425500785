import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class AudioPointsService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    brisanje(a: any){
        return this._http.delete(AppConfig.ipAddress + 'locationPoint/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    locationPoint(a: any){
        return this._http.post(AppConfig.ipAddress + 'locationPoint', a)
        .pipe(map((res: any) => res ));
    }

    dohvatiSve(a: any){
        return this._http.get(AppConfig.ipAddress + 'locationPoint/dohvatiSve', a)
        .pipe(map((res: any) => res ));
    }

    dohvatiSvelocation(a: any){
        return this._http.get(AppConfig.ipAddress + 'locationPoint/dohvatiSvelocation/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    dohvatijedan(a: any){
        return this._http.get(AppConfig.ipAddress + 'locationPoint/dohvati/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    locationPointgetLanguage(a: any){
        return this._http.post(AppConfig.ipAddress + 'locationPoint/getLanguage', a)
        .pipe(map((res: any) => res ));
    }

    uredi(a: any){
        return this._http.put(AppConfig.ipAddress + 'locationPoint/uredi/' + a.id, a)
        .pipe(map((res: any) => res ));
    }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }

    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

    uploadAudio(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadAudio', a)
        .pipe(map((res: any) => res      
        ));
    }

    QRCodeAudioPoint(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/QRCodeAudioPoint', a)
        .pipe(map((res: any) => res      
        ));
    }

}