import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffersService } from '../offers.service';
import { AppConfig } from '../../../config';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';
import { BasicInfoService } from '../../basic-info/basic-info.service';

@Component({
  selector: 'app-one-offer',
  templateUrl: './one-offer.component.html',
  styleUrls: ['./one-offer.component.scss'],
  providers: [BasicInfoService]
})
export class OneOfferComponent implements OnInit {
  @ViewChild("edit", { static: false }) edit: DxFormComponent;  
  @ViewChild("jezikForma", { static: false }) jezikForma: DxFormComponent;  
  token: any;
  id: any;
  objectId: any;
  objekt: any;
  objectName: any;
  offer: any;
  offerName: any;
  adresaImage = AppConfig.adresaImage;
  pictures = [];
  slike = [];
  imaSlike = false;
  openPopup = false;
  limitUpload = false;
  value: any;
  height: any;
  prikaziGaleriju = false;
  detalji = {
    "access_token": '',
    'id': '',
    'idOffer': '',
    'name': '',
    'gallery': [],
    'description': '',
    'workHours': '',
    'contactPhone': '',
    'website': ''
  }
  popupPrijevod = false;
  textPrijevod = {};
  jezikTeksta: any;
  prijevodDescription: any;
  confirmDelete = false;
  prijevodTitle: any;
  loadingVisible = false;
  prijevodWeb: any;
  draggingIndex: number;
  popisJezikaEdit = [];
  defaultJezik: string;
  jezikPrijevoda: string;

  constructor(
    private _route: ActivatedRoute,
    private basicInfoService: BasicInfoService,
    public _router: Router,
    private offersService: OffersService
  ) {
    this.token = sessionStorage.getItem('token');
    this.id = this._route.snapshot.params['id'];
    this.objectId = this._route.snapshot.params['id2'];
    this.offersService.oneObject({"id": this.objectId}).subscribe(res => {
      this.objectName = res.name;
      this.defaultJezik = res.defaultJezik;
      this.dohvatiMoguceJezike(this.defaultJezik, 'OfferForYou', this.id);
    });
  }

  ngOnInit() {
    this.getOffer();
    this.slikePrikaz();
  }

    // Promijena redoslijeda slika
    private _reorderItem(fromIndex: number, toIndex: number): void {
      const itemToBeReordered = this.pictures.splice(fromIndex, 1)[0];
      this.pictures.splice(toIndex, 0, itemToBeReordered);
      this.draggingIndex = toIndex;
    }
  
    onDragStart(index: number): void {
      this.draggingIndex = index;
    }
  
    onDragEnter(index: number): void {
      if (this.draggingIndex !== index) {
        this._reorderItem(this.draggingIndex, index);
      }
    }
  
    onDragEnd(): void {
      this.draggingIndex = undefined;
    }
  

  onShown(e){   
    this.edit.instance.focus();
  }

  onShownJezik(e){
    this.jezikForma.instance.focus();
  }

  editOffer() {
    if (this.pictures.length == 5) {
      notify('Only 5 photos can be uploaded!');
      this.limitUpload = true;
    }
    this.openPopup = true;
  }

  odustani(){
    this.openPopup = false;
    this.offer = {};
  }

  getOffer() {
    this.offersService.getOffer({"id": this.objectId, "idOffer": this.id}).subscribe(res => {
      this.offer = res.podaci;
      this.offerName = res.podaci.name;
      this.pictures = res.podaci.gallery;
      if(res.podaci.gallery.length != 0){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }
      this.confirmDelete = false;
    });
  }

  saveOffer(e) {
    this.loadingVisible = true;
    this.detalji = {
      "access_token": this.token,
      'id': this.objectId,
      'idOffer': this.id,
      'name': e.name,
      'gallery': this.pictures,
      'description': e.description || null,
      'workHours': e.workHours || null,
      'contactPhone': e.contactPhone || null,
      'website': e.website || null
    }
    this.offersService.editOffer(this.detalji).subscribe(res => {
      this.loadingVisible = false;
      if (res.success == true) {
        this.getOffer();
        this.openPopup = false;
      }
    });
  }

  preDeleteOffer() {
    this.confirmDelete = true;
  }

  deleteOffer() {
    this.offersService.deleteOffer({"id": this.objectId, "idOffer": this.id}).subscribe(res => {
      if (res.success === true) {
        this._router.navigate(['/offers/' + this.objectId]);
      }
    })
  }

  slikePrikaz(){
    this.slike = [];
    this.offersService.getOffer({"id": this.objectId, "idOffer": this.id}).subscribe(res => {
      for (let slika of res.podaci.gallery) {
        this.slike.push(
          this.adresaImage + '/imagesUpload/' + slika
        )
      }
    })
  }

  otvoriGaleriju() {
    this.prikaziGaleriju = true;
  }

  dodajFotku(event){
    this.value =  event.target.files;
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      this.height = img.height;
      this.uploadDoc(event.target.value);
    }
  }

  ukloniFotku(slika, i) {
    this.offersService.deleteImage({'fileName': slika}).subscribe(res => {
      if(res.success == true) {
        this.pictures.splice(i, 1);
        this.uklanjanjeSlika();
      } else {}
      if(this.pictures.length == 5){
        notify('Only 5 photos can be uploaded!');
        this.limitUpload = true;
      } else {
        this.limitUpload  = false;
      }
    })
  }

  uklanjanjeSlika(){
  let podaci = {
    "access_token": this.token,
    'id': this.objectId,
    'idOffer': this.id,
    'gallery': this.pictures,
    'description': this.detalji.description,
    'workHours': this.detalji.workHours,
    'contactPhone': this.detalji.contactPhone,
    'website': this.detalji.website,
    'name': this.detalji.name
  }
    this.offersService.editOffer(podaci).subscribe(res=>{
      // console.log(res);   
    }) 
  } 


  uploadDoc(data){
    let fileList: FileList = this.value;
    if(fileList.length > 0){
      let file: File = fileList[0];         
      let formData:FormData = new FormData();
      formData.append('datoteka', file);
      formData.append('height', this.height);
      let headers = new Headers();
      headers.append('Content-Type', newFunction()); 

      this.offersService.upload(formData).subscribe(res => {
        this.pictures.push(res.name + res.extension);
        if(this.pictures.length != 0) {
          this.imaSlike = true;
        } else { 
          this.imaSlike = false
        }
        if(this.pictures.length == 5){
          notify('Only 5 photos can be uploaded!');
          this.limitUpload = true;
        } else {
          this.limitUpload  = false;
        }            
      });  
    }
  }

  //PRIJEVOD
  dohvatiMoguceJezike(defaultJezik, vrsta, offerId){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.objectId, 'jezik': defaultJezik, 'ekran': vrsta, 'offerId': offerId}).subscribe(res=> {
        // console.log(res);
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.jezikPrijevoda = $event.selectedItem.name;
    this.jezikTeksta = $event.selectedItem.text;
    this.popupPrijevod = true;
    this.offersService.getLanguage({
      accommodationId: this.objectId,
      idOffer: this.id,
      language: $event.selectedItem.text
    }).subscribe(res=> {
      // console.log(res);
      if (res.success == false) {
        notify(res.message, 'error');
        this.textPrijevod['description'] = null;
        this.textPrijevod['title'] = null;
        this.textPrijevod['web'] = null;
      } else {
        this.textPrijevod['description'] = res.data.description;
        this.textPrijevod['title'] = res.data.title;
        this.textPrijevod['web'] = res.data.web;
      }
    })
  }

  spremiPrijevod(e){
    this.popupPrijevod = false;
    this.prijevodDescription = e.description;
    this.prijevodTitle = e.title;
    this.prijevodWeb = e.web;

    let podaciSlanje = {
      'accommodationId': this.objectId, 
      'idOffer': this.id, 
      'description': this.prijevodDescription || null, 
      'language': this.jezikTeksta,
      'title' : this.prijevodTitle || null,
      'web': this.prijevodWeb || null
    };
    
    this.offersService.editLanguage(podaciSlanje).subscribe(res=>{
      if(res.success == true){
        this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
        notify('Your translation is saved.', 'success');
      }
    });
  }

  odustaniPrijevod(){
    this.popupPrijevod = false;
    this.textPrijevod = {};
  }
}

function newFunction(): string {
	return 'multipart/form-data';
}