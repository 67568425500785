import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import { AuthService, AppInfoService } from '../../services';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  login = '';
  password = '';
  poruka: string;
  loggedIn = false;
  passwordMode: string;

  constructor(
    public appInfo: AppInfoService,
    private authService: AuthService
  ){
    this.passwordMode = 'password';
  }

  otkrijLozinku(){
    this.passwordMode = this.passwordMode === "text" ? "password" : "text";
  }
  
  onLoginClick(args, login, password){
    let user = {'email': login, 'password': password}
    if (!args.validationGroup.validate().isValid) {
        return;
    }      
    this.authService.login(user).subscribe(res => {
        if(res.success === true){
          localStorage.setItem('role', res['user'].role);
          localStorage.setItem('myID', res['user'].id);
          localStorage.setItem('fullName', res['user'].fullName);
          sessionStorage.setItem('token', res['token']);
          this.authService.logIn(login, password);
        } else if (res.success ===  false){
          this.poruka = 'Login failed: Invalid username or password.';
        }
    })
    // args.validationGroup.reset();
  }

  /** logiranje na enter key */
  onEnterKey(args, login, password){ 
    let user = {'email': login, 'password': password}
    this.authService.login(user).subscribe(res => {
      if(res.success === true){
        localStorage.setItem('role', res['user'].role);
        localStorage.setItem('myID', res['user'].id);
        localStorage.setItem('fullName', res['user'].fullName);
        sessionStorage.setItem('token', res['token']);
        this.authService.logIn(login, password);
        } else if (res.success ===  false){
          this.poruka = 'Login failed: Invalid username or password.'; 
        }
    })
  }

  onInitialized(e){
    setTimeout(() => e.component.focus())
  }

}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }