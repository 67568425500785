import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class YouMayNeedService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    usluge(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiMozdaZatrebate', a)
        .pipe(map((res: any) => res      
        ));
    }

    editWakeUpCall(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediPozivZaBudenje', a)
        .pipe(map((res: any) => res      
        ));
    }
    editCarWash(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediPranjeAuta', a)
        .pipe(map((res: any) => res      
        ));
    }

    editRentACar(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediRentACar', a)
        .pipe(map((res: any) => res      
        ));
    }
    
    editEmergency(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediHitno', a)
        .pipe(map((res: any) => res      
        ));
    }
    editTransport(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediTransport', a)
        .pipe(map((res: any) => res      
        ));
    }
    oneObject(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
        .pipe(map((res: any) => res      
        ));
    }
    getLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/getLanguage', a)
        .pipe(map((res: any) => res));
    }

    editLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/editLanguage', a)
        .pipe(map((res: any) => res));
    }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }
    
    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

}