import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { DxFormComponent } from 'devextreme-angular';
import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

import { MapsAPILoader, MouseEvent} from '@agm/core';

import { AppConfig } from './../../config';
import { AudioPointsService } from './audio-points.service';
import { BasicInfoService } from '../basic-info/basic-info.service';

@Component({
  selector: 'app-audio-points',
  templateUrl: './audio-points.component.html',
  styleUrls: ['./audio-points.component.scss'],
  providers: [AudioPointsService, BasicInfoService]
})
export class AudioPointsComponent implements OnInit {  

  // @ViewChild(DxFormComponent, {static: false}) editAudioPoint: DxFormComponent;
  // @ViewChild(DxFormComponent, {static: false}) newAudioPoint: DxFormComponent;
  @ViewChild("tab1", { static: false }) tab1: DxFormComponent;
  adresaImage = AppConfig.adresaImage;
  tocke: any;
  tab: number = 0;
  sviJezici = [];  
  id: any;
  prikaziGaleriju = false;
  zadaniObjekt = {};
  zadaniObjektLength: any;
  varijablaZadani: any;
  dodajTocku = false;
  podaci = {
    'name': '',
    'description': '',
    'locationId': '',
    'audiofile': '',
    // 'position': '',
    'picture': '',
    // 'audio': '',
    'location': {}
  };
  imaAudioFile = false;
  valueAudio: any;
  imaSlike = false;
  fotka: any;
  value: any;
  height: any;
  slike = [];
  audioSource: any;
  podaciEdit: any = {};
  urediTocku = false;
  audio: any;
  audioprikaz: any;
  jezikPrikaz: any;
  audioDatoteka: any;
  loadingVisible = false;
  qrCode: any;

  popisJezikaEdit = [];
  defaultJezik: string;
  jezikPrijevoda: string;
  jezikTeksta: string;
  odabraniDrugiJezik: boolean = false;
  caption: string = "selected language";
  odabranaTocka: string;

  private geoCoder;
  latitude: number;
  longitude: number;
  zoom: number = 18;
  address: string;
  @ViewChild('pretraga', {static: false}) public searchElementRef: ElementRef;
  @ViewChild('map', {static: false}) public mapElementRef: ElementRef;

  koordinate: any;

  constructor(
    private audioPointsService: AudioPointsService,
    private basicInfoService: BasicInfoService,
    private mapsAPILoader: MapsAPILoader,
    private _route: ActivatedRoute,
    private _location: Location,
    private ngZone: NgZone
  ) {
    this.id = this._route.snapshot.params['id'];
    this.defaultJezik = this._route.snapshot.params['lng'];
    this.jezikTeksta = this._route.snapshot.params['lng'];
    this.caption = "selected language: " + this._route.snapshot.params['lng'];
  }

  ngOnInit() { 
    this.dohvatiSveTocke(this.id);
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'naslovTablice',    
      },
      { 
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: this.popup.bind(this)
        }
      },
      { 
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Back',
          type: "default",
          stylingMode: "contained",
          onClick: this.back.bind(this)
        }  
      }
    )
  }

  back(){
    this._location.back(); 
  }

  backToList(){
    // this.dodajTocku = false;
    // this.zadaniObjekt = {}
    this.editZadani();
    this.tab = 0;
    this.urediTocku = false;
    // this.imaSlike = false;
    // this.imaAudioFile = false;
    // sessionStorage.removeItem('audioDatoteka');
  }

  popup(){
    this.dodajTocku = true; 
    this.tab = 0; 
    setTimeout(() => {  
      this.tab1.instance.getEditor('name').focus(); 
    }, 100);
    sessionStorage.removeItem('audioDatoteka');
    this.audioDatoteka = null;
    this.fotka = null;
    this.imaSlike = false;
    this.imaAudioFile = false;
    this.prikazLokacije(); 
  }

  otvoriGaleriju(){
    this.prikaziGaleriju = true;
  }

  editPoint(e){
    this.slike = [];
    this.tab = 0;
    setTimeout(() => {  
      this.tab1.instance.getEditor('name').focus(); 
      }, 100)
    this.urediTocku = true;
    
    this.odabranaTocka = e.data.id;
    this.audioPointsService.dohvatijedan({id: e.data.id}).subscribe(res => {
      // console.log(res['location']);
      this.dohvatiMoguceJezike(this._route.snapshot.params['accId'], this.defaultJezik, 'LocationPoint', this.odabranaTocka);
      this.fotka = res['picture']
      this.qrCode = res['QRCode'];
      if(res['picture']){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }      

      if(res['audiofile']){       
        this.jezikPrikaz = this.jezikTeksta;
        this.imaAudioFile = true;
        this.audioDatoteka = res['audiofile']; 
        sessionStorage.setItem("audioDatoteka", this.audioDatoteka);       
      }

      this.podaciEdit = {
        locationId: res['locationId'],
        name: res['name'],
        description: res['description'],
        audiofile: res['audiofile'],
        picture: res['picture'],
        location: res['location']
      }    
      this.slike.push(this.adresaImage + '/imagesUpload/' + res['picture']);
      this.latitude = res['location'].lat;
      this.longitude = res['location'].lon;
    })
    this.prikazLokacijeZaEdit();
  }

/** --------------------- AUDIO  ---------------------- */
  dodajAudio(e){
    this.valueAudio =  e.target.files;
    // let img = new Image();
    this.audioprikaz = window.URL.createObjectURL(e.target.files[0]);
    this.uploadAudio(e.target.value);   
  }

  uploadAudio(data){
    let fileList: FileList = this.valueAudio;
      if(fileList.length > 0){
        let file: File = fileList[0];                
        let formData:FormData = new FormData();
          formData.append('audio', file);
          formData.append('language', this.jezikTeksta);
        let headers = new Headers();
          headers.append('Content-Type', newFunction());            
        this.audioPointsService.uploadAudio(formData).subscribe(res => {
          this.audio = res.name
          this.jezikPrikaz = res.language;            
          if(this.audio) {            
              // if(this.jezikPrikaz === 'hr'){
                this.audioDatoteka = res.name + res.extension
                sessionStorage.setItem("audioDatoteka", this.audioDatoteka);      
                this.imaAudioFile = true;      
              // }
          }
        });  
      }
  }

  ukloniAudio(audio, i){    
    this.audioPointsService.deleteImage({'fileName': audio}).subscribe(res => {   
      if(res.success == true){  
        this.audioDatoteka = null;
        this.imaAudioFile = false;
        sessionStorage.removeItem('audioDatoteka');

      //// samo kod uređivanja: 
        if(this.urediTocku){
            this.audioDatoteka = null;
            this.imaAudioFile = false;
            // this.podaciEdit['audiofile'] = null;
            // delete this.podaciEdit['audiofile']
            sessionStorage.removeItem('audioDatoteka');
            this.uklanjanjeAudio();  
        } 
      }}
    )
  }
  
  uklanjanjeAudio(){
      this.audioDatoteka = null;
      this.imaAudioFile = false;
      // this.podaciEdit['audiofile'] = null;
      delete this.podaciEdit['audiofile']
      sessionStorage.removeItem('audioDatoteka');
      this.audioPointsService.uredi({
          id: this.odabranaTocka,
          picture: this.fotka,
          name: this.podaciEdit.name,
          description: this.podaciEdit.description,
          qrCode: this.qrCode,
          jezik: this.odabraniDrugiJezik == true ? this.jezikTeksta : null
      }).subscribe(res => {
        //console.log(res)
      }) 
  }

  /** --------------------- SLIKA ---------------------- */
  /** slika */
  dodajFotku(event){
    this.value =  event.target.files;
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {    
      this.height = img.height;
      this.uploadDoc(event.target.value);
    }
  }

  ukloniFotku(slika){
    this.audioPointsService.deleteImage({'fileName': slika}).subscribe(res => {  
      if(res.success == true){      
      this.fotka = null;
      this.podaciEdit.picture = null;
      this.imaSlike = false;
        if(this.urediTocku){
          this.uklanjanjeSlika(); // ovo samo kod edita
        } 
      }}
    )
  }

  uklanjanjeSlika(){   
    this.audioPointsService.uredi({
        id: this.odabranaTocka, 
        picture: this.fotka,
        name: this.podaciEdit.name,
        description: this.podaciEdit.description,
        qrCode: this.qrCode,
        jezik: this.odabraniDrugiJezik == true ? this.jezikTeksta : null,
    }).subscribe(res => { 
      // console.log(res)
    })  
  }

  uploadDoc(data){
    let fileList: FileList = this.value;
    if(fileList.length > 0){
      let file: File = fileList[0];         
      let formData:FormData = new FormData();
          formData.append('datoteka', file);
          formData.append('height', this.height);
      let headers = new Headers();
          headers.append('Content-Type', newFunction());            
      this.audioPointsService.upload(formData).subscribe(res => {
          this.fotka = res.name + res.extension;     
          this.podaciEdit.picture = res.name + res.extension;   
          if(this.fotka) {
            this.imaSlike = true;
            // prikaz slike za slide
            this.slike.push(this.adresaImage + '/imagesUpload/' + this.fotka);
          } else { 
            this.imaSlike = false;
          }
      });  
    }
  }

  /**------------------------------------- nova točka ----------------------------------------------------- */
  addAudioPoint(e){
    this.zadaniObjektLength = Object.entries(this.zadaniObjekt).filter(([k,v],i)=>!!v)   
   
   // ----------------------- HRVATSKI JEZIK --------------------------------------- //
        if(this.zadaniObjektLength.length > 1){ 
          this.varijablaZadani = { name: this.zadaniObjekt['name'], description: this.zadaniObjekt['description'], audiofile: this.audioDatoteka } 
         } else {
             this.varijablaZadani = {};
         }  
 
          
     //////// slanje svega ////////////
    let hr = this.varijablaZadani;
    this.podaci = {
    locationId: this.id,
      picture: this.fotka,
      name: this.varijablaZadani['name'],
      description: this.varijablaZadani['description'],
      audiofile: this.varijablaZadani['audiofile'],
      location: this.podaci.location
    }    
 
  if(this.varijablaZadani['name'] === undefined || this.varijablaZadani['description'] === undefined || this.audioDatoteka === null || this.audioDatoteka === undefined || this.fotka === undefined || this.fotka === null){
      notify('Name, Description, Audio file and Image are required!', 'error');
    } else {
      this.slanjeNaAPI();    
    }
  }
   
  dodatnoPitanje(){
    let encodedMessage = encodeHtml("You don't have all translations. Save it anyway or add another translation");
    let myDialog = custom({
           title: "Warning",
           messageHtml: encodedMessage,
           buttons: [
              {
                text: "Save",
                onClick: (e) => {          
                    return { buttonText: e.component.option("text") }
                }
              },
             {
               text: "Cancel",
               onClick: (e) => {
                 return { buttonText: e.component.option("text") }
               }
            }]
    });  
    myDialog.show().then((dialogResult) => {
            if(dialogResult.buttonText == 'Save'){
              this.slanjeNaAPI();
            }
    });
  }

     /**-------------------------------- uređivanje točke ----------------------------------------------- */
  editAudioPointSave(e){
    this.loadingVisible = true;
    if(this.audioDatoteka){
      e.audiofile = this.audioDatoteka;
    }     
    if(e.name === '' || e.description === '' || e.audioFile || this.fotka === null || this.fotka === undefined || this.audioDatoteka === undefined || this.audioDatoteka === null){
        this.loadingVisible = false;
        notify('Name, Description and Audio file are required!', 'error');
    } else {
      let podaciZaSlanje = {
        id: this.odabranaTocka,
        picture: this.fotka,
        name: e.name,
        description: e.description,
        audiofile: e.audiofile,
        jezik: this.odabraniDrugiJezik == true ? this.jezikTeksta : null,
        location: this.podaciEdit.location
    };

    console.log(podaciZaSlanje);

      this.audioPointsService.uredi(podaciZaSlanje).subscribe(res => {
          this.loadingVisible = false;
          notify('Success', 'success');        
          // this.urediTocku = false;     
          // this.dodajTocku = false;
          this.dohvatiSveTocke(this.id);             
          // this.zadaniObjekt = null;
          // this.podaciEdit = null;
          // this.jezikTeksta = null;
          // this.odabraniDrugiJezik = false;
          // this.caption = "selected language: " + this.odabraniDrugiJezik ? null : this.defaultJezik;
      })
    }    
  }
   
  slanjeNaAPI(){
    this.loadingVisible = true;   
    this.audioPointsService.locationPoint(this.podaci).subscribe(res =>{     
        this.loadingVisible = false;
        if(res.success === true){
          this.dodajTocku = false;
          this.dohvatiSveTocke(this.id);
          sessionStorage.removeItem('audioDatoteka');
          this.zadaniObjekt = {};
        } else {
            notify('Error. Try again!', 'error');
        }
    })    
  }

  dohvatiSveTocke(id){
    this.audioPointsService.dohvatiSvelocation({id: id}).subscribe(res => {
        this.tocke = res.data;
    })
  }

  deletePoint(e){
    this.audioPointsService.brisanje({id: e.data.id}).subscribe(res => {
        if(res.success === true){
          notify('Audio point successfully deleted', 'success');
          this.dohvatiSveTocke(this.id);
        } else {
          notify('An error has occurred. Try again', 'error');
          this.dohvatiSveTocke(this.id);
        }
    })    
  }

  //PRIJEVOD
  dohvatiMoguceJezike(accomodationID, defaultJezik, vrsta, pointId){
    this.basicInfoService.dohvatiJezikePrijevod({'id': accomodationID, 'jezik': defaultJezik, 'ekran': vrsta, 'pointId': pointId}).subscribe(res=> {
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.jezikPrijevoda = $event.selectedItem.name;
    this.jezikTeksta = $event.selectedItem.text;
    this.odabraniDrugiJezik = true;
    this.caption = "selected language: " + $event.selectedItem.text;
    this.imaAudioFile = false;
    this.audioDatoteka = null;
    this.audioPointsService.locationPointgetLanguage({
        pointId: this.odabranaTocka,
        language: $event.selectedItem.text
    }).subscribe(res=> {
      if (res.success == false) {
        notify(res.data, 'error');
        this.imaAudioFile = false;
        this.audioDatoteka = null;
        sessionStorage.removeItem('audioDatoteka');
      } else {
        if(res.data['audiofile']){
          this.imaAudioFile = true;
          this.audioDatoteka = res.data['audiofile'];
          sessionStorage.setItem("audioDatoteka", this.audioDatoteka);
        }
        this.podaciEdit = {
            name: res.data['name'],
            description: res.data['description'],
            audiofile: res.data['audiofile']
        }
      }
    })
  }

  editZadani(){
    this.odabraniDrugiJezik = false;
    this.imaAudioFile = false;
    this.audioDatoteka = null;
    // this.jezikTeksta = this.defaultJezik;
    this.caption = "selected language: " + this.defaultJezik;
    this.slike = [];
    this.tab = 0;
    setTimeout(() => {  
      this.tab1.instance.getEditor('name').focus(); 
    }, 100)
    this.urediTocku = true;
    
    this.audioPointsService.dohvatijedan({id: this.odabranaTocka}).subscribe(res => { 
      this.fotka = res['picture']
      this.qrCode = res['QRCode'];
      if(res['picture']){
        this.imaSlike = true;
      } else {
        this.imaSlike = false;
      }      

      if(res['audiofile']){       
        // this.jezikPrikaz = thi;        
        this.imaAudioFile = true;
        this.audioDatoteka = res['audiofile']; 
        sessionStorage.setItem("audioDatoteka", this.audioDatoteka);       
      }

      this.podaciEdit = {
        locationId: res['locationId'],
        name: res['name'],
        description: res['description'],
        audiofile: res['audiofile'],
        picture: res['picture'],
        location: res['location']  
      }     
      this.slike.push(this.adresaImage + '/imagesUpload/' + res['picture']);
      this.podaciEdit.location.lat = +this.latitude;
      this.podaciEdit.location.lon = +this.longitude; 
    })
  }

  //MAP

   markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.podaci.location = {lat: this.latitude, lon: this.longitude};
  }

  markerDragEndEdit($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddressEdit(this.latitude, this.longitude);
    this.podaciEdit.location.lat = +this.latitude;
    this.podaciEdit.location.lon = +this.longitude;    
  }

  getAddress(latitude, longitude) {   
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18; // 15;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getAddressEdit(latitude, longitude){
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18; // 15;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 18; //15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  } 

  prikazLokacije(){
    setTimeout(() => {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {       
          types: ["geocode"]  
        });
        autocomplete.addListener("place_changed", () => {     
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.koordinate = place;
            //verify result  
            if (place.geometry === undefined || place.geometry === null) {
              this.latitude = this.koordinate.name.toString().split(',')[0];
              this.longitude = this.koordinate.name.toString().split(',')[1];
              this.zoom = 18; // 15;
              this.podaci.location = {lat: Number(this.latitude), lon: Number(this.longitude)};
              this.setLocationFromData();
             /*  return; */
            } 
            // set latitude, longitude and zoom
            if(place.adr_address){
              this.latitude = place.geometry.location.lat();
              this.longitude = place.geometry.location.lng();
              this.zoom = 18; // 15;
              this.podaci.location = {lat: +this.latitude, lon: +this.longitude};
            }
          });     
        });
      });
    }, 10);
  }

  prikazLokacijeZaEdit(){
    if(!this.odabraniDrugiJezik){
      setTimeout(() => {
        this.mapsAPILoader.load().then((res) => {
          this.setLocationFromData();
          this.geoCoder = new google.maps.Geocoder;      
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {       
            types: ["geocode"]        
          });
          autocomplete.addListener("place_changed", () => {     
            this.ngZone.run(() => {
              //get the place result
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              this.koordinate = place;
              //verify result  
              if (place.geometry === undefined || place.geometry === null) {
                this.latitude = this.koordinate.name.toString().split(',')[0];
                this.longitude = this.koordinate.name.toString().split(',')[1];
                this.zoom = 18; // 15;
                this.podaciEdit.location.lat = Number(this.latitude);
                this.podaciEdit.location.lon = Number(this.longitude);
                this.setLocationFromData();
               /*  return; */
              } 
              // set latitude, longitude and zoom
              if(place.adr_address){
                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();
                this.zoom = 18; // 15;
                this.podaciEdit.location.lat = +this.latitude;
                this.podaciEdit.location.lon = +this.longitude;
              }
            });     
          });   
        });
      }, 100);
    }
  }

  setLocationFromData() {
    if ('geolocation' in navigator) {
        this.latitude = +this.podaciEdit.location.lat;
        this.longitude = +this.podaciEdit.location.lon;
        this.zoom = 18; //15;
    }
  }   

 onMapReady(map: any) {
      map.setOptions({
        mapTypeControl: 'true',
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'hybrid'],
          position: google.maps.ControlPosition.TOP_RIGHT,
          style: google.maps.MapTypeControlStyle.DEFAULT
        },
        fullscreenControl: true,
        streetViewControl: true
    });
  }

}

function newFunction(): string {
	return 'multipart/form-data';
}