import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class AboutService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }
    
    editAbout(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/urediONama', a)
        .pipe(map((res: any) => res      
        ));
    }

    about(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiPodatkeONama', a)
        .pipe(map((res: any) => res      
        ));
    }

    // uploadSlike(a: any){
    //     return this._http.post(AppConfig.ipAddress + 'upload/uploadSliku', a)
    //     .pipe(map((res: any) => res      
    //     ));
    // }

    upload(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadSlikuIResize?height=', a)
        .pipe(map((res: any) => res      
        ));
    }

    deleteImage(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/obrisiFile', a)
        .pipe(map((res: any) => res      
        ));
    }

    oneObject(a: any){
        return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiOsnovneInfo', a)
        .pipe(map((res: any) => res      
        ));
    }
    
    getLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/getLanguage', a)
        .pipe(map((res: any) => res));
    }

    editLanguage(a: any){
        return this._http.put(AppConfig.ipAddress + 'language/editLanguage', a)
        .pipe(map((res: any) => res));
    }

    uploadAudio(a: any){
        return this._http.post(AppConfig.ipAddress + 'upload/uploadAudio', a)
        .pipe(map((res: any) => res      
        ));
    }

}


/**
 *     password(a: any){
        return this._http.put(AppConfig.ipAddress + 'users/password/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }
 */