import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import notify from 'devextreme/ui/notify';
import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';

import { ObjectsService } from './objects.service';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss'],
  providers: [ObjectsService]
})
export class ObjectsComponent implements OnInit {

  token: any;
  nazivDokumenta: string;
  role: any;
  myID: any;
  naslov: string;
  objects = [];
  activity = [];
  id: any;
  isEditSuccess = false;
  isEditError = false;
  window: any;
  jezikPopup = false;
  sviJezici = [];
  idObjekta: any;
  headerFilterData = [
    {
      text: 'Yes',
      value: true,
      template: 'filter'
    },
    {
      text: 'No',
      value: false,
      template: 'filter'
    }
  ]
  
  constructor(
    private _objectService: ObjectsService,
    private router: Router
  ) {
    var filter = localStorage.getItem('zapamtiFilter');
    this.token = sessionStorage.getItem('token');
    this.role = localStorage.getItem('role');
    this.myID = localStorage.getItem('myID');

    // zbog promjene naslova popup-a
    this.onShowing = this.onShowing.bind(this);

    this.allObjects();

    this.activity = [{id: 1, name: 'True'},{id: 0, name: 'False'}];
  }

  onExporting(e){
    e.component.beginUpdate();
    e.component.columnOption("contactEmail", "visible", true);
    e.component.columnOption("contactPerson", "visible", true);
    e.component.columnOption("language", "visible", true);
  }

  onExported(e){
    e.component.columnOption("contactEmail", "visible", false);
    e.component.columnOption("contactPerson", "visible", true);
    e.component.columnOption("language", "visible", false);
    e.component.endUpdate();
    this.allObjects();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        locateInMenu:'auto',
        location: 'after',
        template: "kreirajObjekt",
      },
      {
        location: 'before',
        template: 'naslovTablice',
      }
    )
  }

  onShowing(e){
    e.component.option("title", this.naslov);
  }

  hover(e){
    if (e.rowType === "data" && e.column.dataField === "name"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.color = "#E6861B";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.color = "white";
      }
    }
    if (e.rowType === "data" && e.column.dataField === "defaultJezik"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.cursor = 'pointer';
      }
    }
  }

  /* -------------------- aktivan-neaktivan na button ----------------------------- */
  aktiviraj(e, data){
    this._objectService.activation({id: data.key.id}).subscribe(res=> {
      if(res.success == true){
        this.isEditSuccess = true;
      } else {
        this.isEditError = true;
      }
      this.allObjects();
    })
  }

  // da ide na detalj
  cellClickEvent(e){
    this.nazivDokumenta = e.values[0];
    this.sviJezici = [];
    if(e.rowType != 'header'){
      this.idObjekta = e.key.id;
      let NavigationExtras: NavigationExtras = {
        state: { object: e.key }   
      }
    if (e.rowType == "data" && e.column.dataField === "name") {
      this.router.navigate(['/one-object/' + e.key.id], NavigationExtras);
    }
      // Default language
      if (e.rowType == "data" && e.column.dataField === "defaultJezik") {
        this.jezikPopup = true;
        this.idObjekta = e.key.id;
        for (const key in e.key.language) {
          if (e.key.language.hasOwnProperty(key)) {
            // console.log({name: `${key}`, text: `${key}`, value: `${e.key.language[key]}` });
            this.sviJezici.push({name: key, text: key, value: e.key.language[key]});
          }
        }
      }
    }
  }

  kreirajObjekt(){
    let NavigationExtras: NavigationExtras = {
      state: { object: 'newObject' }
    }
    this.router.navigate(['/basic-info'], NavigationExtras);
  }

  allObjects(){
    this._objectService.objects({"myID": this.myID, "role": this.role}).subscribe(res => {
       this.objects = res;
    })
  }

  // jezici(e){
  //   this.jezikPopup = true;
  // }

  ngOnInit() {
    sessionStorage.removeItem('contentKey');
  }

  odabranJezik(e, item){
    this._objectService.addLanguage({idObjekta: this.idObjekta, language: item}).subscribe(res=> {
      if(res.success == true){
        notify(res.message, 'success');
      } else {
        e.target.checked = false;
        notify(res.message, 'error');
      }
    })
  }

  languageColumn_calculateCellValue (rowData) {
    if(rowData.language){
      let languageKeys= Object.keys(rowData.language);
      let activeLanguagesString = "";

      var activeLanguages = languageKeys.filter(function(key) {
          return rowData.language[key]
      });
  
      for(var i = 0; i < activeLanguages.length; i++){
        activeLanguagesString += activeLanguages[i].toLocaleUpperCase();
        (activeLanguages.length > 1) && (i < activeLanguages.length - 1 ) ? activeLanguagesString += ", " : "";
      }
  
      return activeLanguagesString;
    }
  }

  deleteObject(e: any){
    let encodedMessage = encodeHtml("Are you sure that you want to delete this object?");
    let myDialog = custom({
        title: "Delete",
        messageHtml: encodedMessage,
        buttons: [{
            text: "Yes",
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
        },{
          text: "No",
          onClick: (e) => {          
              return { buttonText: e.component.option("text") }
          }
      }]
    });  
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'Yes'){               
        this._objectService.obrisiAccommodation({id: e.data.id}).subscribe(res=>{
          if(res.success == true){
            notify('Object successfully deleted', 'success');
          } else {
            notify(res.message ,'error');
          }
          this.allObjects();
        })         
      }
    });  
  }

  dohvatiPDF(item){
    this._objectService.napraviPDF2({idObjekta: this.idObjekta, jezik: item.name}).subscribe(res=>{
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = this.nazivDokumenta + '-' + item.name + '.pdf';
        link.click();
    })
  }

}