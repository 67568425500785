import { Component, OnInit, ViewChild } from '@angular/core';
import { YouMayNeedService } from './you-may-need.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';
import { AppConfig } from './../../config';
import { BasicInfoService } from '../basic-info/basic-info.service';

@Component({
  selector: 'app-you-may-need',
  templateUrl: './you-may-need.component.html',
  styleUrls: ['./you-may-need.component.scss'],
  providers: [YouMayNeedService, BasicInfoService]
})
export class YouMayNeedComponent implements OnInit {
  @ViewChild("jezikForma", { static: false }) jezikForma: DxFormComponent;  
  @ViewChild("formaTransport", { static: false }) formaTransport: DxFormComponent;  
  @ViewChild("formaEmergency", { static: false }) formaEmergency: DxFormComponent;  
  @ViewChild("formaRentacar", { static: false }) formaRentacar: DxFormComponent;  
  @ViewChild("formaCar", { static: false }) formaCar: DxFormComponent;  
  adresaImage = AppConfig.adresaImage;
  transportList = [];
  emergencyList = [];
  rentACarList = [];
  carWashList = [];
  // wakeUpCallList = [];
  id: any;
  transport = false;
  emergency = false;
  rentACar = false;
  carWash = false;
  // wakeUpCall = false;
  klik = false;
  usluga: string;
  otvoriPopupTransport = false;
  otvoriPopupEmergency = false;
  otvoriPopupRentACar = false;
  otvoriPopupCarWash = false;
  // otvoriPopupWakeUpCall = false;   
  sveusluge: any;
  imeObjekta: any;
  popupPrijevod = false;
  textPrijevod = {};
  jezikTeksta: any;
  jezikTextaObjekt: any;
  prijevodDescription: any;
  prijevodHouseRules: any;
  idObjekta: any;
  odabranaUsluga: any;
  fotka: any;
  value: any;
  height: any;
  prikaziGaleriju = false;
  slike = [];
  picture: any;
  imaSlike = false;
  kocka: any;
  loadingVisible = false;
  prijevodWeb: any;
  popisJezikaEdit = [];
  defaultJezik: string;
  jezikPrijevoda: string;

  constructor(
    private basicInfoService: BasicInfoService,
    private youMayNeedService: YouMayNeedService,
    private _route:ActivatedRoute
  ) {
    this.id = this._route.snapshot.params['id'];
    this.youMayNeedService.oneObject({"id": this.id}).subscribe(res => {
      this.imeObjekta = res.name; 
      this.idObjekta = res.ownerId;
      this.defaultJezik = res.defaultJezik;
    });
    this.usluge();    
   }

  onShownJezik(e){   
    this.jezikForma.instance.focus();
  }

  onShown(e){   
    this.formaTransport.instance.focus();
  }

  onShownEmergency(e){   
    this.formaEmergency.instance.focus();
  }

  onShownRent(e){   
    this.formaRentacar.instance.focus();
  }

  onShownCar(e){   
    this.formaCar.instance.focus();
  }

  odustani(){
    this.otvoriPopupTransport = false;
  }
  odustaniE(){
    this.otvoriPopupEmergency = false;
  }
  odustaniR(){
    this.otvoriPopupRentACar = false;
  }
  odustaniC(){
    this.otvoriPopupCarWash = false;
  }


  otvoriGaleriju(){
    this.prikaziGaleriju = true;
  }

    /** --------------------- SLIKA ---------------------- */
  /** slika */
dodajFotku(event){
  this.value =  event.target.files;
  let img = new Image();
  img.src = window.URL.createObjectURL(event.target.files[0])
  img.onload = () => {
    // console.log(this.value);    
  this.height = img.height;
  this.uploadDoc(event.target.value);
  }
}

ukloniFotku(slika, podaci){  
// console.log(this.podaciEdit);
  this.youMayNeedService.deleteImage({'fileName': slika}).subscribe(res => {
    // console.log(res);    
    if(res.success == true){      
     this.picture = null;
     this.fotka = null;
     this.imaSlike = false;
     this.slike = [];
     this.usluge();
    //  console.log(this.kocka);
     if(this.kocka === 1){
       this.spremiTransportNakonBrisanja(podaci)
     } else if(this.kocka === 2){
       this.spremiEmergencNakonBrisanja(podaci)
     } else if(this.kocka === 3){
       this.spremiRentACarNakonBrisanja(podaci)
     } else if(this.kocka === 4){
       this.spremiCarWashNakonBrisanja(podaci)
     }
    }}
  )
}

uploadDoc(data){
  let fileList: FileList = this.value;
    if(fileList.length > 0){
      let file: File = fileList[0];         
      let formData:FormData = new FormData();
        formData.append('datoteka', file);
        formData.append('height', this.height);
      let headers = new Headers();
        headers.append('Content-Type', newFunction());            
      this.youMayNeedService.upload(formData).subscribe(res => {  
        // console.log(res);
        this.fotka = res.name + res.extension;     
        this.picture = res.name + res.extension;   
        if(this.fotka) {
          this.imaSlike = true;
           // prikaz slike za slide
          this.slike.push(this.adresaImage + '/imagesUpload/' + this.fotka)
        } else { 
          this.imaSlike = false
        }
      });  
    }
  } 
  
   onTransport(){
    this.transport = true;
     this.kocka = 1;
    // this.usluge();  
     this.slike = [];
     this.usluga = 'Transport';    
     this.emergency = false;
     this.rentACar = false;
     this.carWash = false;
     this.dohvatiMoguceJezike(this.defaultJezik, 'Transport');
    //  this.wakeUpCall = false;
     this.klik = true;
      if(Object.keys(this.sveusluge.transport).length === 0){
        // console.log('nema ničega');
        this.editTransport();
      } else {
        // console.log('ima vrijednosti');  
      }
      if(this.transportList['picture']){
        this.imaSlike = true;
        this.fotka = this.transportList['picture'];
        this.slike.push(this.adresaImage + '/imagesUpload/' + this.transportList['picture']) 
      } else {
        this.imaSlike = false;
        this.fotka = null;
        this.slike = [];
      }
   }

   onEmergency(){
    this.emergency = true;
     this.kocka = 2;
     this.slike = [];
     this.usluga = 'Emergency';    
     this.transport = false;
     this.rentACar = false;
     this.carWash = false;
    //  this.wakeUpCall = false;
     this.klik = true;
     if(Object.keys(this.sveusluge.emergency).length === 0){
      // console.log('nema ničega');
      this.editEmergency();
    } else {
      // console.log('ima vrijednosti');  
    }
    if(this.emergencyList['picture']){
      this.imaSlike = true;
      this.fotka = this.emergencyList['picture'];
      this.slike.push(this.adresaImage + '/imagesUpload/' + this.emergencyList['picture']) 
    } else {
      this.imaSlike = false;
      this.fotka = null;
      this.slike = [];
    }
  }

  onRentACar(){
    this.rentACar = true; 
     this.kocka = 3;
     this.slike = [];
     this.usluga = 'Rent a car';
     this.transport = false;
     this.emergency = false;
     this.carWash = false;
     this.dohvatiMoguceJezike(this.defaultJezik, 'rentAcar');
    //  this.wakeUpCall = false;
     this.klik =  true;

      if(Object.keys(this.sveusluge.rentACar).length === 0){
        // console.log('nema ničega');
        this.editRentACar();
      } else {
        // console.log('ima vrijednosti');  
      }
      if(this.rentACarList['picture']){
        this.imaSlike = true;
        this.fotka = this.rentACarList['picture'];
        this.slike.push(this.adresaImage + '/imagesUpload/' + this.rentACarList['picture']) 
      } else {
        this.imaSlike = false;
        this.fotka = null;
        this.slike = [];
      }
   }

   onCarWash(){
    this.carWash = true;
     this.kocka = 4;
     this.slike = [];
     this.usluga = 'Car wash';       
     this.transport = false;
     this.emergency = false;
     this.rentACar = false;
     this.dohvatiMoguceJezike(this.defaultJezik, 'CarEmergencyHelp');
    //  this.wakeUpCall = false;
     this.klik = true;
     if(Object.keys(this.sveusluge.carWash).length === 0){
      // console.log('nema ničega');
      this.editCarWash();
    } else {
      // console.log('ima vrijednosti');  
    }
    if(this.carWashList['picture']){
      this.imaSlike = true;
      this.fotka = this.carWashList['picture'];
      this.slike.push(this.adresaImage + '/imagesUpload/' + this.carWashList['picture']) 
    } else {
      this.imaSlike = false;
      this.fotka = null;
      this.slike = [];
    }
  }

  //  onWakeUpCall(){
  //   this.usluga = 'WakeUp call';
  //   //  this.wakeUpCall = true;  
  //    this.transport = false;
  //    this.emergency = false;
  //    this.rentACar = false;
  //    this.carWash = false;
  //    this.klik = true;
  //    if(Object.keys(this.sveusluge.wakeUpCall).length === 0){
  //     // console.log('nema ničega');
  //     this.editWakeUpCall();
  //   } else {
  //     // console.log('ima vrijednosti');  
  //   }
  //  }

  usluge(){
    this.youMayNeedService.usluge({id: this.id}).subscribe(res => {
      this.sveusluge = res;
       this.transportList = res.transport;
       this.emergencyList = res.emergency;
       this.rentACarList = res.rentACar;
       this.carWashList = res.carWash;
      //  this.wakeUpCallList = res.wakeUpCall;
    })
  }

  ngOnInit() {  }

/** TRANSPORT */
  editTransport(){
    this.otvoriPopupTransport = true;
  }

  saveTransport(e){ 
    this.loadingVisible = true;
    this.otvoriPopupTransport = false;  
    this.youMayNeedService.editTransport({
      "id": this.id,
      "shortDescription": e.shortDescription || null,
      "contactEmail": e.contactEmail || null,
      "contactPhone": e.contactPhone || null,
      "website": e.website || null,
      "picture": this.picture
    }).subscribe(res => {
      this.loadingVisible = false;
      this.usluge();   
      // console.log(res);      
    });  
  }

  spremiTransportNakonBrisanja(e){
    this.youMayNeedService.editTransport({
      "id": this.id,
      "shortDescription": e.shortDescription || null,
      "contactEmail": e.contactEmail || null,
      "contactPhone": e.contactPhone || null,
      "website": e.website || null,
      "picture": this.picture
    }).subscribe(res => {
      this.usluge();    
    }); 
  }

  /** EMERGENCY */
  editEmergency(){
    // console.log('UREĐIUJEM emergency')
    this.otvoriPopupEmergency = true;

    // console.log(this.emergencyList);
    // console.log(Object.keys(this.emergencyList).length);

    if(Object.keys(this.emergencyList).length === 0 || 
    (this.emergencyList['GSS']==="" && this.emergencyList['HAK']==="" && this.emergencyList['contactFiremen']==="" && this.emergencyList['contactHospital']==="" && this.emergencyList['emergency']==="")){
        this.emergencyList['emergency'] = ' +385 ' +  '192'    
        this.emergencyList['contactFiremen'] = '+385 ' + '193' 
        this.emergencyList['contactHospital'] = '+385 ' + '194' 
        this.emergencyList['GSS'] = '+385 ' + '112' 
        this.emergencyList['HAK'] = '+385 ' + '1987' 
        this.spremiEmergencySBrojevima(); 
      } else {
        this.emergencyList = this.emergencyList;
      }
  }

  // Automatski sprema sve predefinirane brojeve prilikom kreiranja novog objekta
  spremiEmergencySBrojevima(){
    this.youMayNeedService.editEmergency({
      "id": this.id,
      "emergency": this.emergencyList['emergency'],
      "contactFiremen": this.emergencyList['contactFiremen'],
      "contactHospital": this.emergencyList['contactHospital'],
      "pharmacy":  null,
      "doctor": null,
      "GSS": this.emergencyList['GSS'],
      "HAK": this.emergencyList['HAK'],
      "picture": null
    }).subscribe(res => {
      this.usluge();       
    });
  }

  saveEmergency(e){    
    this.loadingVisible = true;
    // console.log(e);    
    // console.log('spremi Emergency');   
    this.otvoriPopupEmergency = false;  
    this.youMayNeedService.editEmergency({
      "id": this.id,
      "emergency": e.emergency || null,
      "contactFiremen": e.contactFiremen || null,
      "contactHospital": e.contactHospital || null,
      "pharmacy": e.pharmacy || null,
      "doctor": e.doctor || null,
      "GSS": e.GSS || null,
      "HAK": e.HAK || null,
      "picture": this.picture
    }).subscribe(res => {
      this.loadingVisible = false;
      // console.log(res);  
      this.usluge();       
    });  
  }

  spremiEmergencNakonBrisanja(e){
    this.youMayNeedService.editEmergency({
      "id": this.id,
      "emergency": e.emergency || null,
      "contactFiremen": e.contactFiremen || null,
      "contactHospital": e.contactHospital || null,
      "pharmacy": e.pharmacy || null,
      "doctor": e.doctor || null,
      "GSS": e.GSS || null,
      "HAK": e.HAK || null,
      "picture": this.picture
    }).subscribe(res => {
      // console.log(res);  
      this.usluge();       
    }); 
  }

  /** RENT A CAR */
  editRentACar(){
    this.otvoriPopupRentACar = true;
  }

  saveRentACar(e){
    this.loadingVisible = true;
    this.otvoriPopupRentACar = false;
    this.youMayNeedService.editRentACar({
      "id": this.id,
      "shortDescription": e.shortDescription || null,
      "contactPhone": e.contactPhone || null,
      "email": e.email || null,
      "picture": this.picture
    }).subscribe(res => {
      // console.log(res);  
      this.loadingVisible = false; 
      this.usluge();      
    });  
  }

  spremiRentACarNakonBrisanja(e){
    this.youMayNeedService.editRentACar({
      "id": this.id,
      "shortDescription": e.shortDescription || null,
      "contactPhone": e.contactPhone || null,
      "email": e.email || null,
      "picture": this.picture
    }).subscribe(res => {
      // console.log(res);   
      this.usluge();      
    });  
  }

    /** CAR WASH */
  editCarWash(){
    this.otvoriPopupCarWash = true;
  }

  saveCarWash(e){
    // console.log(e);    
    this.loadingVisible = true;
    this.otvoriPopupCarWash = false;      
    this.youMayNeedService.editCarWash({
      "id": this.id,
      "shortDescription": e.shortDescription || null,
      "contactPhone": e.contactPhone || null,
      "email": e.email || null,
      "picture": this.picture
    }).subscribe(res => {
      // console.log(res);  
      this.loadingVisible = false;  
      this.usluge();     
  });  
}

spremiCarWashNakonBrisanja(e){
  this.youMayNeedService.editCarWash({
    "id": this.id,
    "shortDescription": e.shortDescription || null,
    "contactPhone": e.contactPhone || null,
    "email": e.email || null,
    "picture": this.picture
  }).subscribe(res => {
    // console.log(res);    
    this.usluge();     
}); 
}

    /** WAKEUP CALL */
    // editWakeUpCall(){
    //   this.otvoriPopupWakeUpCall = true;
    // }
  
  //   saveWakeUpCall(e){
  //     // console.log(e);    
  //     // console.log('spremi WAKEUP CALL');
  //     this.otvoriPopupWakeUpCall = false;  
      
  //     this.youMayNeedService.editWakeUpCall({
  //       "id": this.id,
  //       "shortDescription": e.shortDescription || null,
  //       "contactPhone": e.contactPhone || null,
  //       "email": e.email || null
  //     }).subscribe(res => {
  //       // console.log(res); 
  //       this.usluge();        
  //   });  
  // }

  zatvoriPopup(){
    this.usluge();
  }
  
  //////////////////////////** PRIJEVOD NA JEZIKE *//////////////////////////////
  spremiPrijevod(e){
    this.popupPrijevod = false;
    this.prijevodDescription = e.description;
    this.prijevodWeb = e.web;
    switch(this.odabranaUsluga){
      case 'transport':
        this.youMayNeedService.editLanguage({
          'accommodationId': this.id,
          'jezik': this.jezikTeksta,
          'youMayNeed': {
              'transport': this.odabranaUsluga == 'transport' ? this.prijevodDescription : null,
              'web': this.prijevodWeb || null
          }
      }).subscribe(res=>{
          if(res.success == true){
            this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
            notify('Your translation is saved.', 'success');
          }
      })
        break;
    }
    switch(this.odabranaUsluga){
      case 'rentACar':
        this.youMayNeedService.editLanguage({
          'accommodationId': this.id,
          'jezik': this.jezikTeksta,
          'youMayNeed': {
              'rentACar': this.odabranaUsluga == 'rentACar' ? this.prijevodDescription : null
          }
      }).subscribe(res=>{
          if(res.success == true){
            this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
            notify('Your translation is saved.', 'success');
          }
      })
        break;
    }
    switch(this.odabranaUsluga){
      case 'carWash':
        this.youMayNeedService.editLanguage({
          'accommodationId': this.id,
          'jezik': this.jezikTeksta,
          'youMayNeed': {
              'carWash': this.odabranaUsluga == 'carWash' ? this.prijevodDescription : null
          }
      }).subscribe(res=>{
          if(res.success == true){
            this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
            notify('Your translation is saved.', 'success');
          }
      })
        break;
    }
  }

  dohvatiMoguceJezike(defaultJezik, vrsta){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.id, 'jezik': defaultJezik, 'ekran': vrsta}).subscribe(res=> {
        // console.log(res);
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event, usluga){
    this.odabranaUsluga = usluga;
    this.popupPrijevod = true;
    this.jezikPrijevoda = $event.selectedItem.name;
    this.jezikTeksta = $event.selectedItem.text;
    this.youMayNeedService.getLanguage({
        accommodationId: this.id,
        language: $event.selectedItem.text
    }).subscribe(res=>{
      console.log(res);
      if(res.success == false){
        notify(res.data, 'error');
      } else {
        switch(usluga){
          case 'transport':
            this.textPrijevod['description'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.transport : '';
            this.textPrijevod['web'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.web : '';
            break;
        }
        switch(usluga){
          case 'rentACar':
            this.textPrijevod['description'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.rentACar : '';
            this.textPrijevod['web'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.web : '';
            break;
        }
        switch(usluga){
          case 'carWash':
            this.textPrijevod['description'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.carWash : '';
            this.textPrijevod['web'] = res.data.youMayNeed != undefined ? res.data.youMayNeed.web : '';
            break;
        }
        // switch(usluga){
        //   case 'wakeUpCall':
        //     this.textPrijevod['description'] = res.data.youMayNeed.wakeUpCall;
        //     break;
        // }
      }
   })
  }

     odustaniPrijevod(){
      this.popupPrijevod = false;
      this.textPrijevod = {};
    }
  
}

function newFunction(): string {
	return 'multipart/form-data';
}