import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { MapsAPILoader, MouseEvent } from '@agm/core';

import notify from 'devextreme/ui/notify';
import { encodeHtml } from 'devextreme/core/utils/string'
import { custom } from 'devextreme/ui/dialog';
import { DxFormComponent } from 'devextreme-angular';

import { editInteresService } from './edit-interes.service';
import { BasicInfoService } from './../../basic-info/basic-info.service';
import { AppConfig } from './../../../config';
import { TagsService } from '../../tags/tags.service';

declare const google: any;

@Component({
  selector: 'app-edit-interes',
  templateUrl: './edit-interes.component.html',
  styleUrls: ['./edit-interes.component.scss'],
  providers: [editInteresService, BasicInfoService, TagsService]
})
export class EditInteresComponent implements OnInit {

  @ViewChild("jezikForma", { static: false }) jezikForma: DxFormComponent;  
  adresaImage = AppConfig.adresaImage;
  interest: any;
  token: any;
  id: any;
  latitude: number;
  longitude: number;
  samoPrikaz = false;
  address: string;
  zoom: number =  18;
  private geoCoder;
  value: any;
  imeObjekta: any;
  tipObjekta: any;
  objekt: any;
  object = {
    'access_token': '',
    "id": '',
    "name": '',
    "phone": '',
    "city": '',
    "address": '',
    "email": '',
    "web": '',
    "parking": '',
    "petFriendly": '',
    "gallery": [],
    "pdf": [],
    "openHours": '',
    "shortDescription": '',
    "view360": '',
    "lon": 0,
    "lat": 0,
    "active": 0,
    'location': {"lat": 0, "lon": 0},
    "type": '',
    'tags': []
  };
  activity: any;
  parking: any;
  petFriendly: any;
  odabranParking: any;
  odabranPetFriendly: any;
  location: Location;
  fotke = [];
  images = [];
  imagesEdit = [];
  types: any;
  limitUpload = false;
  otvoriPopupPDF = false;
  pdfDatoteka = [];
  imaPDF = false;
  dodanPDF = false;
  nijeDodanPDF = false;
  valuepdf: any;
  brojPDF: any;
  popupPrijevod = false;
  textPrijevod = {};
  zastavaJezik: any;
  jezikTeksta: any;
  jezikTextaObjekt: any;
  prijevodDescription: any;
  height: any;
  prikaziGaleriju = false;
  slike = [];
  gradovi: any;
  termSearch: any;
  gradoviPopup = false;
  odabranGrad: any;
  odabranType: any;
  odabraniTipovi: any;
  prijevodTitle: any;
  prijevodOpenHours: any;
  prijevodWeb: any;
  draggingIndex: number;
  jezikPrijevoda: string;
  popisJezikaEdit = [];

  tagsList: any = [];
  linkovi: any = [];

  @ViewChild('search', {static: false}) public searchElementRef: ElementRef;
  @ViewChild('map', {static: false}) public mapElementRef: ElementRef;

  constructor(
    private editInteresService: editInteresService,
    private _route:ActivatedRoute,
    private router: Router, 
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _location: Location,
    private basicInfoService: BasicInfoService,
    private _tagsService: TagsService
  ){
    this.id = this._route.snapshot.params['id'];
    this.token = sessionStorage.getItem('token');
    this.slikePrikaz();
    
    if(this.router.getCurrentNavigation().extras.state){
      this.samoPrikaz = true;
      this.oneInterest();
    } else {
      this.samoPrikaz = false;
      this.oneInterestEditing();
    }  
    this.types = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}       
    ]

    this.activity = [
      {id: 1, name: 'True'},
      {id: 0, name: 'False'}
    ]
                   
    this.parking = [
      { id: 'NO', name: 'NO'},
      { id: 'YES', name: 'YES'}
    ]
              
    this.petFriendly = [
      { id: 'NO', name: 'NO'},
      { id: 'YES', name: 'YES'}
    ]

    this.changeType = this.changeType.bind(this);
  }

  getTags(){
    this.tagsList = [];
    this._tagsService.dohvatiSveTagove({'jezik': this.jezikTeksta}).subscribe(res =>{
      if(res.success === true){
        this.tagsList = res.data;
      }
    })
  }

  onContentReady(e){   
    e.component.getEditor('name').focus();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift( 
      {
        location: 'before',
        template: 'naslovTablice',    
      }
    )
  }

  // Promijena redoslijeda slika
  private _reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.fotke.splice(fromIndex, 1)[0];
    this.fotke.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragStart(index: number): void {
    this.draggingIndex = index;
  }

  onDragEnter(index: number): void {
    if (this.draggingIndex !== index) {
      this._reorderItem(this.draggingIndex, index);
    }
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }

  onShownJezik(e){   
    this.jezikForma.instance.focus();
  }

  odustaniPrijevod(){
    this.popupPrijevod = false;
    this.textPrijevod = {};
  }

  odustani(){
    this._location.back();
  }

  oneInterest(){
    this.editInteresService.getOneInterest({id: this.id}).subscribe(res=> {
      this.interest = res;
      this.latitude = parseFloat(this.interest.location.lat);
      this.longitude = parseFloat(this.interest.location.lon);   
      this.imeObjekta = this.interest.name;
      this.images = this.interest.gallery;
      this.pdfDatoteka = this.interest.pdf;
      this.dohvatiMoguceJezike(res.defaultJezik);
      this.dohvatiLinkove();
      this.getTags();
      this.jezikTeksta = res.defaultJezik; 
      switch(this.interest.type){
        case 'beach':              
          this.interest.type = 'Beach';
        break;
        case 'organizedTrip':
          this.interest.type = "Organized Trip";
        break;
        case 'indoor':
          this.interest.type = "Indoor";
        break;
        case 'healthAndBeauty':
          this.interest.type = "Health And Beauty";
        break;
        case 'sportAndRecreation':
          this.interest.type = "Sport And Recreation";
        break;
        case 'sightOfNature':
          this.interest.type = "Sight Of Nature";
        break;
        case 'historyAttraction':
          this.interest.type = "History Attraction";
        break;
        case 'restaurant':
          this.interest.type = "Restaurant";
        break;
        case 'feelAndTaste':
          this.interest.type = "Feel and taste";
        break;
        case 'shopingAndGroceries':
          this.interest.type = "Shoping and groceries";
        break;
        case 'happenings':
          this.interest.type = "Happenings";
        break;
        case 'accommodation':
          this.interest.type = "Accommodation";
        break;
      }
    })
  }

  changeType(e) {
    this.odabranType = e.value;  
  }

  oneInterestEditing(){
    this.editInteresService.getOneInterest({id: this.id}).subscribe(res=> {
      this.objekt = res;
      this.odabranGrad = this.objekt.city
      this.latitude = parseFloat(this.objekt.location.lat);
      this.longitude = parseFloat(this.objekt.location.lon);   
      this.imeObjekta = this.objekt.name;
      this.fotke = this.objekt.gallery;
      this.pdfDatoteka = this.objekt.pdf;
      this.jezikTeksta = res.defaultJezik; 
      this.dohvatiMoguceJezike(res.defaultJezik);
      this.dohvatiLinkove();
      this.getTags();
       if(this.fotke.length == 5){
        this.limitUpload = true;
      }
    });
  }

  uklanjanjeSlika(){
      this.object.access_token = this.token,
      this.object.id = this.id,
      this.object.gallery = this.fotke,
      this.editInteresService.updateInterest(this.object).subscribe(res=>{
        // console.log(res);   
      })  
  }

  saveChanges(data: any){    
    if(data.location.lat === 0){
       data.location.lat = this.latitude;
    }
    if(data.location.lon === 0) {
       data.location.lon = this.longitude;
    }
    this.object.access_token = this.token,
    this.object.id = data.id,
    this.object.name = data.name,
    this.object.phone = data.phone || null,
    this.object.address = data.address || null,
    this.object.city = this.odabranGrad,
    this.object.email = data.email || null,
    this.object.web = data.web || null,
    this.object.parking = data.parking || null,
    this.object.petFriendly = data.petFriendly || null,
    this.object.gallery = this.fotke,
    this.object.openHours = data.openHours || null,
    this.object.shortDescription = data.shortDescription || null,
    this.object.view360 = data.view360 || null,
    this.object.lat = data.location.lat,
    this.object.lon = data.location.lon,
    this.object.active = data.active.toString(); // jer ne prihvaća broj
    this.object.pdf = this.pdfDatoteka;
    this.object.location = {lat: data.location.lat, lon: data.location.lon}
    this.object.type = data.type   /// this.odabranType, 
    this.object.tags = data.tags;
    
    // 17.03.2021. multiple odabir tipova 
    //////////////////////////////// edit odkomentirati ///////////////////////////////
    this.editInteresService.updateInterest(this.object).subscribe(res=>{   
      if(res.success == true) {
        notify('Success!', 'success')
        this.router.navigate(['/content/'])
      } else {
        notify('Something went wrong. Try again.', 'error');
      }
    }) 
  }

  /** ----------------------- galerija slika------------------------------------ */
  slikePrikaz(){
    this.slike = [];
    this.editInteresService.getOneInterest({"id": this.id}).subscribe(res => {
      for (var x in res['gallery']){
        this.slike.push(
            this.adresaImage + '/imagesUpload/' + res['gallery'][x] 
        )
      }   
    })
  }
  
// galerija slika 
  otvoriGaleriju(){
    this.prikaziGaleriju = true;
  }

  pdf(e){
    this.otvoriPopupPDF = true;   
    if(this.pdfDatoteka.length > 0){
      this.imaPDF = true;
    } else{
      this.imaPDF = false;
    }
  }

  uploadPDF(event){
    this.valuepdf = event.target.files;
    this.uploadpdf(event.target.value);
  }

  uploadpdf(data){
    let fileList: FileList = this.valuepdf;
    if(fileList.length > 0){
      let file: File = fileList[0];         
      let formData:FormData = new FormData();
          formData.append('datoteka', file);
      let headers = new Headers();
          headers.append('Content-Type', newFunction());            
      this.editInteresService.uploadPDF(formData).subscribe(res => {
          this.pdfDatoteka.push(res.name + res.extension);
          this.dodanPDF = true;
          if(this.pdfDatoteka.length != 0) {
            this.imaPDF = true;
          } else {
            this.imaPDF = false;
          }
      });
    }
  }

  doneClick(){
    this.otvoriPopupPDF = false;
  }

    // deletePDF(pdf, i){
    //   // console.log(pdf, i)
    //   // console.log({'fileName': pdf});
    //   this.editInteresService.deletePDF({'fileName': pdf}).subscribe(res => {
    //     console.log(res);      
    //         if(res.success == true) {
    //           this.pdfDatoteka.splice(i, 1)
    //           this.imaPDF = false;
    //           // this.uklanjanjePDF();
    //      } else { }
    //     //  console.log(this.pdfDatoteka);         
    //   })
    // }

  uklanjanjePDF(){
    this.object.access_token = this.token,
    this.object.id = this.id,
    this.object.pdf = this.pdfDatoteka;
    this.editInteresService.updateInterest(this.object).subscribe(res=>{
        // console.log(res);   
    })  
  }

  brisanjePdf(pdf, i){
    let encodedMessage = encodeHtml("Are you sure to delete this item?");
    let myDialog = custom({
        title: "Warning",
        messageHtml: encodedMessage,
        buttons: [{
          text: "Delete",
          onClick: (e) => {
            return { buttonText: e.component.option("text") }
          }
        },{
          text: "Cancel",
          onClick: (e) => {
            return { buttonText: e.component.option("text") }
          }
        }]
    });
  
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText == 'Delete'){
        this.editInteresService.deletePDF({'fileName': pdf}).subscribe(res => {     
            if(res.success == true){
              this.pdfDatoteka.splice(i, 1);
              this.uklanjanjePDF();
              this.imaPDF = false;
            }         
        })
      }
    });
  }

  ukloniFotku(slika, i){
    this.editInteresService.deleteImage({'fileName': slika}).subscribe(res => {  
      if(res.success == true){
       this.fotke.splice(i, 1);
       this.uklanjanjeSlika();
      }
      if(this.fotke.length == 5){
       notify('Only 5 photos can be uploaded!');
       this.limitUpload = true;
     } else {
       this.limitUpload  = false;
     }
    })
  }
   
  uploadClick(e){
    if(this.fotke.length == 5){
      notify('Only 5 photos can be uploaded. Delete one first!');
      this.limitUpload = true;
    } else {
      e.click();
    }
  }

  dodajFotku(event){
    this.value =  event.target.files;
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      this.height = img.height;
      this.uploadDoc(event.target.value);
    }
  }
  
  uploadDoc(data){
    let fileList: FileList = this.value;
    if(fileList.length > 0){
      let file: File = fileList[0];         
      let formData:FormData = new FormData();
          formData.append('datoteka', file);
          formData.append('height', this.height);
      let headers = new Headers();
          headers.append('Content-Type', newFunction());            
      this.editInteresService.upload(formData).subscribe(res => {
          this.fotke.push(res.name + res.extension);
          if(this.fotke.length == 5){
            notify('Only 5 photos can be uploaded. Delete one first!');
            this.limitUpload = true;
          }
      });  
    }  
  }

  ngOnInit() {
    if(!this.samoPrikaz){
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["geocode"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
            this.objekt.location.lat = this.latitude;
            this.objekt.location.lon = this.longitude;
            });
          });
      })
    }    

  }

  //** PRIJEVOD NA JEZIKE */
  dohvatiMoguceJezike(defaultJezik){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.id, 'interestID': this.id, 'jezik': defaultJezik, 'ekran': 'Content'}).subscribe(res=> {
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.popupPrijevod = true;
    this.jezikTeksta = $event.selectedItem.text;
    this.jezikPrijevoda = $event.selectedItem.name;
    this.dohvatiLinkove();
    this.editInteresService.getInterestLangugage({
        interestId: this.id, 
        language: $event.selectedItem.text
    }).subscribe(res=>{
      if(res.success == false){
        notify(res.message, 'error');
        this.textPrijevod['description'] = '';
        this.textPrijevod['title'] = '';
        this.textPrijevod['openHours'] = '';
        this.textPrijevod['web']= '';
      } else {
        this.textPrijevod['description'] = res.data;
        this.textPrijevod['title'] = res.datatitle;
        this.textPrijevod['openHours'] = res.datatime;
        this.textPrijevod['web'] = res.dataweb;
      }
    })
  }

  // prevedi(e, jezik){
  //   // console.log(e, jezik)
  //   this.zastavaJezik = e;
  //   this.popupPrijevod = true;
  //   this.jezikTeksta = jezik;

  //   /// pogledaj postojeći jezik
  //       this.editInteresService.getInterestLangugage({
  //         interestId: this.id,
  //         language: jezik
  //       }).subscribe(res=>{  
  //         // console.log(res)  
  //         if(res.success == false){
  //           // console.log(res)
  //           // notify(res.message, 'error')
  //           this.textPrijevod['description'] = '';
  //           this.textPrijevod['title'] = '';
  //           this.textPrijevod['openHours'] = '';
  //           this.textPrijevod['web']= '';
  //         } 
  //         else {
  //           this.textPrijevod['description'] = res.data;
  //           this.textPrijevod['title'] = res.datatitle;
  //           this.textPrijevod['openHours'] = res.datatime;
  //           this.textPrijevod['web'] = res.dataweb;
  //         }
  //     })
  // }

  spremiPrijevod(e){
    this.popupPrijevod = false;
    this.prijevodDescription = e.description;
    this.prijevodOpenHours = e.openHours;
    this.prijevodTitle = e.title;
    this.prijevodWeb = e.web;
    this.editInteresService.editInterestLanguage({
        interestId: this.id,
        jezik: this.jezikTeksta,
        desc: this.prijevodDescription || null,
        title: this.prijevodTitle || null,
        time: this.prijevodOpenHours || null,
        web: this.prijevodWeb || null}).subscribe(res=>{
        if(res.success == true){
          this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
          notify('Your translation is saved.', 'success');
        }
    })

  }
 
  dodajGrad(e){
    this.gradoviPopup = true;
  }

  zatvorenPopup(){
    this.termSearch = '';
  }

  trazim() {
    const term = this.termSearch;     
    if (term.length >= 3) {  
      this.editInteresService.dohvatiGradoveFilter({ime: term}).subscribe(res => {
        this.gradovi = res;
      })
    }
  }

  gradoviPopupClose(e){
    this.gradoviPopup = false;  
    this.gradovi = []; 
 }

  odabirGrada(index: number, data: any, isChecked: boolean){
    if(isChecked === true) {
      this.odabranGrad = data.cityName;
    }
  }

   //***************************** edit lokacije na mapi *********************************** */
  editAdressOnMap($event: MouseEvent){
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddressEdit(this.latitude, this.longitude);
    this.objekt.location.lat = this.latitude;
    this.objekt.location.lon = this.longitude;
  }

  getAddressEdit(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK'){
          if (results[0]) {
            this.zoom = 18;
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
    });
  }

  dohvatiLinkove(){
    this.editInteresService.dohvatiLinkove({'id': this.id, 'jezik': this.jezikTeksta}).subscribe(res => {
      if(res.success == true){
        this.linkovi = res.data;
      }         
    })
  }

  addLink($event: any){
    this.editInteresService.dodajLink({'id': this.id, 'opis': $event.data.opis, 'link': $event.data.link, 'jezik': this.jezikTeksta}).subscribe(res => {     
      if(res.success == true){
        notify('Link added!', 'success');
      }         
    })
  }

  editLink($event){
    this.editInteresService.urediLink({'id': this.id, 'linkID': $event.data.id, 'opis': $event.data.opis, 'link': $event.data.link, 'jezik': this.jezikTeksta}).subscribe(res => {
      if(res.success == true){
        notify('URL updated!', 'success');
      }         
    })
  }

  deleteLink($event){
    this.editInteresService.obrisiLink({'id': this.id, 'linkID': $event.data.id}).subscribe(res => {
      if(res.success == true){
        notify('URL deleted!', 'success');
      }         
    })
  }

}

function newFunction(): string {
	return 'multipart/form-data';
}