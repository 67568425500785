import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SelectedContentService {

  constructor(private _http: HttpClient) { }

  dohvatiInterese(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dohvatiInterese', a)
      .pipe(map((res: any) => res      
    ));
  }

  dodajInteres(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/dodajInteres', a)
      .pipe(map((res: any) => res      
    ));
  }

  ukloniInteres(a: any) {
    return this._http.put(AppConfig.ipAddress + 'accommodation/ukloniInteres', a)
      .pipe(map((res: any) => res      
    ));
  }

  allInterests(a: any){
    return this._http.get(AppConfig.ipAddress + 'interest/dohvatiSve', a)
      .pipe(map((res: any) => res      
    ));
  }

  gradoviObjekata(a: any){
    return this._http.get(AppConfig.ipAddress + 'accommodation/vratiGradove', a)
      .pipe(map((res: any) => res      
    ));
  }
  
  urediInterestOrderBy(a: any){
    return this._http.put(AppConfig.ipAddress + 'accommodation/urediInterestOrderBy', a)
      .pipe(map((res: any) => res      
    ));
  }

  contentExcel(a: any){
    return this._http.get(AppConfig.ipAddress + 'accommodation/contentExcel/?idObjekta=' + a.idObjekta, {responseType: 'blob'})
      .pipe(map((res: any) => res      
    ));
  }

}