import { Component, OnInit, ViewChild } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UsersService } from './users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UsersService]
})
export class UsersComponent implements OnInit {
  @ViewChild('inputLozinka', { static: false }) textBox: DxTextBoxComponent;  
  token: any;
  users = [];
  naslov: string;
  passwordPopup = false;
  editPopup = false;
  vrijedost: any;
  id: any;
  isToastVisibleSuccess = false;
  isToastVisibleError = false;
  userSuccess = false;
  userError = false;
  newUserSuccess = false;
  newUserError = false;
  podaciKorisnik:any;
  activity = [];
  roles = [];
  dodavanje = false;
  window: any;
  loadingVisible = false;

  constructor(private _usersService: UsersService) {
    this.token = sessionStorage.getItem('token');
    this.allUsers();
  
    // zbog promjene naslova popup-a
    this.onShowing = this.onShowing.bind(this);

    this.activity=[{id: 1, name: 'True'}, 
                   {id: 0, name: 'False'}]

    this.roles=[{value: 'admin', name: 'admin' }, 
                {value: 'agency', name: 'agency'}]
   }

   onToolbarPreparing(e) { 
      e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'naslovTablice',    
      }
    )
  }
  
  onShowing(e){  
    e.component.option("title", this.naslov);
  }

   allUsers(){
    this._usersService.allUsers({}).subscribe(res => {
       this.users = res;
      //  console.log(this.users)
    })
  }

  onInitNewRow(e){
    // console.log(e)
    this.dodavanje = true;
    this.naslov = 'Add new';
  }

  oneUser(e){
    this.dodavanje = false;
    this.naslov = 'Edit';
    this.id = e.data.id;
  }

  changePasword(data){
    // console.log(data.key);
    this.id = data.key;    
    this.passwordPopup = true;
  }

  onShown(e){
    this.textBox.instance.focus();
  }

  odustani(){
    this.passwordPopup = false;
    this.vrijedost = null;
  }

  savePassword(e){ 
    this.vrijedost = '';
    if(e){
      this.passwordPopup = false;
      this._usersService.password({id: this.id, password: e}).subscribe(res=> {
        if(res.success == true){
          // console.log(res);
          this.isToastVisibleSuccess = true;
        } else {
          this.isToastVisibleError = true;
        }      
      })
    } else {
      notify('Password is required!', 'error')
    }
  }

editUser(e){
  this.loadingVisible = true;
  this._usersService.editUser({id: this.id, phone: e.data.phone || null, fullName: e.data.fullName, active: e.data.active}).subscribe(res=> {
    // console.log(res)
    this.loadingVisible = false;
    if(res.success == true) {
      this.userSuccess = true;
    } else {
      this.userError = true;
    }
    this.allUsers();
  })
}

addUser(e) {
  this.loadingVisible = true;
  this._usersService.addUser({
        'email': e.data.email,
        'password': e.data.password,
        'role': e.data.role,
        'phone': e.data.phone,
        'fullName': e.data.fullName
  }).subscribe(res  => {
    this.loadingVisible = false;
      if(res.succes == true){
        // console.log(res);  
        this.newUserSuccess = true;
      } else {
        // console.log(res);        
        this.newUserError = true;
     }
     this.allUsers();
  })
}

  ngOnInit() {
    sessionStorage.removeItem('objectsKey');
    sessionStorage.removeItem('contentKey');
    }

}
