import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class ViatorService {

    constructor(private _http: HttpClient) { 
      // console.log(AppConfig.ipAddress)
    }

    dohvatiGradove(a: any) {
        return this._http.post(AppConfig.ipAddress + 'via_destination/dohvatiGradove', a)
          .pipe(map((res: any) => res));
    }
      
    dohvatiPonudu(a: any) {
    return this._http.post(AppConfig.ipAddress + 'via_destination/dohvatiPonudu', a)
        .pipe(map((res: any) => res));
    }

    dodajViatorDest(a: any) {
        return this._http.put(AppConfig.ipAddress + 'via_destination/dodajViatorDest', a)
        .pipe(map((res: any) => res));
    }

    ukloniViatorDest(a: any) {
        return this._http.put(AppConfig.ipAddress + 'via_destination/ukloniViatorDest', a)
        .pipe(map((res: any) => res));
    }
    
}