import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContentService } from './content.service';
import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';
import { Router, NavigationExtras } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  providers: [ContentService]
})
export class ContentComponent implements OnInit {

  token: any;
  allInterests = [];
  types: any;
  allCities = [];
  naslov: string;
  window: any;
  customFilter: any;
  prikazTipovi = [];
  role: any;
  isAdmin = false;
  jezikPopup = false;
  sviJezici: string;

  constructor(
    private interestService: ContentService,
    private router: Router
  ) {
    this.role = localStorage.getItem('role');
    this.token = sessionStorage.getItem('token');

  // zbog promjene naslova popup-a
  this.onShowing = this.onShowing.bind(this);

    this.types = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}  
    ]

    this.sviInteresi();
    this.sviGradoviObjekata();

      /// custom filter  /////
      this.customFilter = [
        {text: 'Accommodation', value: 'accommodation'},
        {text: "Beach",value: 'beach'}, 
        {text: "Feel and taste",value: 'feelAndTaste'},
        {text: 'Happenings', value: 'happenings'},
        {text: "Health And Beauty",value: 'healthAndBeauty'}, 
        {text: "History Attraction",value: 'historyAttraction'}, 
        {text: "Indoor",value: 'indoor'}, 
        {text: "Organized Trip",value: 'organizedTrip'},      
        {text: "Restaurant",value: 'restaurant'}, 
        {text: "Shoping and groceries",value: 'shopingAndGroceries'},
        {text: "Sight Of Nature",value: 'sightOfNature'}, 
        {text: "Sport And Recreation",value: 'sportAndRecreation'}, 
      ];  
  }

  onExporting(e){
    e.component.beginUpdate();
    e.component.columnOption("language", "visible", true);
  }

  onExported(e){
    e.component.columnOption("language", "visible", false);
    e.component.endUpdate();
  }

  calculateCellValue(data) {
    return data.type.length > 0 ? data.type.join(', ') : " ";
  }

  // calculateDisplayValue(rowData) {  
  //   // return rowData.type.join(', ')
  //   return rowData.type.length > 0 ? rowData.type.join(', ') : " ";
  // } 

  calculateDisplayValue(data) {
    this.prikazTipovi = [
      { id: 'accommodation', name: 'Accommodation'},  
      { id: 'beach', name: 'Beach'},
      { id: 'feelAndTaste', name: 'Feel and taste'},
      { id: 'happenings', name: 'Happenings'},  
      { id: 'healthAndBeauty', name: 'Health And Beauty'},  
      { id: 'historyAttraction', name: 'History Attraction'},
      { id: 'indoor', name: 'Indoor'},
      { id: 'organizedTrip', name: 'Organized Trip'},  
      { id: 'restaurant', name: 'Restaurant'},
      { id: 'shopingAndGroceries', name: 'Shoping and groceries'}, 
      { id: 'sightOfNature', name: 'Sight Of Nature'},
      { id: 'sportAndRecreation', name: 'Sport And Recreation'}          
    ]  

    var displayText = '';
    for (var i in data['type']) {
        var type = data['type'][i];
        for(let i = 0; i < this.prikazTipovi.length; i++){
        if(type === this.prikazTipovi[i].id)  
        displayText += this.prikazTipovi[i].name + ', ';
        }
    }
    displayText = displayText.slice(0, -2); // trim trailing delimter
    return displayText;
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {   
    return [this.calculateCellValue, "contains", filterValue];
  }

   oneInteres(e){
    this.naslov = 'Edit';
  }

   addInteres(e){
     this.router.navigate(['/add-interes']); 
   }

   editInteres(e){
     this.router.navigate(['/edit-interes/' + e.data.id]);
  }

  deleteInteres(e){
    let id = e.data.id;    
    let encodedMessage = encodeHtml("Are you sure that you want to delete this item?");
    let myDialog = custom({
        title: "Delete",
        messageHtml: encodedMessage,
        buttons: [{
            text: "Yes",
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
        },{
          text: "No",
          onClick: (e) => {          
              return { buttonText: e.component.option("text") }
          }
      }]
    });  
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'Yes'){               
        this.interestService.obrisiContent({id: e.data.id}).subscribe(res=>{
          if(res.success == true){
            notify('Interest successfully deleted', 'success');
          } else {
            notify(res.message ,'error');
          }
          this.sviInteresi();
        })            
      }else{  }  
    });  
  }
  
  hover(e){
    if (e.rowType === "data" && e.column.dataField === "name"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.fontWeight = "bold";
        e.cellElement.style.color = "#E6861B";
        e.cellElement.style.cursor = 'pointer';
      } else {
        e.cellElement.style.fontWeight = "normal";
        e.cellElement.style.color = "white";
      }  
    }
    if (e.rowType === "data" && e.column.dataField === "defaultJezik"){
      if (e.eventType == 'mouseover'){
        e.cellElement.style.cursor = 'pointer';
      }
    }
  }

  // da ide na detalj
  cellClickEvent(e){
    let navigationExtras: NavigationExtras = {
      state: { nalog: 'samoPrikaz' }         
    };
    if (e.rowType == "data" && e.column.dataField === "name") { 
      this.router.navigate(['/edit-interes/' + e.key], navigationExtras);
    }
    if (e.rowType == "data" && e.column.dataField === "defaultJezik") {
      this.jezikPopup = true;
      this.sviJezici = e.data.language;
    }
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift(
      {
        locateInMenu:'auto',
        location: 'after',
        template: "addInteres",
      },
      {
        location: 'before',
        template: 'naslovTablice',    
      }
    )      
  }

  onInitNewRow(e){
    this.naslov = 'Add new';
  }

  onShowing(e){  
    e.component.option("title", this.naslov);
  }

  sviInteresi(){
    this.interestService.allInterests({}).subscribe(res => {
       this.allInterests = res;      
    })
  }

  sviGradoviObjekata(){
    this.interestService.gradoviObjekata({}).subscribe(res => {
       this.allCities = res;
    })
  }

  ngOnInit() {
    sessionStorage.removeItem('objectsKey');
    if(this.role === 'admin') {
        this.isAdmin = true;
    } else if(this.role === 'agency') {
        this.isAdmin = false;
    }       
  }

  // ngOnDestroy(){
  //   sessionStorage.removeItem('uniqueKey1')
  // }

}