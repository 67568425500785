import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { NotificationDetailService } from './notification-detail.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
  providers: [NotificationDetailService]
})
export class NotificationDetailComponent implements OnInit {
  @ViewChild(DxFormComponent, {static: false}) notification: DxFormComponent;
  podaci: any;
  jezikTab = [
    { id: 0, text: "Hr" },
    { id: 1, text: "En" },
    { id: 2, text: "Fr" },
    { id: 3, text: "De" },
    { id: 4, text: "It" }
 ]
 tab: number = 0;
 id: any;
 obavijestDetalji: any = {};
 imeObjekta: any;
 checkboxOptions = [
   {id: 1, text: 'Send only to users of this object'},
   {id: 2, text: 'Send to all users in this city'}
 ]
 checkOption: any;
 obavijestPoslana = false;
 checkRadio: any;

  constructor(private _route: ActivatedRoute, private notificationDetailService: NotificationDetailService,private router: Router, private _location: Location) {
    this.id = this._route.snapshot.params['id'];

    this.oneNotification();
   }

   ngOnInit() {  }

  odabirTaba(e){
    this.tab = e.itemIndex;
  }


  back(){
    this._location.back(); 
  }

  check(e){
    this.checkOption = e.value;
  }

  posalji(data){
    // console.log(data);
    
      let podaci = {
        'myID': this.id,
        'sendoption': this.checkOption
      }
      // console.log(podaci);
      
      if(this.checkOption === undefined){
        notify('You must specify the notification recipient!', 'error')
      } else {

        this.notificationDetailService.posaljiPush(podaci).subscribe(res =>{
          // console.log(res);
          if(res.success === true){
            notify('Successfully sent!', 'success')
            this.router.navigate(['notifications-list']);
          }else {
            notify(res.message, 'error')
          }
      }) 
    }  
 }
  
  oneNotification(){
    this.notificationDetailService.listnotificationDetails({"id": this.id}).subscribe(res => {
      // console.log(res);
      this.checkRadio = res['sendoption']
      if(res['send'] === true){
        this.obavijestPoslana = true;
      } else {
        this.obavijestPoslana = false;
      }
      this.obavijestDetalji = {
        de: res['de'],
        en: res['en'],
        fr: res['fr'],
        hr: res['hr'],
        it: res['it'],
        id: res['id'],       
        notification_date: res['notification_date'],
        send: res['send'],
        sender: res['sender'],
        recipient: res['sender'],
        senderName: res['sender_name'],
        recName: res['sender_name'],
      }
      // console.log(this.obavijestDetalji);   
      this.imeObjekta = res['sender_name'];   
    })
  }

  zakaziSlanje(){
    this.notificationDetailService.zakaziPush({'myID': this.id}).subscribe(res => {
        this.podaci = res;
        if(res.success === true){
          notify('Successfully scheduled!', 'success');
          this.router.navigate(['notifications-list']);
        }else {
          notify(res.message, 'error')
        }
    })
  }
 
 }
 