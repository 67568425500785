import { Component, OnInit } from '@angular/core';
import { AnaliticsService } from './analitics.service';

@Component({
  selector: 'app-analitics',
  templateUrl: './analitics.component.html',
  styleUrls: ['./analitics.component.scss'],
  providers: [AnaliticsService]
})
export class AnaliticsComponent implements OnInit {

token: any;
podaciAnalitike: any;
odabranaAudioLokacija: any;
audioLokacije: any;
odabranaAudioTocka: any;
audioTocke: any;

  constructor(private _analiticsService: AnaliticsService) {
    this.token = sessionStorage.getItem('token');

    this.analitika();
   }

   onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift( 
      {
      location: 'before',
      template: 'naslovTablice',    
    },
    )
  }

   analitika(){
    this._analiticsService.dohvatiAnalitiku({}).subscribe(res => {
       this.podaciAnalitike = res;
     })
   }

  onAudioLokacijeExpanding($event: any){
    this.odabranaAudioLokacija = $event.key;
    this._analiticsService.dohvatiBrojacLokacija({'id': $event.key.accommodationID}).subscribe(res => {
      if(res.success == true){
        this.audioLokacije = res.data;
      }
    })
  }

  onAudioTockeExpanding($event: any){
    this.odabranaAudioTocka = $event.key;
    this._analiticsService.dohvatiBrojacTocaka({'id': $event.key.id}).subscribe(res => {
      if(res.success == true){
        this.audioTocke = res.data;
      }
    })
  }

  ngOnInit() {
    sessionStorage.removeItem('objectsKey');
    sessionStorage.removeItem('contentKey');
  }

}
