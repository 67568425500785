import { Component, OnInit } from '@angular/core';

import { encodeHtml } from 'devextreme/core/utils/string';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

import { BasicInfoService } from '../basic-info/basic-info.service';
import { TagsService } from './tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  providers: [TagsService, BasicInfoService]
})
export class TagsComponent implements OnInit {

  tagsList: any = [];
  naslov: any;
  loadingVisible = false;

  popisJezikaEdit = [];
  jezikPrijevoda: any = { 'text': null };
  odabraniJezik: string = null;
  // placeholder = "Croatian (hr)";
  tagID: string;

  prijevodiPopup: boolean = false;
  tagName: any = {
    'name': ''
  };

  constructor(
    private _basicInfoService: BasicInfoService,
    private _tagsService: TagsService
  ){
    this.onShowing = this.onShowing.bind(this);
  }

  ngOnInit(){
    this.getTags();
  }

  onToolbarPreparing(e: any){ 
    e.toolbarOptions.items.unshift(
    {
      location: 'before',
      template: 'naslovTablice'   
    })
  }

  onShowing(e: any){
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e: any){
    this.naslov = 'Add new tag';
  }

  addTag(e: any){
    let data = {
      'name': e.data.name,
      'defaultJezik': 'hr'
    }
    this.loadingVisible = true;
    this._tagsService.newTag(data).subscribe(res => {
      this.loadingVisible = false;
      if(res.success == true) {
        notify('Success!', 'success');
      } else {
        notify('Something went wrong. Try again.', 'error');
      }
      this.getTags();
    })
  }

  oneTag(e: any){
    this.jezikPrijevoda = e.data.name;
    this.prijevodiPopup = true;
    this.tagID = e.data.id;
    this.tagName = {
      'name': e.data.name
    };
    this.dohvatiMoguceJezike(this.tagID);
  }

  cancelTag(){
    this.prijevodiPopup = false;
  }

  deleteTag(e: any){
    let encodedMessage = encodeHtml("Are you sure that you want to delete this tag?");
    let myDialog = custom({
        title: "Delete",
        messageHtml: encodedMessage,
        buttons: [{
            text: "Yes",
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
        },{
          text: "No",
          onClick: (e) => {          
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'Yes'){        
        this._tagsService.deleteTag(e.data.id).subscribe(res=>{
          if(res.success == true){
            notify('Tag successfully deleted!', 'success');
            this.getTags();
          } else {
            notify(res.message ,'error');
          }
          this.getTags();
        })
      }
    });
  }

  editTag(e: any){
    let data = {
      'id': this.tagID,
      'name': e.name,
      'jezik': this.odabraniJezik /* null Označava HR */
    }
    this.loadingVisible = true;
    this._tagsService.editTag(data).subscribe(res => {
      this.loadingVisible = false;
      if(res.success == true) {
        notify('Success!', 'success');
        if(this.odabraniJezik == null){
          this.tagsList.find(item => item.id == this.tagID).naziv = e.name;
        }
        this.prijevodiPopup = false;
      } else {
        notify(res.message, 'error');
      }
    })
  }

  getTags(){
    this._tagsService.dohvatiSveTagove({}).subscribe(res =>{
      if(res.success === true){
        this.tagsList = res.data;
      }
    })
  }

  dohvatiMoguceJezike(tagID){
    this._basicInfoService.dohvatiJezikePrijevod({'id': tagID, 'jezik': 'hr', 'ekran': 'Tag'}).subscribe(res=> {
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify(res.message, 'error');
        }
    })
  }

  displayExpr(data){
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.odabraniJezik = $event.selectedItem.text;
    this.jezikPrijevoda = $event.selectedItem.name;
    this._tagsService.getTagLanguage({
        id: this.tagID,
        language: $event.selectedItem.text
    }).subscribe(res=>{
      if(res.success == false){
        notify(res.data, 'error');
        this.tagName = {
          'name': null
        };
      } else {
        this.tagName = {
          'name': res.data.name
        };
      }
    })
  }

}