import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class HomeService {

    constructor(private _http: HttpClient) { 
        // console.log(AppConfig.ipAddress)
    }

    getData(a: any){
        return this._http.get(AppConfig.ipAddress + 'interest/getCount', a)
        .pipe(map((res: any) => res));
    }

}