import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostcardsService } from './postcards.service';
import { AppConfig } from '../../config';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-postcards',
  templateUrl: './postcards.component.html',
  styleUrls: ['./postcards.component.scss'],
  providers: [PostcardsService]
})
export class PostcardsComponent implements OnInit {
  adresaImage = AppConfig.adresaImage;
  token: any;
  id: any;
  value: any;
  postcards = [];
  imeObjekta: any;
  openPopup = false;
  razglednica = {   
    "access_token": '',
    "id": '',
    "postcards": []
  }
  limitUpload = false;
  about: any;
  slikaDodana = false;
  loadingVisible = true;

  constructor(private _postcardsService: PostcardsService, private _route: ActivatedRoute) {
      this.token = sessionStorage.getItem('token');
      this.id = this._route.snapshot.params['id'];
      this._postcardsService.oneObject({"id": this.id}).subscribe(res => {
        this.imeObjekta = res.name; 
      });      
      this.getPostcards();
   }

   uploadPostcard(event){
     this.loadingVisible = true;     
     this.value =  event.target.files;
     let img = new Image();
     img.src = window.URL.createObjectURL(event.target.files[0])
     img.onload = () => {
      if(img.width != 1200 || img.height != 675){
        notify('The image dimensions must be 1200 x 675!');
        this.loadingVisible = false;
      } else {
        this.uploadDoc(event.target.value);
      }
    }
  }


   uploadDoc(data){
      let fileList: FileList = this.value;
        if(fileList.length > 0){
          let file: File = fileList[0];         
          let formData:FormData = new FormData();
            formData.append('datoteka', file);
          let headers = new Headers();
            headers.append('Content-Type', newFunction());            
          this._postcardsService.kreirajPostcard(formData).subscribe(res => {
            // console.log(res);
            this.loadingVisible = false;
            
            if(res.success == true) {
            this.postcards.push(res.postcard); 
            this.slikaDodana= true;          
          }
            if(this.postcards.length == 5){
              notify('Only 5 photos can be uploaded!');
              this.limitUpload = true;
            } else {
              this.limitUpload  = false;
            }            
        });  
      }
  }

  spremiPostcard(){
    this._postcardsService.spremiPostcard({id: this.id, postcards: this.postcards}).subscribe(res => {
  })
}


  ukloniPostcard(slika, i){
      this._postcardsService.deletePostcards({'fileName': slika}).subscribe(res => {
      //  console.log(res);     
        if(res.success == true){
         this.postcards.splice(i, 1);
         this.spremiBrisanjSlike();
       }  else { }
        // console.log(this.postcards.length)
        if(this.postcards.length == 5){
       notify('Only 5 photos can be uploaded. Delete one first!');
        this.limitUpload = true;
        } else {
          this.limitUpload  = false;
        }
    })
  }

  editPostcard(){
    //  console.log(this.postcards.length)
    if(this.postcards.length === 5){
      notify('Only 5 photos can be uploaded. Delete one first!');
      this.limitUpload = true;
    } else {
      this.limitUpload = false;
    }
     this.openPopup = true;     
  }

  spremiBrisanjSlike(){
      this.razglednica.access_token = this.token,
      this.razglednica.id = this.id,
      this.razglednica.postcards = this.postcards,
      this._postcardsService.spremiPostcard(this.razglednica).subscribe(res => {
      })  
  }

  saveEdit(e){
    this.openPopup = false;
    this.razglednica.access_token = this.token,
    this.razglednica.id = this.id,
    this.razglednica.postcards = this.postcards,
    this._postcardsService.spremiPostcard(this.razglednica).subscribe(res => {
    })    
   }

  getPostcards(){
    this._postcardsService.prikazPostcards({id: this.id}).subscribe(res => {
      this.postcards = res.postcards;
      this.loadingVisible = false;
    }) 
  } 

  odustani(){
    if(this.postcards.length > 0 && this.slikaDodana){
      notify('You must delete images first!', 'info')
    } else {
      this.openPopup = false;
    }
    
  }

  ngOnInit() { }
}

function newFunction(): string {
	return 'multipart/form-data';
}