import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { BasicInfoService } from '../basic-info/basic-info.service';
import { ObjectTypesService } from './object-types.service';

@Component({
  selector: 'app-object-types',
  templateUrl: './object-types.component.html',
  styleUrls: ['./object-types.component.scss'],
  providers: [ObjectTypesService, BasicInfoService]
})
export class ObjectTypesComponent implements OnInit {
  objectTypes: any = [];
  naslov: any;
  loadingVisible = false;

  popisJezikaEdit = [];
  jezikPrijevoda: any = { 'text': null };
  odabraniJezik: string = null;
  // placeholder = "Croatian (hr)";
  objID: string;

  prijevodiPopup: boolean = false;
  typeName: any = {
    'objectName': ''
  };

  constructor(
    private basicInfoService: BasicInfoService,
    private objectTypesService: ObjectTypesService
  ) {
    this.onShowing = this.onShowing.bind(this);
   }

  ngOnInit() {
    this.objectTypeList();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift(
    {
      location: 'before',
      template: 'naslovTablice'    
    })      
  }

  onShowing(e){  
    e.component.option("title", this.naslov);
  }

  onInitNewRow(e){
    this.naslov = 'Add new';
    // console.log(this.naslov);  
  }

  addType(e){
    let data = {
      'naziv': e.data.naziv
    }
    this.loadingVisible = true;
    this.objectTypesService.newObjectType(data).subscribe(res => {  
      this.loadingVisible = false;
      if(res.success == true) {
        notify('Success!', 'success')
      } else {
        notify('Something went wrong. Try again.', 'error');
      }
      this.objectTypeList();
    })
  }

  oneType(e){
    this.jezikPrijevoda = e.data.name;
    this.prijevodiPopup = true;
    this.objID = e.data.id;
    this.typeName = {
      'objectName': e.data.naziv
    };
    this.dohvatiMoguceJezike(this.objID);
  }

  cancelType(){
    this.prijevodiPopup = false;
  }

  editType(e){
    let data = {
      'id': this.objID,
      'naziv': e.objectName,
      'jezik': this.odabraniJezik /* null Označava HR */
    }
    // console.log(data);
    this.loadingVisible = true;
    this.objectTypesService.editObjectType(data).subscribe(res => {
      // console.log(res);    
      this.loadingVisible = false;
      if(res.success == true) {
        notify(res.message, 'success');
        if(this.odabraniJezik == null){
          this.objectTypes.find(item => item.id == this.objID).naziv = e.objectName;
        }
        this.prijevodiPopup = false;
      } else {
        notify(res.message, 'error');
      }
    })
  }

  objectTypeList(){
    this.objectTypesService.popis({}).subscribe(res =>{
      // console.log(res); 
      if(res.success === true){
        this.objectTypes = res.data;
        // console.log(this.objectTypes);
      }  
    })
  }

  dohvatiMoguceJezike(objID){
    this.basicInfoService.dohvatiJezikePrijevod({'id': objID, 'jezik': 'hr', 'ekran': 'ObjectTypes', 'objID': objID}).subscribe(res=> {
        // console.log(res);
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify(res.message, 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    // console.log($event);
    this.odabraniJezik = $event.selectedItem.text;
    this.jezikPrijevoda = $event.selectedItem.name;
    this.objectTypesService.getLanguage({
        typeID: this.objID, 
        language: $event.selectedItem.text
    }).subscribe(res=>{
      // console.log(res);
      if(res.success == false){
        notify(res.data, 'error');
        this.typeName = {
          'objectName': null
        };
      } else {
        this.typeName = {
          'objectName': res.data.name
        };
      }
    })
  }

}