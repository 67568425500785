import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { event } from 'devextreme/events';
import notify from 'devextreme/ui/notify';
import { TypesService } from './types.service';

@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.scss'],
  providers: [TypesService]
})
export class TypesComponent implements OnInit {
token: any;
types: any;
vrijednost: boolean;
accommodationID: any;

  constructor(private typesService: TypesService, private _route: ActivatedRoute) {
    this.accommodationID = this._route.snapshot.params['id'];
   }

   onToolbarPreparing(e) { 
     e.toolbarOptions.items.unshift(
    {
      location: 'before',
      template: 'naslovTablice',    
    })      
  }

  onClick(e: any, data: any){
    // console.log({id: this.accommodationID, typeId: data.key});  
    if(e.value === true){
      this.typesService.dodajInteresDisableType({id: this.accommodationID, typeId: data.key}).subscribe(res =>{
      // console.log(res)
      if(res.success === true){
        this.popis();
        } 
      })
      } else {
      this.typesService.ukloniInteresDisableType({id: this.accommodationID, typeId: data.key}).subscribe(res =>{
        // console.log(res)
        if(res.success === false){
          this.popis();
        } 
      })
    }
  }

  popis(){
    this.typesService.dohvatiInterestDisableType({id: this.accommodationID}).subscribe(res =>{
      // console.log(res);  
      this.types = res.podaci;
    })
  }

  ngOnInit() {
    this.popis()
  }

}