import { Component, HostBinding } from '@angular/core';
import { AuthService, ScreenService, AppInfoService, Locale } from './shared/services';
import { locale } from 'devextreme/localization';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  locale: string;
  locales: Locale[];
  date = new Date();
  year = this.date.getFullYear();
  path: any;
  obavijesti = false;
  
  constructor(private authService: AuthService, private screen: ScreenService, public appInfo: AppInfoService, router: Router) {
    this.locale = this.getLocale(); 
    this.locales = appInfo.getLocales();
    locale(this.locale);
    this.setLocale(this.locale);

    this.path = location.pathname;

    this.prikaziObavijesti();    

    const ruta =  router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // console.log(e.url);
        if(e.url === '/notifications'){
          this.obavijesti = true;
        } else {
          this.obavijesti = false;
          ruta.unsubscribe();
        }
      }
    })

  }

  getLocale() {
    var locale = sessionStorage.getItem("locale");
    return locale != null ? locale : "en";
  }
  setLocale(locale) {
    sessionStorage.setItem("locale", locale);
  }
  changeLocale(data) {
    this.setLocale(data.value);
    parent.document.location.reload();
  }

  isAutorized() {
    return this.authService.isLoggedIn;
  }

  prikaziObavijesti(){
    // return this.authService.prikazObavijesti;
    if(this.path === '/notifications'){
      this.obavijesti = true;
      // return true;    
    } else {
      this.obavijesti = false;
      // return false;
    }      
  }




}
