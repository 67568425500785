import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class ContentService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    allInterests(a: any){
        return this._http.get(AppConfig.ipAddress + 'interest/dohvatiSve', a)
        .pipe(map((res: any) => res      
        ));
    }

    gradoviObjekata(a: any){
        return this._http.get(AppConfig.ipAddress + 'accommodation/vratiGradove', a)
        .pipe(map((res: any) => res      
        ));
    }

    obrisiContent(a: any){
        return this._http.delete(AppConfig.ipAddress + 'interest/' + a.id, a)
        .pipe(map((res: any) => res      
        ));
    }

}