import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { BasicInfoService } from './basic-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../config';
import notify from 'devextreme/ui/notify';
import { Location } from '@angular/common';
import validationEngine from "devextreme/ui/validation_engine";
import { DxFormComponent } from 'devextreme-angular';

declare const google: any;

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
  providers: [BasicInfoService]
})
export class BasicInfoComponent implements OnInit {

  adresaImage = AppConfig.adresaImage;
  window: any;
  myID: any;
  imeObjekta: any;
  idObjekta: any;
  token: any;
  regions = [];
  cities = [];
  countries = [];
  propertyType = [];
  objekt: any;
  objectInfo = {
    'access_token': '',
    'createdBy': '',
    'ownerId': '',
    'type': '',
    'name': '',
    'location': {"lat": 0, "lon": 0},
    'room': 0,
    'bed': 0,
    'contactPerson':  '',
    'contactPhone': '',
    'contactEmail': '',
    'address': '',
    'city': '',
    'region': '',
    'country': '',
    'scanKey': '',
    'shortDescription': '',
    'QRCode': '',
    'active': 0,
    'nearbyCities': [],
    'defaultJezik': ''
  };

  latitude: number;
  longitude: number;
  zoom: number = 18 //15;
  address: string;
  private geoCoder;

  newObject: string;
  data: any;
  grad: string;
  regija: string;
  zemlja: string;
  id: any;
  location: Location;
  addQR: any;
  scanKEY: any;
  imaQR = false;
  qrCode: any;
  jezik = [];
  otvoriPopop = false;
  generiramMP = false;
  slikaMP: any;
  slikaMP2: any;
  gradoviPopup = false;
  searchTerm: any;
  gradovi: any;
  gradovi2: any;
  odabranGrad = [];
  klik = false;
  gradoviPopupEdit = false;
  gradoviZaEdit = false;
  oznaceniGradovi=[];
  popupPrijevod = false;
  textPrijevod = {};
  jezikTeksta: any;
  jezikTextaObjekt: any;
  prijevodDescription: any;
  licenseDate = new Date().setFullYear(new Date().getFullYear() + 1);
  editGrad: any;
  type: any;
  country: any;
  countryName: any;
  region: any;
  city: any;
  cityName: any;
  scanKeyProvjera: any = [];
  prijevodTitle: any;
  scanKeyKreiran = false;
  loadingVisible = false;
  loadingMP = false;
  countryCode: any;
  onemoguciIzmjenuScanKey = false;
  onemoguciQR = false;
  onemoguciMediaPack = false;
  dozvoliIzmjenu: boolean = false;
  objectTypes = [];
  defaultJezik: string;
  popisJezika = [];
  popisJezikaEdit = [];
  jezikPrijevoda: string;

  @ViewChild('search', {static: false}) public searchElementRef: ElementRef;
  @ViewChild('map', {static: false}) public mapElementRef: ElementRef;
  @ViewChild("jezikForma", { static: false }) jezikForma: DxFormComponent;  

  constructor(private basicInfoService: BasicInfoService, 
              private mapsAPILoader: MapsAPILoader, 
              private ngZone: NgZone, 
              private _route:ActivatedRoute, 
              private router: Router,
              private _location: Location) {

    this.token = sessionStorage.getItem('token');
    this.myID = localStorage.getItem('myID');
    this.id = this._route.snapshot.params['id'];

    this.propertyType =  ["Apartment", "Camp", "City", "Health", "Hostel", "Hotel", "House", "Marina", "Place", "Private accomodation", "Region"];
    
    if(this.id){
       this.newObject = 'editNewObject';
       this.basicInfoService.oneObject({"id": this.id}).subscribe(res => {
        this.dohvatiMoguceJezike(res.defaultJezik);
        this.oznaceniGradovi = res.nearbyCities;
        this.imeObjekta = res.name;
        this.qrCode = res.QRCode; 
        if(this.qrCode) {
          this.imaQR = true;
        } else { 
          this.imaQR = false;
        }
        // console.log('UČITAJ PRVO + ' + this.qrCode)
      });
      } else {
        this.newObject = 'addNewObject';
        this.objectInfo.bed = null;
        this.objectInfo.room = null;
        this.dohvatiDostupneJezike();
      }
    // this.allRegions();
    this.allCountries();  
    this.dohvatiScanKey();
    this.objectTypeList();

    this.jezik = ['en', 'de', 'fr' ]

    this.validationCallbackScanKey = this.validationCallbackScanKey.bind(this);
    this.validationCallbackScanKeyEdit = this.validationCallbackScanKeyEdit.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.scanKeyDuzina = this.scanKeyDuzina.bind(this);
  }

  validationCallbackScanKey(e) {           
      var isValid = true;
      this.dozvoliIzmjenu = true;
      for (var i = 0; i < this.scanKeyProvjera.length; i++) {
        if(e.value != null){          
            // if(this.scanKeyProvjera[i].scanKey == e.value && this.scanKEY != e.value) { // ZAKOMENTIRANO JER JE PUŠTALO DA SE UNESE ISTI
              if(this.scanKeyProvjera[i].scanKey == e.value) {             
                  isValid = false;         
                  this.dozvoliIzmjenu = false;    
                  break;
              }
            }
      }  
        return isValid;
  }

  validationCallbackScanKeyEdit(e) {           
    var isValid = true;
    this.dozvoliIzmjenu = true;
    for (var i = 0; i < this.scanKeyProvjera.length; i++) {
      if(e.value != null){          
          // if(this.scanKeyProvjera[i].scanKey == e.value && this.scanKEY != e.value) { // ZAKOMENTIRANO JER JE PUŠTALO DA SE UNESE ISTI
            if(this.scanKeyProvjera[i].scanKey == e.value && !this.onemoguciIzmjenuScanKey) {             
                isValid = false;         
                this.dozvoliIzmjenu = false;    
                break;
            }
          }
    }  
      return isValid;
}

  jezikOdabran(e){
    // console.log(e.itemData);    
  }

  dohvatiDostupneJezike(){
    this.basicInfoService.dohvatiJezike({}).subscribe(res=> {
        if(res.success == true){
          this.popisJezika = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  ngOnInit() {
/** ------------------------ UREĐUJEM OBJEKT - LOKACIJA ------------------------------ */
   if(this.id){
      this.oneObject();      
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["geocode"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18 // 15;
            this.objekt.location.lat = this.latitude;
            this.objekt.location.lon = this.longitude;
            });
          });
      })
/** ------------------------ DODAJEM OBJEKT - LOKACIJA ------------------------------ */
    } else {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;        
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["geocode"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            //verify result            
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18 // 15;
            this.objectInfo.location.lat = this.latitude;
            this.objectInfo.location.lon = this.longitude;
            });
          });
        });
    }   
  }

 saveChanges(data: any, event: any) {  
   this.loadingVisible = true;
    if(data.location.lat === 0){
      data.location.lat = this.latitude;
    }
    if(data.location.lon === 0) {
      data.location.lon = this.longitude;
    }    
    this.objectInfo.access_token = this.token,
    this.objectInfo.ownerId = this.myID,
    this.objectInfo.createdBy = this.myID,
    this.objectInfo.type = data.type,
    this.objectInfo.name = data.name,
    this.objectInfo.location = {lat: data.location.lat, lon: data.location.lon}
    this.objectInfo.bed = data.bed,
    this.objectInfo.room = data.room,
    this.objectInfo.contactPerson = data.contactPerson,
    this.objectInfo.contactPhone = data.contactPhone,
    this.objectInfo.contactEmail = data.contactEmail,
    this.objectInfo.address = data.address,
    this.objectInfo.city = this.grad,
    this.objectInfo.region = this.regija,
    this.objectInfo.country = this.zemlja;
    this.objectInfo.scanKey = data.scanKey;
    this.objectInfo.shortDescription = data.shortDescription;
    this.objectInfo.active = 0;
    this.objectInfo.nearbyCities = this.odabranGrad;
    this.objectInfo.defaultJezik = data.defaultJezik;
    // console.log(this.objectInfo);

    // console.log(validationEngine.validateGroup("objektDodaj").isValid);
    
    /** Validacija za formu */
    if(validationEngine.validateGroup("objektDodaj").isValid === true){
    this.basicInfoService.addObject(this.objectInfo).subscribe(res=>{   
      this.loadingVisible = false;  
      if(res.success == true) {      
          notify('Object has been successfully created.', 'success')
          this._location.back();        
        } else {
          notify('Something went wrong. Try again.', 'error');     
        }
      })
    } else {
      this.loadingVisible = false;
      notify('Please fill in the required fields!', 'error');
    }  
  }

  onContentReady(e){   
    e.component.getEditor('type').focus();
  }

  onContentReadyEdit(e){
    e.component.getEditor('type').focus();
  }

  onShownJezik(e){   
    this.jezikForma.instance.focus();
  }

  odustani(){
    this._location.back();
  }

  generirajQR(){
  //  console.log(this.imaQR)
  //   console.log(this.scanKEY);
  //   console.log(this.qrCode);    
    if(!this.scanKEY){
      notify("You don't have scan key. Add one and save the changes", 'error');
    } else { 
      if (this.imaQR) {
        this.basicInfoService.generirajQR({'kod': this.scanKEY, QRCode: this.qrCode}).subscribe(res => {
      // console.log(res);    
      notify("QR code is generated! Don't forget to save changes!");
      this.imaQR = true;
      this.qrCode = res['kod'];
      // console.log(this.qrCode);
    })  
    } else {
    this.basicInfoService.generirajQR({'kod': this.scanKEY}).subscribe(res => {
        // console.log(res);    
        notify("QR code is generated! Don't forget to save changes!");
      this.imaQR = true;
      this.qrCode = res['kod'];
      // console.log(this.qrCode);
      })  
    }  
  }  
}


scanKeyDuzina(e){
  if(e.value.length != 10){
    return false;
  } else {
    return true;
  }  
}

onValueChanged(e){
  if(this.scanKEY != e.value){
    if(e.value.length === 10){
    // if(e.value.length >= 10){
      this.scanKEY = e.value
      this.loadingVisible = true;
      // console.log(this.dozvoliIzmjenu);
      if(this.dozvoliIzmjenu){
      if (this.imaQR) {
        this.basicInfoService.generirajQR({'kod': e.value, QRCode: this.qrCode}).subscribe(res => {    
            // notify("QR code is generated! Don't forget to save changes!");
            this.imaQR = true;
            this.qrCode = res['kod'];
            this.loadingVisible = false;
            this.generiraj(); 
          })  
      } else {
        this.basicInfoService.generirajQR({'kod': e.value}).subscribe(res => {   
          // notify("QR code is generated! Don't forget to save changes!");
          this.imaQR = true;
          this.qrCode = res['kod'];
          this.loadingVisible = false;
          this.generiraj(); 
        })  
      } 
    } else {
      this.loadingVisible = false;
    }
    }
  } 
  // else {
  //   notify('Your scan key has "space" at the end. Delete it.')
  // }
}

generirajMediaPack(){ 
  // this.loadingMP = true; 
  if(!this.scanKEY){
    notify("You don't have scan key. Add one and save the changes", 'error');
  } else {
    this.generiramMP = true; 
  }
}
  
  spremiMP(e){
    // console.log(this.idObjekta);
      this.basicInfoService.editObject({
        "id": this.id,
        "ownerId": this.idObjekta,
        "mediaPack": this.slikaMP,
        "mediaPack2": this.slikaMP2
      }).subscribe(res=>{
        // console.log(res);      
        if(res.success == true) {
          notify('Your media pack is saved', 'success');
          } else {
            notify('Something went wrong. Try again.', 'error');
        }
        this.generiramMP = false;
      })  
  }

  generiraj(){
    // this.loadingVisible = false;
    // console.log(this.scanKEY);    
    // console.log(e)    
    this.loadingMP = true;
    this.basicInfoService.kreirajMediaPack({'scanKey': this.scanKEY, objekt: this.imeObjekta}).subscribe(res => { // dodano da se šalje ime objekta
      this.loadingVisible = false;
      // console.log(res);    
      this.loadingMP = false;
      if(res.success == true) {
        this.slikaMP = res.mediaPack;
        this.slikaMP2 = res.mediaPack2;
        this.generiramMP = true;         
      } else {
        notify('Something goes wrong. Try again.', 'error');
      }
    })  
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 18 //15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }  

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.objectInfo.location.lat = this.latitude;
    this.objectInfo.location.lon = this.longitude;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18 // 15;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

// allRegions(drzava){
//   // console.log({'drzava': this.countryCode})
//   console.log(drzava);
  
//   this.basicInfoService.regions({'drzava': drzava}).subscribe(res => {
//     console.log(res);    
//     this.regions = res;
//     // console.log(this.regions);
    
//   })    
// }

  allCountries(){
    this.basicInfoService.countries({}).subscribe(res => {
      this.countries = res;
      // console.log(this.countries);    
    })    
  }

  onValueChangeDrzava(e){
    // console.log(e);
    
    if(e.value === null){
      this.countryCode = null;
      this.countryName = null;
      this.zemlja = null;

      this.regija = null;
      this.region = null;
      this.grad = null;
      this.cityName = null;
      this.editGrad = null;
    }      
  }

/** DODAVANJE NOVOG OBJEKTA */
  odabranaDrzava(e){   
    this.zemlja = e.itemData.countryName;
    this.countryCode = e.itemData.countrycode;      
    this.basicInfoService.regions({'drzava': e.itemData.countrycode}).subscribe(res => {
      // console.log(res);    
      this.regions = res;        
    })
  }

  odabranaRegija(e){
    this.regija = e.value;
    // console.log(this.regija);
    this.basicInfoService.cities({'regija': this.regija}).subscribe(res => {
      this.cities = res;
      //  console.log(this.cities);
    })      
  }

  odabraniGrad(e){
    this.grad = e.value;
    // console.log(this.grad);      
  } 

/** uređivanje postojećeg objekta */
oneObject(){ 
  this.basicInfoService.oneObject({"id": this.id}).subscribe(res => {
    this.objekt = res;
    // console.log(res);
    this.latitude = parseFloat(this.objekt.location.lat);
    this.longitude = parseFloat(this.objekt.location.lon); 
    // console.log(this.objekt);
    this.editGrad = this.objekt.city;
    this.scanKEY = this.objekt.scanKey;    
    this.qrCode = this.objekt.QRCode;
    this.slikaMP = this.objekt.mediaPack;
    this.slikaMP2 = this.objekt.mediaPack2;
    this.odabranGrad = this.objekt.nearbyCities;
    this.countryName = this.objekt.country
    this.region = this.objekt.region
        if(this.qrCode) {
          this.imaQR = true;
        } else { 
          this.imaQR = false;
        }
        
    this.idObjekta = this.objekt.ownerId;

        this.basicInfoService.cities({'regija': this.objekt.region}).subscribe(res => {
          this.cities = res;
          //  console.log(this.cities);
        })  

    if(res.scanKey != undefined){
      this.onemoguciIzmjenuScanKey = true;
    } else {
      this.onemoguciIzmjenuScanKey = false;
    } 
    if(res.scanKey != undefined && res.mediaPack != undefined && res.mediaPack2 != undefined){
      this.onemoguciMediaPack = true;
    } else {
      this.onemoguciMediaPack = false;
    }
    if(res.scanKey != undefined && res.QRCode != undefined){
      this.onemoguciQR = true;
    } else {
      this.onemoguciQR = false;
    }
    });   
  }
 
  saveEdit(e){
    // e.city = this.city;
    this.loadingVisible = true;
    let podaciZaSlanje = {
      "address": e.address,
      "city": e.city,
      "id": this.id,
      "ownerId": e.ownerId,
      "name": e.name,
      "scanKey": e.scanKey,
      "QRCode": this.qrCode,
      'contactPerson': e.contactPerson|| null,
      'contactPhone': e.contactPhone || null,
      'contactEmail': e.contactEmail || null,
      'shortDescription': e.shortDescription || null,
      // 'nearbyCities':this.odabranGrad,
      'location': {lat: e.location.lat, lon: e.location.lon},
      'license': {'expire': e.license.expire, 'price': e.license.price || null, 'comment': e.license.comment || null},
      'bed': e.bed,
      'room': e.room,
      'type': e.type,
      'region': this.regija,
      'country': this.zemlja
    }
    // console.log(podaciZaSlanje);
// console.log(validationEngine.validateGroup("objektUredi").isValid);

    if(validationEngine.validateGroup("objektUredi").isValid === true){
      this.basicInfoService.editObject(podaciZaSlanje).subscribe(res => {
        // console.log(res);
        this.loadingVisible = false;
          if(res.success == true) { 
            notify('Basic info successfully changed.', 'success');
            this._location.back();
          } else {
            notify('Basic info change failed. Try again.', 'error');
          }
        })
      } else {
        this.loadingVisible = false;
        notify('Please fill in the required fields!', 'error');
      }  
    /**//////////////////// odkomentirati save edita /////////////////////// */
  }

  dodajGradove(e){
    this.gradoviPopup = true;
  }

  zatvorenPopup(){
    this.searchTerm = '';
  }

  zatvorenEditPopup(){
  }

  search() {
    const term = this.searchTerm;
    // console.log(this.searchTerm);
    
    if (term.length >= 3) {  
      this.basicInfoService.dohvatiGradoveFilter({ime: term}).subscribe(res => {
        // console.log(res);
        this.gradovi = res;
      })
    }
  }
 
  gradoviPopupClose(e){
    this.gradoviPopup = false;
    // console.log(this.odabranGrad);
    this.klik = true;   
    this.gradovi = []; 
   }

  gradoviPopupEditClose(e){
    // console.log(e)
    this.gradoviZaEdit = false;
    // console.log(this.odabranGrad);
  }

  odabirGrada(index: number, data: any, isChecked: boolean){
    if (isChecked === true) {    
            // console.log(data.cityName); 
              this.odabranGrad.push(data.cityName)             
          } else {       
            let index = this.odabranGrad.indexOf(data.cityName);
            this.odabranGrad.splice(index, 1)  
      }
    // console.log(this.odabranGrad);
}


  ukloniGradove(e){
    // console.log(e);    
    this.gradoviZaEdit = true;
    // console.log(this.odabranGrad);
  }

  makniGrad(index: number, data: any, isChecked: boolean){
      // console.log(index);
      // console.log(data);
      // console.log(isChecked);
      if(index != -1) {
        this.odabranGrad.splice(index, 1);
      }
    // console.log(this.odabranGrad);
  } 

//////////////////////////////// JEZIK ////////////////////////////////////////////

  //** PRIJEVOD NA JEZIKE */
  spremiPrijevod(e){
    this.popupPrijevod = false;
    this.prijevodDescription = e.description;
    this.prijevodTitle = e.title;
    this.basicInfoService.editLanguage({
        'accommodationId': this.id,
        'jezik': this.jezikTeksta,
        'shortDescription': this.prijevodDescription,
        'title': this.prijevodTitle
    }).subscribe(res=>{
        if(res.success == true){
          this.popisJezikaEdit.find(item => item.text == this.jezikTeksta).value = true;
          notify('Your translation is saved.', 'success');
        }
    })
  }

  dohvatiMoguceJezike(defaultJezik){
    this.basicInfoService.dohvatiJezikePrijevod({'id': this.id, 'jezik': defaultJezik, 'ekran': 'BasicInfo'}).subscribe(res=> {
        // console.log(res);
        if(res.success == true){
          this.popisJezikaEdit = res.data;
        } else {
          notify('Error retrieving language list!', 'error');
        }
    })
  }

  displayExpr(data) {
    if(data){
      return data.name + ' (' + data.text + ')'
    }
  }

  selectLanguage($event){
    this.popupPrijevod = true;
    this.jezikTeksta = $event.selectedItem.text;
    this.jezikPrijevoda = $event.selectedItem.name;
    this.basicInfoService.getLanguage({
        accommodationId: this.id, 
        language: $event.selectedItem.text
    }).subscribe(res=>{
      if(res.success == false){
        notify(res.data, 'error');
        this.textPrijevod['description'] = '';
        this.textPrijevod['title'] = '';
      } else {
        this.textPrijevod['description'] = res.data.shortDescription;
        this.textPrijevod['title'] = res.data.title;
      }
    })
  }

  dodajGrad(e){
    this.gradoviPopup = true;
  }

   //***************************** edit lokacije na mapi *********************************** */
   editAdressOnMap($event: MouseEvent){
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddressEdit(this.latitude, this.longitude);
    this.objekt.location.lat = this.latitude;
    this.objekt.location.lon = this.longitude;
   }

   getAddressEdit(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18 //15;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }


  dohvatiScanKey(){
    this.basicInfoService.provjeraScanKey({}).subscribe(res => {
      // console.log(res);   
      this.scanKeyProvjera = res.podaci;
      // console.log(this.scanKeyProvjera);      
    })
  }

  odustaniPrijevod(){
    this.popupPrijevod = false;
    this.textPrijevod = {};
  }

  objectTypeList(){
    this.basicInfoService.popis({}).subscribe(res =>{
      // console.log(res); 
      if(res.success === true){
        this.objectTypes = res.data;
      }  
    })
  }

}