export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Users',
    icon: 'group',
    path: '/users'
  },
  // {
  //   text: 'Location',
  //   icon: 'globe',
  //   items: [
  //     {
  //       text: 'Country'
  //     },
  //     {
  //       text: 'Region'
  //     },
  //     {
  //       text: 'Place'
  //     }
  //   ]
  // },
  {
    text: 'Objects',
    icon: 'pin',
    path: '/objects'
  },
  {
    text: 'Object group',
    icon: 'columnchooser',
    path: '/object-group'
  },
  {
    text: 'Object types',
    icon: 'fields',
    path: '/object-types'
  },
  {
    text: 'Content',
    icon: 'coffee',
    path: '/content'
  },
  {
    text: 'Analytics ',
    icon: 'chart',
    path: '/analitics'
  },
  {
    text: 'Notifications',
    icon: 'bookmark',
    path: '/notifications-list'
  },
  {
    text: 'Tags',
    icon: 'variable',
    path: '/tags'
  },
  // {
  //   text: 'Settings',
  //   icon: 'preferences',
  //   path: '/settings'
  // }
];
