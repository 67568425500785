import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class ObjectTypesService {

    constructor(private _http: HttpClient) { 
    //    console.log(AppConfig.ipAddress)
    }

    popis(a: any){
        return this._http.post(AppConfig.ipAddress + 'object_types/dohvati', a)
        .pipe(map((res: any) => res      
        ));
    }

    newObjectType(a: any){
        return this._http.post(AppConfig.ipAddress + 'object_types/dodaj', a)
        .pipe(map((res: any) => res      
        ));
    }

    editObjectType(a: any){
        return this._http.post(AppConfig.ipAddress + 'object_types/uredi', a)
        .pipe(map((res: any) => res      
        ));
    }

    getLanguage(a: any){
        return this._http.post(AppConfig.ipAddress + '/object_types/getLanguage', a)
        .pipe(map((res: any) => res      
        ));
    }

}